/*
 *
 * SignUp constants
 *
 */

export const DEFAULT_ACTION = 'app/SignUp/DEFAULT_ACTION';
export const RESET_STATE = 'app/SignUp/ResetState';
export const SIGNUP_FORM_UPDATE_ACTION = 'app/SignUp/FormUpdateAction';
export const SIGNUP_FORM_SUBMIT_ACTION = 'app/SignUp/FormSubmitAction';
export const SIGNUP_FORM_SUBMIT_SUCCESS_ACTION =
  'app/SignUp/FormSubmitSuccessAction';
export const SIGNUP_FORM_SUBMIT_ERROR_ACTION =
  'app/SignUp/FormSubmitErrorAction';

export const SIGN_UP_VALIDATE_REFERRAL_CODE_ACTION =
  'app/SignUp/ValidateReferralCodeAction';
export const SIGN_UP_VALIDATE_REFERRAL_CODE_SUCCESS_ACTION =
  'app/SignUp/ValidateReferralCodeSuccessAction';
export const SIGN_UP_VALIDATE_REFERRAL_CODE_ERROR_ACTION =
  'app/SignUp/ValidateReferralCodeErrorAction';
export const SIGNUP_SOCIAL_UPDATE_ACTION = 'app/SignUp/SocialUpdateAction';
export const SIGNUP_SOCIAL_SUBMIT_ACTION = 'app/SignUp/SocialSubmitAction';
