const Colors = {
  textPrimary: '#505e78',
  textAccent: '#4798ed',
  shadow: 'rgba(48, 89, 135, 0.25)',
  red: '#ff5a5a',
  green: '#55d480',
  orange: '#ff9100',
  yellowFishOrange: '#ffa531',
  darkBlue: '#374266',
  cyanBlue: '#589CE8',
  tealishBlue: '#24285B',
  tealishGreen: '#627065',
  white: '#fff',
  darkBlueGrey: '#24285B',
  borderColor: '#E5E7EB',
  mediumGreen: '#12AF47',
  has_access:
    'linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0)), linear-gradient(to bottom, #fff8ed, #fff8ed)',
};

const Metrics = {
  tinySpacer: '18px',
  smallSpacer: '30px',
  bigSpacer: '60px',
  hugeSpacer: '120px',
  mainPaddingTop: '60px',
  mainPaddingBottom: '37px'
};

const Fonts = {
  interFont: "Inter, Arial, sans-serif",
  arial: "Arial, sans-serif",
  poppins: "Poppins, Inter, Arial, sans-serif",
}

export { Colors, Metrics, Fonts };
