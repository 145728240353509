/**
 *
 * AuthViewList
 *
 */
import React from "react";
import styled from "styled-components";
import { AuthSubTitle, AuthSubtitlePadding, AuthTitle } from "../AuthView";
import { Colors, Metrics } from "../../styles/Theme";
import auth1 from "../../images/auth/auth-1.svg";
import auth2 from "../../images/auth/auth-2.svg";
import auth3 from "../../images/auth/auth-3.svg";
import auth4 from "../../images/auth/auth-4.svg";
import auth5 from "../../images/auth/auth-5.svg";
import auth6 from "../../images/auth/auth-6.svg";
import { round } from "../../utils/utils";

export const authListItems = [
  { param: 'application_total', icon: auth1, title: "Applications & Cover Letters", subtitle: "View 100% verified successful cover letters!" },
  {
    param: 'interview_experience_total',
    icon: auth2,
    title: "Long-Form Interview Testimonials",
    subtitle: "No more two-line answers. Glassdoor but on steroids!"
  },
  { param: 'company_total', icon: auth3, title: "Company Profiles", subtitle: "Detailed company profiles to use in your applications" },
  { icon: auth4, title: "Congrapps Premium Courses", subtitle: "Learn the details that make all the difference" },
  { icon: auth5, title: "Application Reviews", subtitle: "By true industry insiders reviewing 60+ data points" },
  { icon: auth6, title: "Interview Simulator", subtitle: "1000+ real questions asked in real interviews" }
];

const Container = styled.div`
  width: 38.49%;
  padding: ${Metrics.mainPaddingTop} 81px ${Metrics.mainPaddingBottom};
  background: linear-gradient(182deg, #E5F2FF 0%, rgba(229, 242, 255, 0.00) 100%);

  @media (max-width: 1300px) {
    padding-top: 40px;
    width: 45%;
  }

  @media (max-width: 1650px) {
    padding-left: 4%;
    padding-right: 4%;
  }

  @media (max-width: 1300px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 992px) {
    width: 100%;
    order: 2;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Wrapper = styled.div`
  @media (max-width: 992px) {
    width: 100%;
    max-width: 790px;
    margin: 0 auto;
  }
`;

const List = styled.ul`
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  background: #FFF;
  padding: 16px;
  margin-top: 27px;

  @media (max-width: 1300px) {
    margin-top: 0;
  }
`;

export const ListItem = styled.li`
  position: relative;
  padding: 13px 20px 10px 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 1300px) {
    margin-bottom: 15px;
    padding: 13px 13px 10px 10px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ListItemString = styled.li`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 2.15;
  padding-left: 20px;
`;

export const ListItemIcon = styled.div`
  display: block;
  border-radius: 100%;

  @media (max-width: 1300px) {
    left: 6px;
  }

  img {
    width: 44px;
    height: auto;
  }
`;

export const ListItemTitle = styled.div`
  color: ${Colors.darkBlue};;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25;
  margin-bottom: 5px;
`;

export const ListItemSubtitle = styled.div`
  color: ${Colors.textPrimary};;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25;
`;

function AuthViewList({ children, stats, ...rest }) {
  return (
    <Container>
      <Wrapper>
        <AuthTitle>We help you build an edge.</AuthTitle>
        <AuthSubtitlePadding>
          <AuthSubTitle>Trusted by more than <span className="highlight-blue">{round(stats['happy_application_total']) ?? '10,000'}</span> users</AuthSubTitle>
        </AuthSubtitlePadding>
        <List>
          {authListItems.map(item => {
            return <ListItem>
              <ListItemIcon>
                <img src={item.icon} alt="Congrapps" />
              </ListItemIcon>
              <div>
                <ListItemTitle>{item.param !== undefined ? stats[item.param] ?? '' : ''} {item.title}</ListItemTitle>
                <ListItemSubtitle>{item.subtitle}</ListItemSubtitle>
              </div>
            </ListItem>;
          })}
          <ListItemString className="highlight-blue">And much more!</ListItemString>
        </List>
      </Wrapper>
    </Container>
  );
}

AuthViewList.propTypes = {};

export default AuthViewList;
