import { useEffect } from 'react';
import { addBreadcrumb, resetBreadcrumbs } from '../containers/App/actions';
import { useDispatch } from 'react-redux';

function useBreadcrumbs(breadcrumbs = []) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addBreadcrumb(breadcrumbs));

    const unmount = () => {
      dispatch(resetBreadcrumbs());
    };

    return unmount;
  }, []);
}

export default useBreadcrumbs;
