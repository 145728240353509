/**
 *
 * ApplicationView
 *
 */

import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({
  component: Component,
  render,
  ...rest
}) {
  const storageItem = localStorage.getItem(process.env.STORAGE_KEY);
  const storageData = JSON.parse(storageItem);
  const token =
    (storageData && storageData.global && storageData.global.token) || null;
  const isAuth = token !== null;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          render && {}.toString.call(render) === '[object Function]' ? (
            render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/sign-up',
              state: {'redirected': true}
            }}
          />
        )
      }
    />
  );
}
