/**
 *
 * Main
 *
 */
import React from 'react';
import styled from "styled-components";

const MainBlock = styled.main`
  background: #fff;
  padding: 0;
`;

function Main({ children, ...rest }) {
  return (
      <MainBlock>
        {children}
      </MainBlock>
  );
}

Main.propTypes = {};

export default Main;
