/*
 *
 * User actions
 *
 */

import {
  USER_GET_ACTION,
  RESET_STATE,
  USER_GET_ERROR_ACTION,
  USER_GET_SUCCESS_ACTION,
  USER_UPDATE_ACTION,
  USER_UPDATE_ERROR_ACTION,
  USER_UPDATE_SUCCESS_ACTION,
  USER_GET_WALLET_LIST_ACTION,
  USER_GET_WALLET_LIST_SUCCESS_ACTION,
  USER_GET_WALLET_LIST_ERROR_ACTION,
  USER_SET_TYPE_PRODUCT,
  USER_DELETE_WALLET_ITEM_ACTION,
  USER_DELETE_WALLET_ITEM_SUCCESS_ACTION,
  USER_DELETE_WALLET_ITEM_ERROR_ACTION,
  HOME_PAGE_GET_USER_SUBSCRIPTION,
  HOME_PAGE_GET_USER_SUBSCRIPTION_ERROR,
  HOME_PAGE_GET_USER_SUBSCRIPTION_SUCCESS,
  FINAL_FORM_UPDATE_ACTION,
  FINAL_FORM_SUBMIT_ACTION,
  FINAL_FORM_SUBMIT_SUCCESS_ACTION,
  FINAL_FORM_SUBMIT_ERROR_ACTION,
  USER_GET_BANK_LIST_ACTION,
  USER_GET_BANK_LIST_SUCCESS_ACTION,
  USER_GET_BANK_LIST_ERROR_ACTION,
  USER_SET_BANK_LIST_STATUS_ACTION,
  USER_SET_BANK_LIST_ERROR_ACTION,
  USER_GET_CASH_OUT_LIST_ACTION,
  USER_GET_CASH_OUT_LIST_SUCCESS_ACTION,
  USER_GET_CASH_OUT_LIST_ERROR_ACTION,
  CLEAR_USER_SUBSCRIPTION
  // plopConstImport
} from './constants';

export function userUpdateAction(payload) {
  return {
    type: USER_UPDATE_ACTION,
    payload,
  };
}

export function resetStateAction() {
  return {
    type: RESET_STATE,
  };
}

export function userUpdateSuccessAction(payload) {
  return {
    type: USER_UPDATE_SUCCESS_ACTION,
    payload,
  };
}

export function userUpdateErrorAction(payload) {
  return {
    type: USER_UPDATE_ERROR_ACTION,
    payload,
  };
}

export function userGetAction(payload) {
  return {
    type: USER_GET_ACTION,
    payload,
  };
}

export function userGetSuccessAction(payload) {
  return {
    type: USER_GET_SUCCESS_ACTION,
    payload,
  };
}

export function userGetErrorAction(payload) {
  return {
    type: USER_GET_ERROR_ACTION,
    payload,
  };
}

export function userGetWalletListAction(payload) {
  return {
    type: USER_GET_WALLET_LIST_ACTION,
    payload,
  };
}

export function userGetWalletListSuccessAction(payload) {
  return {
    type: USER_GET_WALLET_LIST_SUCCESS_ACTION,
    payload,
  };
}

export function userGetWalletListErrorAction(payload) {
  return {
    type: USER_GET_WALLET_LIST_ERROR_ACTION,
    payload,
  };
}

export function userSetTypeProduct(payload) {
  return {
    type: USER_SET_TYPE_PRODUCT,
    payload,
  };
}

export function userDeleteWalletItemAction(payload) {
  return {
    type: USER_DELETE_WALLET_ITEM_ACTION,
    payload,
  };
}

export function userDeleteWalletItemSuccessAction(payload) {
  return {
    type: USER_DELETE_WALLET_ITEM_SUCCESS_ACTION,
    payload,
  };
}

export function userDeleteWalletItemErrorAction(payload) {
  return {
    type: USER_DELETE_WALLET_ITEM_ERROR_ACTION,
    payload,
  };
}

export function homeUserSubscriptionAction() {
  return {
    type: HOME_PAGE_GET_USER_SUBSCRIPTION,
  };
}

export function homePageGetUserSubscriptionSuccessAction(payload) {
  return {
    type: HOME_PAGE_GET_USER_SUBSCRIPTION_SUCCESS,
    payload,
  };
}

export function clearUserSubscription() {
  return {
    type: CLEAR_USER_SUBSCRIPTION
  };
}

export function homePageGetUserSubscriptionsErrorAction(payload) {
  return {
    type: HOME_PAGE_GET_USER_SUBSCRIPTION_ERROR,
    payload,
  };
}


export function finalFormUpdateAction(payload) {
  return {
    type: FINAL_FORM_UPDATE_ACTION,
    payload,
  };
}

export function finalFormSubmitAction(payload) {
  return {
    type: FINAL_FORM_SUBMIT_ACTION,
    payload,
  };
}

export function finalFormSubmitSuccessAction(payload) {
  return {
    type: FINAL_FORM_SUBMIT_SUCCESS_ACTION,
    payload,
  };
}

export function finalFormSubmitErrorAction(payload) {
  return {
    type: FINAL_FORM_SUBMIT_ERROR_ACTION,
    payload,
  };
}

export function userGetBankListAction(payload) {
  return {
    type: USER_GET_BANK_LIST_ACTION,
    payload,
  };
}

export function userGetBankListSuccessAction(payload) {
  return {
    type: USER_GET_BANK_LIST_SUCCESS_ACTION,
    payload,
  };
}

export function userGetBankListErrorAction(payload) {
  return {
    type: USER_GET_BANK_LIST_ERROR_ACTION,
    payload,
  };
}

export function userSetBankListErrorAction(payload) {
  return {
    type: USER_SET_BANK_LIST_ERROR_ACTION,
    payload,
  };
}

export function userSetBankListStatusAction(payload) {
  return {
    type: USER_SET_BANK_LIST_STATUS_ACTION,
    payload,
  };
}

export function userGetCashOutListAction(payload) {
  return {
    type: USER_GET_CASH_OUT_LIST_ACTION,
    payload,
  };
}

export function userGetCashOutListSuccessAction(payload) {
  return {
    type: USER_GET_CASH_OUT_LIST_SUCCESS_ACTION,
    payload,
  };
}

export function userGetCashOutListErrorAction(payload) {
  return {
    type: USER_GET_CASH_OUT_LIST_ERROR_ACTION,
    payload,
  };
}
