export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const MAIN_PLAN = 'Achiever';
export const FREE_PLAN = 'Free';

export const FREE = 'Free'
export const ACHIEVER = 'Achiever';
export const DREAMER = 'Dreamer';
export const PLANNER = 'Planner';
export const ACHIEVER_3_MONTHS = 'Achiever 3 months';
export const DREAMER_3_MONTHS = 'Dreamer 3 months';
export const PLANNER_3_MONTHS = 'Planner 3 months';
export const COOKIE_PRODUCT_ID = '_congrappsSubscriptionProductId';
export const CAREER_TEST_DURATION = 1800; // 30 min
