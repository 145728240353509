/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */

import produce from 'immer';
import {
  SAVE_USER_TOKEN,
  DELETE_USER_TOKEN,
  ADD_BREADCRUMB,
  RESET_BREADCRUMBS,
  POP_BREADCRUMB, HIDE_DISCOUNT_FOOTER,
} from './constants';

// The initial state of the App
export const initialState = {
  token: null,
  breadcrumbs: [],
  isDiscountFooter: true,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SAVE_USER_TOKEN:
        draft.token = action.payload.token;
        break;
      case DELETE_USER_TOKEN:
        draft.token = null;
        break;

      case ADD_BREADCRUMB:
        draft.breadcrumbs = draft.breadcrumbs.concat(action.payload);
        break;
      case POP_BREADCRUMB:
        let index = draft.breadcrumbs.length - 1;
        if (action.payload && action.payload.elementAt) {
          index = action.payload.elementAt;
        }
        if (typeof draft.breadcrumbs[index] !== 'undefined') {
          draft.breadcrumbs.splice(index, 1);
        }
        break;
      case RESET_BREADCRUMBS:
        draft.breadcrumbs = [];
        break;

      case HIDE_DISCOUNT_FOOTER:
        draft.isDiscountFooter = action.payload;
        break;
    }
  });

export default appReducer;
