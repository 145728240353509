/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const ADD_BREADCRUMB = 'app/App/AddBreadcrumb';
export const RESET_BREADCRUMBS = 'app/App/ResetBreadcrumbs';
export const POP_BREADCRUMB = 'app/App/PopBreadcrumb';
export const SAVE_USER_TOKEN = 'app/App/SaveUserToken';
export const SAVE_USER_TOKEN_SUCCESS = 'app/App/SaveUserTokenSuccess';
export const DELETE_USER_TOKEN = 'app/App/DeleteUserToken';
export const HIDE_DISCOUNT_FOOTER = 'app/App/HideDiscountFooter'
