/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { Children } from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import buttonStyles from './buttonStyles';

const StyledButtonDefault = styled.button.attrs((props) => ({
  type: 'button',
}))`
  ${buttonStyles}
`;

const StyledLinkDefault = styled(Link)`
  ${buttonStyles}
`;

const StyledADefault = styled.a`
  ${buttonStyles}
`;

function BaseButton({
  children,
  to,
  href,
  shadow = true,
  StyledA = StyledADefault,
  StyledLink = StyledLinkDefault,
  StyledButton = StyledButtonDefault,
  ...rest
}) {
  if (to !== undefined) {
    return (
      <StyledLink to={to} shadow={shadow ? 1 : 0} {...rest}>
        {children}
      </StyledLink>
    );
  }
  if (href !== undefined) {
    return (
      <StyledA href={href} shadow={shadow ? 1 : 0} {...rest}>
        {children}
      </StyledA>
    );
  }
  return (
    <StyledButton shadow={shadow ? 1 : 0} {...rest}>
      {children}
    </StyledButton>
  );
}

BaseButton.propTypes = {
  color: PropTypes.string,
};

export default BaseButton;
