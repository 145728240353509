/*
 *
 * Geo constants
 *
 */

export const GET_CURRENT_COUNTRY_ACTION = 'app/Geo/GetCurrentCountryAction'
export const GET_CURRENT_COUNTRY_SUCCESS_ACTION = 'app/Geo/GetCurrentCountrySuccessAction'
export const RESET_STATE = 'app/Geo/ResetState';
export const DEFAULT_ACTION = 'app/Geo/DEFAULT_ACTION';
