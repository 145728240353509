/*
 *
 * User reducer
 *
 */
import produce from 'immer';
import { DEFAULT_KEY, generateCacheTTL } from 'redux-cache';
import {
  RESET_STATE,
  USER_DELETE_WALLET_ITEM_ACTION,
  USER_DELETE_WALLET_ITEM_ERROR_ACTION,
  USER_DELETE_WALLET_ITEM_SUCCESS_ACTION,
  USER_GET_ACTION,
  USER_GET_ERROR_ACTION,
  USER_GET_SUCCESS_ACTION,
  USER_GET_WALLET_LIST_ACTION,
  USER_GET_WALLET_LIST_ERROR_ACTION,
  USER_GET_WALLET_LIST_SUCCESS_ACTION,
  USER_SET_TYPE_PRODUCT,
  USER_UPDATE_SUCCESS_ACTION,
  // plopConstImport
  HOME_PAGE_GET_USER_SUBSCRIPTION_SUCCESS,
  FINAL_FORM_UPDATE_ACTION,
  FINAL_FORM_SUBMIT_ACTION,
  FINAL_FORM_SUBMIT_SUCCESS_ACTION,
  FINAL_FORM_SUBMIT_ERROR_ACTION,
  USER_GET_BANK_LIST_ACTION,
  USER_GET_BANK_LIST_SUCCESS_ACTION,
  USER_GET_BANK_LIST_ERROR_ACTION,
  USER_SET_BANK_LIST_STATUS_ACTION,
  USER_SET_BANK_LIST_ERROR_ACTION,
  USER_GET_CASH_OUT_LIST_ACTION,
  USER_GET_CASH_OUT_LIST_SUCCESS_ACTION,
  USER_GET_CASH_OUT_LIST_ERROR_ACTION,
  CLEAR_USER_SUBSCRIPTION,
} from './constants';

export const initialState = {
  [DEFAULT_KEY]: null,

  current: {},
  error: {},
  loading: false,
  walletList: [],
  walletListError: {},
  walletListLoading: false,

  deleteWalletItem: {},
  deleteWalletItemError: {},
  deleteWalletItemLoading: false,
  userSubscription: [],

  finalForm: {},
  finalLoading: false,
  finalError: {},
  linkRedirect: '',

  bankList: [],
  bankListError: {},
  setBankListError: {},
  bankListLoading: false,

  cashOutList: [],
  cashOutListError: {},
  cashOutListLoading: false,
};

/* eslint-disable default-case, no-param-reassign */
const userReducer = (state = initialState, action) =>
  // eslint-disable-next-line consistent-return
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      case USER_UPDATE_SUCCESS_ACTION:
        action.payload = { ...draft.current, ...action.payload };
      // eslint-disable-next-line no-fallthrough
      case USER_GET_SUCCESS_ACTION:
        draft.current = { ...action.payload };
        draft.error = {};
        draft.loading = false;
        draft[DEFAULT_KEY] = generateCacheTTL();
        break;
      case USER_GET_ACTION:
        draft.error = {};
        draft.loading = true;
        break;
      case USER_GET_ERROR_ACTION:
        draft.error = action.payload;
        draft.loading = false;
        break;

      case USER_DELETE_WALLET_ITEM_ACTION:
        draft.walletListError = {};
        draft.walletListLoading = true;

        draft.deleteWalletItemError = {};
        draft.deleteWalletItemLoading = true;
        break;
      case USER_DELETE_WALLET_ITEM_SUCCESS_ACTION:
        draft.deleteWalletItem = action.payload;
        draft.deleteWalletItemError = {};
        draft.deleteWalletItemLoading = false;
        break;
      case USER_DELETE_WALLET_ITEM_ERROR_ACTION:
        draft.deleteWalletItemError = { ...action.payload };
        draft.deleteWalletItem = {};
        draft.deleteWalletItemLoading = false;
        break;

      case USER_GET_WALLET_LIST_ACTION:
        draft.walletListError = {};
        draft.walletListLoading = true;
        break;
      case USER_GET_WALLET_LIST_SUCCESS_ACTION:
        draft.walletList = action.payload;
        draft.walletListError = {};
        draft.walletListLoading = false;
        break;
      case USER_GET_WALLET_LIST_ERROR_ACTION:
        draft.walletListError = { ...action.payload };
        draft.walletList = [];
        draft.walletListLoading = false;
        break;
      case USER_SET_TYPE_PRODUCT:
        draft.typeProduct = action.payload;
        break;
      case HOME_PAGE_GET_USER_SUBSCRIPTION_SUCCESS:
        draft.userSubscription = action.payload;
        break;
      case CLEAR_USER_SUBSCRIPTION:
        draft.userSubscription = [];
        break;
      // plopCasesImport
      case FINAL_FORM_UPDATE_ACTION:
        draft.finalForm = action.payload;
        draft.finalError = {};
        draft.finalLoading = false;
        break;
      case FINAL_FORM_SUBMIT_ACTION:
        draft.finalError = {};
        draft.finalLoading = true;
        draft.linkRedirect = action.payload;
        break;
      case FINAL_FORM_SUBMIT_SUCCESS_ACTION:
        draft.finalError = {};
        draft.finalLoading = false;
        break;
      case FINAL_FORM_SUBMIT_ERROR_ACTION:
        draft.finalError = action.payload;
        draft.finalLoading = false;
        break;

      case USER_GET_BANK_LIST_ACTION:
        draft.bankListError = {};
        draft.bankListLoading = true;
        break;
      case USER_GET_BANK_LIST_SUCCESS_ACTION:
        draft.bankList = action.payload;
        draft.bankListError = {};
        draft.bankListLoading = false;
        break;
      case USER_GET_BANK_LIST_ERROR_ACTION:
        draft.bankListError = { ...action.payload };
        draft.bankList = [];
        draft.bankListLoading = false;
        break;
      case USER_SET_BANK_LIST_ERROR_ACTION:
        draft.setBankListError = { ...action.payload };
        draft.bankListLoading = false;
        break;
      case USER_SET_BANK_LIST_STATUS_ACTION:
        draft.bankListLoading = true;
        draft.setBankListError = {};
        break;

      case USER_GET_CASH_OUT_LIST_ACTION:
        draft.cashOutListError = {};
        draft.cashOutListLoading = true;
        break;
      case USER_GET_CASH_OUT_LIST_SUCCESS_ACTION:
        draft.cashOutList = action.payload;
        draft.cashOutListError = {};
        draft.cashOutListLoading = false;
        break;
      case USER_GET_CASH_OUT_LIST_ERROR_ACTION:
        draft.cashOutListError = { ...action.payload };
        draft.cashOutList = [];
        draft.cashOutListLoading = false;
        break;
    }
  });

export default userReducer;
