/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401) {
    if (localStorage.getItem(process.env.STORAGE_KEY)) {
      localStorage.removeItem(process.env.STORAGE_KEY);
      if (window.location.pathname !== '/sign-in') {
        window.location = window.location;
      }
    }
  }

  return response.json().then((json) => Promise.reject(json));
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options).then(checkStatus).then(parseJSON);
}

function getUrl(urlSegment) {
  const rootUrl = process.env.API_URL;
  return `${rootUrl}/${urlSegment}`;
}

function patchThrough(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const initVal = { status: response.status };
  return response
    .json()
    .then((json) => Promise.reject(Object.assign(json, initVal)));
}

export function requestJSON(
  urlSegment,
  payload,
  token,
  method,
  options,
  handleErrors,
) {
  const url = getUrl(urlSegment);

  let opts = options;
  if (!opts) {
    opts = {};
  }
  if (payload) {
    opts.body = JSON.stringify(payload);
  }

  opts.method = method;

  if (!opts.headers) {
    opts.headers = {
      'Content-Type': 'application/json',
    };
  }

  if (token) {
    opts.headers = Object.assign(
      { Authorization: `Bearer ${token}` },
      opts.headers,
    );
  }

  if (handleErrors) {
    return fetch(url, opts).then(patchThrough).then(parseJSON);
  }

  return fetch(url, opts).then(checkStatus).then(parseJSON);
}

export function requestFile(
  urlSegment,
  payload,
  token,
  method,
  options,
  handleErrors,
) {
  const url = getUrl(urlSegment);

  let opts = options;
  if (!opts) {
    opts = {};
  }
  if (payload) {
    opts.body = payload;
  }

  opts.method = method;

  if (!opts.headers) {
    opts.headers = {
      // 'Content-Type': 'application/json',
    };
  }

  if (token) {
    opts.headers = Object.assign(
      { Authorization: `Bearer ${token}` },
      opts.headers,
    );
  }

  if (handleErrors) {
    return fetch(url, opts).then(patchThrough).then(parseJSON);
  }

  return fetch(url, opts).then(checkStatus).then(parseJSON);
}
