/*
 *
 * SignIn reducer
 *
 */
import produce from 'immer';
import {
  DEFAULT_ACTION,
  RESET_STATE,
  SIGNIN_FORM_SUBMIT_ACTION,
  SIGNIN_FORM_SUBMIT_ERROR_ACTION,
  SIGNIN_FORM_SUBMIT_SUCCESS_ACTION,
  SIGNIN_FORM_SUBMIT_SUCCESS_ACTION_WITH_UPLOAD,
  SIGNIN_FORM_UPDATE_ACTION,
  SIGNIN_SOCIAL_SUBMIT_ACTION,
  SIGNIN_SOCIAL_UPDATE_ACTION,
} from './constants';

export const initialState = {
  form: {},
  socialForm: {},
  error: {},
  loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const signInReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      case SIGNIN_FORM_UPDATE_ACTION:
        draft.form = action.payload;
        draft.error = {};
        draft.loading = false;
        break;
      case SIGNIN_FORM_SUBMIT_ACTION:
        draft.error = {};
        draft.loading = true;
        break;
      case SIGNIN_SOCIAL_UPDATE_ACTION:
        draft.socialForm = action.payload;
        draft.error = {};
        draft.loading = false;
        break;
      case SIGNIN_SOCIAL_SUBMIT_ACTION:
        draft.error = {};
        draft.loading = true;
        break;
      case SIGNIN_FORM_SUBMIT_SUCCESS_ACTION:
        draft.error = {};
        draft.loading = false;
        break;
      case SIGNIN_FORM_SUBMIT_SUCCESS_ACTION_WITH_UPLOAD:
        draft.loading = false;
        break;
      case SIGNIN_FORM_SUBMIT_ERROR_ACTION:
        draft.error = action.payload;
        draft.loading = false;
        break;
    }
  });

export default signInReducer;
