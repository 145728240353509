/**
 *
 * SignUp
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Divider from '../../components/Divider';
import {
  FormGroup,
} from '../../components/Form';
import { Link, withRouter } from 'react-router-dom';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import styled, { css } from 'styled-components';
import {
  makeSelectSignUpError,
  makeSelectSignUpForm,
  makeSelectSignUpLoading,
  makeSelectSignUpValidateReferralCode,
  makeSelectSignUpValidateReferralCodeError,
  makeSelectSignUpSocial
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import PageChrome from '../../components/PageChrome';
import {
  resetStateAction,
  signupFormSubmitAction,
  signupFormUpdateAction,
  signUpValidateReferralCodeAction,
  signupSocialSubmitAction,
  signupSocialUpdateAction
} from './actions';
import Button from '../../components/Button';
import { getCompanyIndustryListAction } from '../Company/actions';
import { makeSelectCompanyIndustryList } from '../Company/selectors';
import companyReducer from '../Company/reducer';
import companySaga from '../Company/saga';
import useQuery from '../../utils/useQuery';
import { resetBreadcrumbs } from '../App/actions';
import InputField from '../../components/InputField';
import usePrevious from '../../utils/usePrevious';
import { getCookie, isEmptyObject } from "../../utils/utils";
import SocialLogin from "../../components/SocialLogin";
import Main from "../../components/Main";
import AuthView, { AuthPadding, AuthSubTitle, AuthSubtitlePadding, AuthTitle } from "../../components/AuthView";
import AuthViewList from "../../components/AuthViewList";
import AuthViewForm from "../../components/AuthViewForm";
import { makeSelectStatsGet } from "../Stats/selectors";
import statsReducer from "../Stats/reducer";
import statsSaga from "../Stats/saga";
import { statsGetAction } from "../Stats/actions";
import { COOKIE_PRODUCT_ID } from "../../utils/constants";
import { push } from "connected-react-router";

export const LeftAlign = styled.p`
  text-align: left;
`;

const PreloaderMessage = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 35%;
  font-weight: 700;
  font-size: 1.6rem;
`;

const SpinnerLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #ff411d;
  ${(props) =>
    props.active &&
    css`
      /* durations: 4 * ARCTIME */
      opacity: 1;
      -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1)
        infinite both;
      animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
        both;
    `}
`;

const PreloaderWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  ${(props) =>
    props.active &&
    css`
      /* duration: 360 * ARCTIME / (ARCSTARTROT + (360-ARCSIZE)) */
      -webkit-animation: container-rotate 1568ms linear infinite;
      animation: container-rotate 1568ms linear infinite;
    `}

  div${SpinnerLayer} {
    // border-color:#475ba1;
  }
`;

const PreloaderOverlay = styled.div`
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  position: fixed;
  z-index: 999;

  div${PreloaderWrapper} {
    position: fixed;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }
`;

const Circle = styled.div`
  border-radius: 50%;
`;

const GapPatch = styled.div`
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;

  div${Circle} {
    width: 1000%;
    left: -450%;
  }
`;

const CircleClipper = styled.div`
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;

  div${Circle} {
    width: 200%;
    height: 100%;
    border-width: 3px;
    /* STROKEWIDTH */
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    ${(props) =>
      props.left &&
      css`
        left: 0;
        border-right-color: transparent !important;
        -webkit-transform: rotate(129deg);
        transform: rotate(129deg);
        ${(props) =>
    props.active &&
          css`
            -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
              infinite both;
            animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
              both;
          `}
      `}

    ${(props) =>
      props.right &&
      css`
        left: -100%;
        border-left-color: transparent !important;
        -webkit-transform: rotate(-129deg);
        transform: rotate(-129deg);
        ${(props) =>
    props.active &&
          css`
            /* duration: ARCTIME */
            -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1)
              infinite both;
            animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite
              both;
          `}
      `}
  }

  ${(props) => props.active && css``}
`;

export const RegisterButton = styled(Button)`
  margin: ${(props) => props.top || '20px'} auto 0;
  font-size: 22px;

  @media screen and (max-width: 600px) {
    margin: 0 auto;
  }
`;

export const TosPrivacy = styled.div`
  padding: 15px;
  margin-bottom: 15px;

  label {
    color: #374266;
    font-weight: 700;
    font-size: 15px;
    line-height: 28px;

    @media (max-width: 620px) {
      line-height: 20px;
      margin-bottom: 8px;
    }

    span {
      display: block;
    }

    a {
      color: #589CE8;
    }
  }

  & > div {
    display: flex;
    align-items: center;
  }
`;

const centerSectionListDefault = [
  {
    title: 'Account Information',
    fields: [
      {
        type: 'text',
        label: 'Email',
        name: 'email',
        helperText:
          'This will remain confidential and will not be visible to anyone.',
      },

      {
        name: 'interested_industries',
        type: 'select',
        label: 'Interested Industries:',
        helperText:
          'After selecting your Interested Industry, you will receive an industry Pre-selected Successful Application as a gift. Enjoy! ',
        items: [],
        isMulti: true,
        labelAttribute: 'title',
        valueAttribute: 'id',
      },
    ],
  },
  {
    title: 'Security',
    fields: [
      {
        type: 'password',
        label: 'Password',
        name: 'password',
      },
      {
        type: 'password',
        label: 'Confirm Password',
        name: 'password_confirm',
      },
    ],
  },
  {
    fields: [
      {
        type: 'text',
        label: 'Referral Code',
        name: 'referral_code',
        optional: true,
        validation: false,
      },
    ],
  },
];

export function SignUp({
  history,
  dispatch,
  form,
  loading,
  errors,
  signupFormUpdate,
  signupFormSubmit,

  companyIndustryList,

  referralCode,
  location,
  socialForm,
  socialUpdate,
  socialSubmit, stats,
}) {
  useInjectReducer({ key: 'signUp', reducer });
  useInjectSaga({ key: 'signUp', saga });

  useInjectReducer({ key: 'stats', reducer: statsReducer });
  useInjectSaga({ key: 'stats', saga: statsSaga });

  useInjectReducer({ key: 'company', reducer: companyReducer });
  useInjectSaga({ key: 'company', saga: companySaga });
  const [pageTitle, setPageTitle] = useState('Create new account.');
  const [fromSignUp, setFromSignUp] = useState(false);
  const [centerSectionList, setCenterSectionList] = useState(
    centerSectionListDefault,
  );
  const [errorsList, setErrorsList] = useState({})

  const query = useQuery();

  useEffect(() => {

    const referral_code = query.get('referral');
    if (form.referral_code !== referral_code) {
      onFormChange({
        target: {
          name: 'referral_code',
          value: referral_code,
        },
      });
    }
  }, [query]);

  const onFormChange = (evt) => {
    if (evt !== undefined && evt.persist) evt.persist();
    const newForm = { ...form };
    newForm[evt.target.name] = evt.target.value;
    signupFormUpdate(newForm);
  };

  const handleSocialSignin = (payload) => {

    const data = payload.data;
    const newSocialForm = { ...socialForm };
    if(payload.item === 'facebook') {
      newSocialForm['access_token'] = data.accessToken;
    } else {
      newSocialForm['code'] = data.code;
    }
    newSocialForm['item'] = payload.item;
    socialUpdate(newSocialForm);
    socialSubmit();
  }

  useEffect(() => {
    if(isEmptyObject(stats)) {
      dispatch(statsGetAction());
    }

    const cleanup = () => {
      dispatch(resetStateAction());
      dispatch(resetBreadcrumbs());
    };
    dispatch(getCompanyIndustryListAction());
    return cleanup;
  }, []);

  useEffect(() => {
    if (!isEmptyObject(referralCode)) {
      if (referralCode.status) {
        centerSectionList[2].fields[0].validation = '.*';
      } else {
        centerSectionList[2].fields[0].validation = '$^';
      }
      centerSectionList[2].fields[0].valid = referralCode.status;

      centerSectionList[2].fields[0].helperText = referralCode.reason;
      setCenterSectionList([...centerSectionList]);
    }
  }, [referralCode]);

  useEffect(() => {
    if (
      companyIndustryList.length > 0 &&
      centerSectionList[0].fields[1].items.length <= 0
    ) {
      centerSectionList[0].fields[1].items = companyIndustryList;
      centerSectionList[2].fields[0].handleOnBlur = () => {
        dispatch(
          signUpValidateReferralCodeAction({
            referralCode: form.referral_code,
          }),
        );
      };

      setCenterSectionList([...centerSectionList]);
    }
    if (!isEmptyObject(location.state) && location.state.redirected) {
      setFromSignUp(true);
      setPageTitle('Step 1/2: Sign Up First');
      // setIsCustomPageTitle(true);
    }
  }, [companyIndustryList]);

  useEffect(() => {
    setErrorsList(errors);
  }, [errors]);

  const prevReferrralCode = usePrevious(form.referral_code);
  const [referralCodeTimeout, setReferralCodeTimeout] = useState(null);

  useEffect(() => {
    console.log(
      form,
      prevReferrralCode,
      form.referral_code && prevReferrralCode === null,
    );
    if (
      (form.referral_code &&
        prevReferrralCode &&
        form.referral_code !== prevReferrralCode) ||
      (form.referral_code && prevReferrralCode === null)
    ) {
      if (referralCodeTimeout !== null) {
        clearTimeout(referralCodeTimeout);
      }

      setReferralCodeTimeout(
        setTimeout(() => {
          dispatch(
            signUpValidateReferralCodeAction({
              referralCode: form.referral_code,
            }),
          );
          // Call saga check
        }, 1500),
      );
    }
  }, [form.referral_code, prevReferrralCode]);

  const handlerFormSubmit = (values) => {
    const location = history.location.state;
    let link = location && location.from ? location.from : '';

    signupFormUpdate(values);
    signupFormSubmit(link);
  }

  const initialValues = {
    email: null,
    interested_industries: null,
    password: null,
    password_confirm: null,
    referral_code: null,
  };

  return (
    <PageChrome showBackground={false} showFooter={false} isFixedMinWidth={false}>
      <Helmet>
        <title>Sign Up | Congrapps</title>
        <meta
          name="description"
          content="Register with Congrapps now to access 100% verified previously successful cover letter and application samples for Investment Banking, Law, Consulting and Accounting"
        />
      </Helmet>
      <Main>
        <AuthView>
          <AuthViewList stats={stats} />
          <AuthViewForm>
            <AuthTitle><h1>{pageTitle}</h1></AuthTitle>
            <AuthSubtitlePadding>
              <AuthSubTitle>Already a member <Link to={{
                pathname: "/sign-in",
                state: { fromSignUp }
              }} className="highlight-blue">log in</Link></AuthSubTitle>
            </AuthSubtitlePadding>
            <SocialLogin loginListener={handleSocialSignin}/>
            <AuthPadding padding={"13px 0 9px"}>
              <AuthSubTitle>or continue with email</AuthSubTitle>
            </AuthPadding>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                /*               email: Yup.string().required('Email is required'),
                              password: Yup.string().required('Password is required'),  */
              })}
              onSubmit={(values) => handlerFormSubmit(values)}
            >
              {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setTouched,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <div>
                      <InputField
                        name="email"
                        type="email"
                        form={values}
                        onChange={handleChange}
                        errors={errorsList}
                        label="Email"
                      />
                    </div>
                    <div>
                      <InputField
                        name="password"
                        type="password"
                        inputValid={true}
                        form={values}
                        value={values.password}
                        onChange={handleChange}
                        errors={errorsList}
                        label="Password"
                      />
                      <InputField
                        name="password_confirm"
                        type="password"
                        inputValid={true}
                        form={values}
                        value={values.password_confirm}
                        onChange={handleChange}
                        errors={errorsList}
                        label="Repeat Password"
                      />
                      <InputField
                        name="referral_code"
                        type="text"
                        form={values}
                        optional={true}
                        validation={false}
                        value={values.referral_code}
                        onChange={handleChange}
                        errors={errorsList}
                        label="Referral Code"
                      />
                    </div>
                  </FormGroup>
                  <TosPrivacy>
                    <div>
                      <InputField
                        name="tos_consent"
                        type="checkbox"
                        form={values}
                        errors={errorsList}
                        onChange={handleChange}
                        label={
                          <>
                            I have read and agree with this Congrapps’ <Link to="/terms-and-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy Policy</Link>
                          </>
                        }
                      />
                    </div>
                    <div>
                      <InputField
                        name="marketing_consent"
                        type="checkbox"
                        form={values}
                        errors={errorsList}
                        onChange={handleChange}
                        label="I would like to receive the 20% Initial Discount Code and Future Promotions"
                      />
                    </div>
                  </TosPrivacy>
                  <RegisterButton type="submit" color="cyanBlue" id="register" height='60px' width="100%" radius="8px" top='0'>
                    Create Account
                  </RegisterButton>
                </form>
              )}
            </Formik>
          </AuthViewForm>
        </AuthView>

        {loading && (
          <PreloaderOverlay>
            <PreloaderMessage>Setting up your account...</PreloaderMessage>
            <PreloaderWrapper active>
              <SpinnerLayer active>
                <CircleClipper active left>
                  <Circle />
                </CircleClipper>
                <GapPatch>
                  <Circle />
                </GapPatch>
                <CircleClipper active right>
                  <Circle />
                </CircleClipper>
              </SpinnerLayer>
            </PreloaderWrapper>
          </PreloaderOverlay>
        )}
      </Main>
    </PageChrome>
  );
}

SignUp.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  form: makeSelectSignUpForm(),
  loading: makeSelectSignUpLoading(),
  errors: makeSelectSignUpError(),
  companyIndustryList: makeSelectCompanyIndustryList(),
  referralCode: makeSelectSignUpValidateReferralCode(),
  referralCodeError: makeSelectSignUpValidateReferralCodeError(),
  socialForm: makeSelectSignUpSocial(),
  stats: makeSelectStatsGet(),
});

function mapDispatchToProps(dispatch) {
  return {
    signupFormUpdate: (payload) => dispatch(signupFormUpdateAction(payload)),
    signupFormSubmit: (linkRedirect) => dispatch(signupFormSubmitAction(linkRedirect)),
    socialUpdate: (payload) => dispatch(signupSocialUpdateAction(payload)),
    socialSubmit: () => dispatch(signupSocialSubmitAction()),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withRouter)(SignUp);
