export const studiesList = [
  {
    value: 1,
    label: 'Undergraduate',
  },
  {
    value: 2,
    label: 'Postgraduate',
  },
];
