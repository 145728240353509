import { useEffect } from 'react';

export default function useScrollListener(callback = () => {}, ms = 150) {
  let onFinishedId;
  let scrollStarted = false;
  let scrollDirection = 'down';
  let scrollTop = 0;
  let prevScrollTop = scrollTop;

  useEffect(() => {
    function handleUpdate(event, scrollState) {
      const updateScrollTop = (e) => {
        prevScrollTop = scrollTop;
        scrollTop = e.target.scrollingElement.scrollTop;
      };

      const updateScrollDirection = (e) => {
        const top = e.target.scrollingElement.scrollTop;
        scrollDirection = top > prevScrollTop ? 1 : -1;
      };
      updateScrollTop(event);
      updateScrollDirection(event);
      return {
        scrollDirection,
        scrollTop,
        scrollState,
        prevScrollTop,
        scrollElement: event.target.scrollingElement,
      };
    }

    const handleScroll = (event) => {
      if (!scrollStarted) {
        scrollStarted = true;
        const payload = handleUpdate(event, 'start');
        callback(payload);
      }

      if (onFinishedId !== null) {
        clearTimeout(onFinishedId);
      }

      onFinishedId = setTimeout(() => {
        const payload = handleUpdate(event, 'end');
        callback(payload);
        scrollStarted = false;
      }, ms);
    };
    window.addEventListener('scroll', handleScroll, false);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
}
