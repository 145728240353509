/**
 *
 * NavSignIn
 *
 */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Link, useHistory} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import styled, {css} from 'styled-components';
import {makeSelectUserToken} from '../App/selectors';
import buttonStyles from '../../components/BaseButton/buttonStyles';
import './NavSignIn.scoped.scss';
import {makeSelectUser} from '../User/selectors';
import {userGetAction} from '../User/actions';
import {getUserSubscription} from '../Stats/selectors';
import {getUserSubscriptionAction} from '../Stats/actions';
import {makeSelectCountry} from "../Geo/selectors";
import {getCurrentCountryAction} from "../Geo/actions";
import getSymbolFromCurrency from "currency-symbol-map";
import credits from '../../images/congrappsCredits.svg';
import personLight from '../../images/person-light.svg';
import { Colors } from "../../styles/Theme";

const NavButton = styled(Link)`
  ${buttonStyles};
  border-radius: 8px;
  padding: 0 35px;
  margin: 0 11px 0;
  font-weight: 700;
  font-size: 16px !important;
  height: 30px;
  line-height: 34px;
  box-shadow: none;
  transition: 0.2s ease;
  width: 133px;

  text-transform: uppercase;
`;

const SignInButton = styled(NavButton)`
  background-color: #fff !important;
  color: #589CE8;

  @media screen and (min-width: 2000px) {
    height: 1.822vw;
    font-size: 0.83vw !important;
    width: 6.92vw;
  }
`;

const SignUpButton = styled(NavButton)`
  background-color: #589CE8 !important;
  color: #fff;

  &:hover {
    background-color: #61a9f6 !important;
    color: #fff;
  }
`;

const NotLoggedInWrapper = styled.div`
  display: flex;
  margin-left: 0;

  @media screen and (min-width: 1024px) {
    align-items: center;
    height: 64px;
    padding-left: 12px;
    border-left: 1px solid #E5E5E5;
  }


  @media screen and (max-width: 1024px) {
    margin-left: unset;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
`;

const SubscriptionBtnCss = css`
  border-radius: 8px;
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  min-width: 133px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GetAccessBtn = styled(Link)`
  ${SubscriptionBtnCss};
  background: #FFA531;
  white-space: nowrap;
`;

// const HasAccessBtn = styled(Link)`
//   ${SubscriptionBtnCss};
//   background: #589CE8;
// `;

const UserCredits = styled.div`
  padding-right: 10px;
  font-family: Inter, sans-serif;
  color: ${Colors.darkBlue};
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
`;

const CreditsImage = styled.img`
  width: 30px!important;
  height: 30px!important;
  display: block;
  margin-bottom: 0!important;
`;

const UserImage = styled(Link)`
  width: 30px;
  height: 30px;
  background: #589CE8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 19px;
  cursor: pointer;
  margin-bottom: 0;

  img {
    display: block;
    width: 30px;
    height: 30px;
    margin-bottom: 0!important;
  }
`;

export function NavSignIn({dispatch, token, user = {}, userSubscriptions, country}) {

  useEffect(() => {
    if (token) {
      dispatch(userGetAction());
      dispatch(getUserSubscriptionAction());
      dispatch(getCurrentCountryAction())
    }
  }, [token]);

  const history = useHistory();

  function handleClick() {
    history.push('/home');
  }

  const todayDate = new Date().toISOString();

  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('£');

  useEffect(() => {
    if (!!country['code']) {
      setCurrencySymbol(getSymbolFromCurrency(country['code']))
    }
  }, [country])

  useEffect(() => {
    if (userSubscriptions.length > 0) {
      const result = [];
      userSubscriptions.forEach((userSubscription) => {
        if (userSubscription.is_active === true) {
          if (userSubscription.subscription_product.type === 'Corporate Astronaut') {
            result.push('Achiever');
          } else {
            result.push((userSubscription.cancel_at || todayDate) >= todayDate && userSubscription.subscription_product.type);
          }
        }
      });

      setActiveSubscriptions(result);

      setHasSubscription(result.length > 0);

    }

  }, [userSubscriptions]);

  if (token) {
    return (
      <div className="header-profile">
        <ul>
          {/*<li className="karma">*/}
          {/*  <Link to="/congrapps-credit">{currencySymbol}{user.karma_amount / 100} credit</Link>*/}
          {/*</li>*/}
          <li>
            {hasSubscription ? (
              <Link to="/my-account/accessed-applications">
                <button
                  className={`subscription-type-btn active${activeSubscriptions.length > 1 ? " smaller" : ""}`}>
                  {activeSubscriptions.map((item, index) => (
                    <span key={index}>{item}</span>
                  ))}
                </button>
              </Link>
            ) : null}
            {!hasSubscription ? (
              <GetAccessBtn to="/pricing">
                Access
              </GetAccessBtn>
            ) : null}
          </li>

          <li className="divider"/>
          <li className="profile">
            <Link to="/application/upload/">
              <UserCredits>£{user.karma_amount / 100}</UserCredits>
            </Link>
            <Link to="/application/upload/">
              <CreditsImage src={credits} alt="Credits" />
            </Link>
            <UserImage to="/my-account/">
              <img src={personLight} alt="Dashboard" />
            </UserImage>
            {/*<span className="lvl">LVL 7</span>*/}
            {/*<Link to="/my-account/">Dashboard</Link>*/}
          </li>
        </ul>
      </div>
    );
  }
  return (
    <NotLoggedInWrapper className="signin-profile">
      <SignInButton to="/sign-in/">Sign In</SignInButton>
      {/*<SignUpButton to="/sign-up/">Register</SignUpButton>*/}
    </NotLoggedInWrapper>
  );
}

NavSignIn.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const mapStateToProps = createStructuredSelector({
  token: makeSelectUserToken(),
  user: makeSelectUser(),
  userSubscriptions: getUserSubscription(),
  country: makeSelectCountry()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(NavSignIn);
