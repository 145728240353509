import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the stats state domain
 */

const selectStatsDomain = (state) => state.stats || initialState;

/**
 * Other specific selectors
 */

//plopImport

const makeSelectStatsGet = () =>
  createSelector(selectStatsDomain, (substate) => substate.get);

const makeSelectStatsGetLoading = () =>
  createSelector(selectStatsDomain, (substate) => substate.getLoading);

const makeSelectStatsGetError = () =>
  createSelector(selectStatsDomain, (substate) => substate.getError);

const getUserSubscription = () =>
  createSelector(selectStatsDomain, (substate) => substate.userSubscription);

/**
 * Default selector used by Stats
 */

const makeSelectStats = () =>
  createSelector(selectStatsDomain, (substate) => substate);

export {
  makeSelectStatsGet,
  makeSelectStatsGetLoading,
  makeSelectStatsGetError,
  makeSelectStats,
  getUserSubscription,
};
