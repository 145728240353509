import {
  call,
  put,
  takeLeading,
} from '@redux-saga/core/effects';

import {
 GET_CURRENT_COUNTRY_ACTION,
} from './constants';
import {getCurrentCountryAction, getCurrentCountrySuccessAction} from "./actions";
import {requestJSON} from "../../utils/request";


// plopSagaExports

export function* getCurrentCountry() {
  // Select username from store
  const requestURL = `geo/country`;
  try {
    // Call our request helper (see 'utils/request')
    // Prepare for geo location currency, enable this after prepare finish
    const result = yield call(requestJSON, requestURL, null, null, 'GET');
    yield put(getCurrentCountrySuccessAction(result));
    // yield put(getCurrentCountrySuccessAction({
    //   "country_code": "GB",
    //   "country_name": "United Kingdom",
    //   "code": "GBP"
    // }));

  } catch (err) {
    console.log('there is an error: ', err);
    yield put(getCurrentCountrySuccessAction({
      "country_code": "GB",
      "country_name": "United Kingdom",
      "code": "GBP"
    }));
  }
}

// Individual exports for testing
export default function* geoSaga() {
  yield takeLeading(GET_CURRENT_COUNTRY_ACTION, getCurrentCountry);
}
