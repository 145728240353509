/*
 *
 * Stats actions
 *
 */

import {
  DEFAULT_ACTION,
  RESET_STATE,
  // plopConstImport
  STATS_GET_ACTION,
  STATS_GET_SUCCESS_ACTION,
  STATS_GET_ERROR_ACTION,
  GET_USER_SUBSCRIPTION_ACTION,
  GET_USER_SUBSCRIPTION_SUCCESS_ACTION,
  GET_USER_SUBSCRIPTION_ERROR_ACTION,
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function resetStateAction() {
  return {
    type: RESET_STATE,
  };
}

export function statsGetAction() {
  return {
    type: STATS_GET_ACTION,
  };
}

export function statsGetSuccessAction(payload) {
  return {
    type: STATS_GET_SUCCESS_ACTION,
    payload,
  };
}

export function statsGetErrorAction(payload) {
  return {
    type: STATS_GET_ERROR_ACTION,
    payload,
  };
}

export function getUserSubscriptionAction() {
  return {
    type: GET_USER_SUBSCRIPTION_ACTION,
  };
}

export function getUserSubscriptionSuccessAction(payload) {
  return {
    type: GET_USER_SUBSCRIPTION_SUCCESS_ACTION,
    payload
  };
}

export function getUserSubscriptionErrorAction(payload) {
  return {
    type: GET_USER_SUBSCRIPTION_ERROR_ACTION,
    payload
  };
}
