import {
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from '@redux-saga/core/effects';
import { requestFile, requestJSON } from '../../utils/request';
import { makeSelectUserToken} from '../App/selectors';
import {
  finalFormSubmitErrorAction,
  finalFormSubmitSuccessAction,
  homePageGetUserSubscriptionsErrorAction,
  homePageGetUserSubscriptionSuccessAction,
  userDeleteWalletItemErrorAction,
  userDeleteWalletItemSuccessAction,
  userGetErrorAction,
  userGetSuccessAction,
  userGetWalletListAction,
  userGetWalletListErrorAction,
  userGetWalletListSuccessAction,
  userUpdateAction,
  userUpdateErrorAction,
  userUpdateSuccessAction,
  userGetBankListAction,
  userGetBankListErrorAction,
  userGetBankListSuccessAction,
  userSetBankListErrorAction,
  userGetCashOutListSuccessAction,
  userGetCashOutListErrorAction,
  // plopActionImport
} from './actions';
import {
  USER_DELETE_WALLET_ITEM_ACTION,
  USER_GET_ACTION,
  USER_GET_WALLET_LIST_ACTION,
  USER_UPDATE_ACTION,
  // plopConstImport
  HOME_PAGE_GET_USER_SUBSCRIPTION,
  FINAL_FORM_SUBMIT_ACTION,
  USER_GET_BANK_LIST_ACTION,
  USER_SET_BANK_LIST_STATUS_ACTION, USER_GET_CASH_OUT_LIST_ACTION,
} from './constants';
import selectUserDomain, {makeSelectFinalForm} from './selectors';
import { checkCacheValid } from '../../utils/checkCacheValid';
import {signinFormSubmitSuccessAction} from "../SignIn/actions";

// plopSagaExports

export function* userUpdate(action) {
  // Select username from store
  const token = yield select(makeSelectUserToken());
  const { payload } = action;
  const requestURL = `user/`;

  try {
    const formData = new FormData();

    for (const property in payload) {
      if (payload.hasOwnProperty(property)) {
        formData.append(property, payload[property]);
      }
    }
    // Call our request helper (see 'utils/request')
    const result = yield call(requestFile, requestURL, formData, token, 'PUT');
    yield put(userUpdateSuccessAction(result));
    yield put(userGetSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);

    yield put(userUpdateErrorAction(err));
  }
}

export function* userGet(action) {
  // Select username from store
  const state = yield select();
  const isCacheValid = checkCacheValid(selectUserDomain(state));
  const isForced = (action.payload && action.payload.force) || false;
  if (isCacheValid && !isForced) {

    console.log('isCacheValid && !isForced',  isCacheValid, isForced);

    return null;
  }
  const token = yield select(makeSelectUserToken());
  const requestURL = `user/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    homePageGetUserSubscription();
    yield put(userGetSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);

    yield put(userGetErrorAction(err));
  }
}

export function* userGetWalletList(action) {
  // Select username from store
  const state = yield select();
  // const isCacheValid = checkCacheValid(selectUserDomain(state));
  // if (isCacheValid) {
  //   return null;
  // }
  const token = yield select(makeSelectUserToken());
  const requestURL = `user/wallet/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(userGetWalletListSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);
    yield put(userGetWalletListErrorAction(err));
  }
}

export function* userDeleteWalletItem(action) {
  // Select username from store
  const token = yield select(makeSelectUserToken());
  const { id } = action.payload;
  const requestURL = `user/wallet/${id}/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'DELETE');
    yield put(userGetWalletListAction(result));
    yield put(userDeleteWalletItemSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);
    yield put(userDeleteWalletItemErrorAction(err));
  }
}

export function* homePageGetUserSubscription() {
  const requestURL = `subscription/access/`;
  try {
    const result = yield call(requestJSON, requestURL, null, null, 'GET');
    yield put(homePageGetUserSubscriptionSuccessAction(result));
  } catch (err) {
    yield put(homePageGetUserSubscriptionsErrorAction(err));
  }
}

export function* finalFormSubmit() {
  // Select username from store
  const token = yield select(makeSelectUserToken());
  const payload = yield select(makeSelectFinalForm());
  const requestURL = `user/final/`;

  try {
    const result = yield call(requestJSON, requestURL, payload, token, 'PUT');
    yield put(userUpdateSuccessAction(result));
    yield put(finalFormSubmitSuccessAction());
  } catch (err) {
    console.log('there is an error: ', err);
    yield put(finalFormSubmitErrorAction(err));
  }
}

export function* userGetBankList() {
  // Select username from store
  const state = yield select();
  // const isCacheValid = checkCacheValid(selectUserDomain(state));
  // if (isCacheValid) {
  //   return null;
  // }
  const token = yield select(makeSelectUserToken());
  const requestURL = `user/bank/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(userGetBankListSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);
    yield put(userGetBankListErrorAction(err));
  }
}

export function* userSetBankListStatus(action) {
  const token = yield select(makeSelectUserToken());
  const data = action.payload
  const formData = {
    account_number: data?.safe_account_number,
    sort_code: data?.safe_sort_code,
    paypal_id: data?.safe_paypal_id,
    email: data?.email,
    first_name: data?.first_name,
    last_name: data?.last_name,
    payment_type: data?.payment_type,
    id: data?.id,
  }

  const requestURL = `user/create/bank/`;

  let method = 'POST'
  if(formData.id) {
    method = 'PUT'
  }

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, formData, token, method);

    if(result.status) {
      yield put(userGetBankListAction());
    }
  } catch (err) {
    yield put(userSetBankListErrorAction(err));
  }

}

export function* userGetCashOutList() {
  const state = yield select();

  const token = yield select(makeSelectUserToken());
  const requestURL = `user/cash-outs/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(userGetCashOutListSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);
    yield put(userGetCashOutListErrorAction(err));
  }
}

// Individual exports for testing
export default function* userSaga() {
  // plopDefaultSagaExports
  yield takeLatest(USER_UPDATE_ACTION, userUpdate);
  yield takeLeading(USER_GET_ACTION, userGet);
  yield takeLatest(USER_GET_WALLET_LIST_ACTION, userGetWalletList);
  yield takeLatest(USER_GET_BANK_LIST_ACTION, userGetBankList);
  yield takeLatest(USER_GET_CASH_OUT_LIST_ACTION, userGetCashOutList);
  yield takeLatest(USER_SET_BANK_LIST_STATUS_ACTION, userSetBankListStatus);
  yield takeLatest(
    HOME_PAGE_GET_USER_SUBSCRIPTION,
    homePageGetUserSubscription,
  );
  yield takeLatest(USER_DELETE_WALLET_ITEM_ACTION, userDeleteWalletItem);
  yield takeLatest(FINAL_FORM_SUBMIT_ACTION, finalFormSubmit);
}
