/**
 *
 * AuthViewForm
 *
 */
import React from 'react';
import styled from "styled-components";
import { Metrics } from "../../styles/Theme";



const Container = styled.div`
  width: 61.51%;
  padding: ${Metrics.mainPaddingTop} 81px ${Metrics.mainPaddingBottom};

  @media (max-width: 1300px) {
    padding-top: 40px;
    width: 55%;
  }

  @media (max-width: 1650px) {
    padding-left: 4%;
    padding-right: 4%;
  }

  & > div {
    width: 100%;
    max-width: 712px;
    margin: 0 auto;
  }

  @media (max-width: 992px) {
    width: 100%;
    order: 1;

    padding-left: 15px;
    padding-right: 15px;
  }
`;

function AuthViewForm({ children, ...rest }) {
  return (
    <Container>
      <div>
        {children}
      </div>
    </Container>
  );
}

AuthViewForm.propTypes = {};

export default AuthViewForm;
