export const INTERVIEW_FAQ_GET_LIST_ACTION =
  'app/InterviewSimulatorLanding/faqInterviewSimulatorGetListAction'
export const INTERVIEW_FAQ_GET_LIST_SUCCESS_ACTION =
  'app/InterviewSimulatorLanding/faqInterviewSimulatorGetListSuccessAction'
export const INTERVIEW_FAQ_GET_LIST_ERROR_ACTION =
  'app/InterviewSimulatorLanding/faqInterviewSimulatorGetListErrorAction'
export const INTERVIEW_QUESTIONS_GET_LIST_ACTION =
  'app/InterviewSimulatorLanding/questionsInterviewSimulatorGetListAction'
export const INTERVIEW_QUESTIONS_GET_LIST_SUCCESS_ACTION =
  'app/InterviewSimulatorLanding/questionsInterviewSimulatorGetListSuccessAction'
export const INTERVIEW_QUESTIONS_GET_LIST_ERROR_ACTION =
  'app/InterviewSimulatorLanding/questionsInterviewSimulatorGetListErrorAction'
export const INTERVIEW_QUESTIONS_TRACK_ACTION =
  'app/InterviewSimulatorLanding/questionsInterviewSimulatorTrackAction'
export const REDIRECT_LINK = 'redirect_link'

export const statistics = [
  {
    param: 'company_total',
    default: '',
    label: 'Company Profiles'
  },
  {
    param: 'application_total',
    default: '',
    label: 'Past Applications'
  },
  {
    param: 'interview_experience_total',
    default: '',
    label: 'Interview Testimonials'
  },
  {
    param: 'premium_career_course',
    default: '',
    label: 'Premium Career Courses'
  },
  {
    param: '',
    default: '103',
    label: 'Cover Letters Reviewed'
  },
  {
    param: '',
    default: '231',
    label: 'Interviews Simulated'
  },
  {
    param: '',
    default: '263',
    label: 'Tracked Applications'
  },
  {
    param: 'happy_application_total',
    default: '',
    label: 'Happy Customers'
  },
];
