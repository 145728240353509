/*
 *
 * Stats constants
 *
 */

export const DEFAULT_ACTION = 'app/Stats/DEFAULT_ACTION';
export const RESET_STATE = 'app/Stats/ResetState';

export const STATS_GET_ACTION = 'app/Stats/GetAction';
export const STATS_GET_SUCCESS_ACTION = 'app/Stats/GetSuccessAction';
export const STATS_GET_ERROR_ACTION = 'app/Stats/GetErrorAction';

export const GET_USER_SUBSCRIPTION_ACTION = 'GetUserSubscription';
export const GET_USER_SUBSCRIPTION_SUCCESS_ACTION = 'GetUserSubscriptionSuccessAction';
export const GET_USER_SUBSCRIPTION_ERROR_ACTION = 'GetUserSubscriptionErrorAction';
