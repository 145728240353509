/**
 *
 * Button.js
 *
 * A common button, if you pass it a prop "route" it'll render a link to a react-router route
 * otherwise it'll render a link with an onclick
 */

import React, { Children } from 'react';
import PropTypes from 'prop-types';

import BaseButton from '../BaseButton';

function Button({ children, to, href, ...rest }) {
  return <BaseButton to={to} href={href} {...rest} children={children} />;
}

Button.propTypes = {
  color: PropTypes.string,
};

export default Button;
