/*
 *
 * SignUp reducer
 *
 */
import produce from 'immer';
import {
  DEFAULT_ACTION,
  RESET_STATE,
  // plopConstImport
  SIGN_UP_VALIDATE_REFERRAL_CODE_ACTION,
  SIGN_UP_VALIDATE_REFERRAL_CODE_SUCCESS_ACTION,
  SIGN_UP_VALIDATE_REFERRAL_CODE_ERROR_ACTION,
  SIGNUP_FORM_SUBMIT_ACTION,
  SIGNUP_FORM_SUBMIT_ERROR_ACTION,
  SIGNUP_FORM_SUBMIT_SUCCESS_ACTION,
  SIGNUP_FORM_UPDATE_ACTION,
  SIGNUP_SOCIAL_UPDATE_ACTION,
  SIGNUP_SOCIAL_SUBMIT_ACTION
} from './constants';

export const initialState = {
  validateReferralCode: {},
  validateReferralCodeLoading: true,
  validateReferralCodeError: {},

  form: {},
  socialForm: {},
  error: {},
  loading: false,

  linkRedirect: '',
};

/* eslint-disable default-case, no-param-reassign */
const signUpReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      //plopCasesImport
      case SIGN_UP_VALIDATE_REFERRAL_CODE_ACTION:
        draft.validateReferralCodeLoading = true;
        draft.validateReferralCodeError = {};
        break;

      case SIGN_UP_VALIDATE_REFERRAL_CODE_SUCCESS_ACTION:
        draft.validateReferralCode = action.payload;
        draft.validateReferralCodeLoading = false;
        draft.validateReferralCodeError = {};
        break;

      case SIGN_UP_VALIDATE_REFERRAL_CODE_ERROR_ACTION:
        draft.validateReferralCodeLoading = false;
        draft.validateReferralCodeError = action.payload;
        break;

      case SIGNUP_FORM_UPDATE_ACTION:
        draft.form = action.payload;
        draft.error = {};
        draft.loading = false;
        break;
      case SIGNUP_FORM_SUBMIT_ACTION:
        draft.error = {};
        draft.loading = true;
        draft.linkRedirect = action.payload;
        break;
      case SIGNUP_FORM_SUBMIT_SUCCESS_ACTION:
        draft.error = {};
        draft.loading = false;
        break;
      case SIGNUP_FORM_SUBMIT_ERROR_ACTION:
        draft.error = action.payload;
        draft.loading = false;
        break;
      case SIGNUP_SOCIAL_UPDATE_ACTION:
        draft.socialForm = action.payload;
        draft.error = {};
        draft.loading = false;
        break;
      case SIGNUP_SOCIAL_SUBMIT_ACTION:
        draft.error = {};
        draft.loading = true;
        break;
    }
  });

export default signUpReducer;
