/**
 *
 * Nav
 *
 */

import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import {Link, NavLink} from 'react-router-dom';
import logo from '../../images/Logo_SVG.svg';
import logoWhite from '../../images/FULL Logo Congrapps-white.png';

import NavSignIn from '../../containers/NavSignIn';
// import MobileMenu from './MobileMenu';
import {Manifest} from './manifest';
import useScrollListener from '../../utils/useScrollListener';
import MobileMenu from './MobileMenu';
import SearchForm from '../../containers/SearchForm';
import {createStructuredSelector} from "reselect";
import {makeSelectUser} from "../../containers/User/selectors";
import {connect} from "react-redux";
import {compose} from "redux";
import {userGetAction} from "../../containers/User/actions";
import {getUserSubscription} from "../../containers/Stats/selectors";
import {makeSelectCountry} from "../../containers/Geo/selectors";
import {makeSelectUserToken} from "../../containers/App/selectors";
import getSymbolFromCurrency from "currency-symbol-map";
import DropDown from "./components/DropDown";
import DropDownWrapper, { ALinkStyles } from "./components/DropDownWrapper";
import cn from "classnames";

const Fixed = styled.nav`
  display: flex;
  width: 100%;
  padding: 8px 12px 8px 18px;
  background: ${(props) => (props.transparent && 'none') || 'white'};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9100;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    !props.transparent &&
    css`
      box-shadow: 0 4px 4px rgba(67, 85, 166, 0.15);
    `};

  ${(props) =>
    props.transparent &&
    css`

        &:hover {
          background: #fff;
          box-shadow: 0 4px 4px rgba(67, 85, 166, 0.15);

          .signin-profile {
            border-left: 1px solid #E5E5E5;
          }

          .header-profile ul {
            border-left: 1px solid #E5E5E5;;

            li.divider {
              opacity: 1;
            }
          }
        }
    `};

  transform: ${(props) =>
    props.isHiding ? 'translateY(-120px);' : 'translateY(0px);'};
  transition: 0.2s all linear;
  color: #505f79;

  img {
    width: auto;
    height: 30px;
    margin-bottom: 4px;
  }

  @media screen and (min-width: 2000px) {
    height: 3.33vw;
  }
`;

const TextLink = styled(NavLink)`
  // margin-left: 22px;
  // font-size: 14px;

  color:  #374266;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 64px;
  position: relative;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background-color: rgb(229, 236, 255);

    :after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      transition: 0.3s;
      left: 50%;
      transform: translateX(-50%);
      width: 42px;
      height: 5px;
      background-color: #589ce8;
    }
  }

  &:hover {
    // background-color: rgb(219, 226, 255);
    cursor: pointer;

    :after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      transition: 0.3s;
      left: 50%;
      transform: translateX(-50%);
      width: 42px;
      height: 4px;
      background-color: #589ce8;
    }
  }


  @media screen and (min-width: 2000px) {
    zoom: 1.1;
  }

  @media screen and (max-width: 1550px) {
    padding: 0 7px;
    font-size: 15px;
  }

  @media screen and (max-width: 1441px) {
    padding: 0 5px;
    font-size: 13px;
  }

  @media screen and (max-width: 1150px) {
    padding: 0 5px;
    font-size: 13px;
  }

  @media screen and (max-width: 1131px) {
    padding: 0 5px;
    font-size: 13px;
  }
`;


const ALink = styled.a`${ALinkStyles}`

const Block = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const DesktopBlock = styled(Block)`
  height: 64px;

  .header-profile {
    //margin-left: auto;
  }


  display: none;

  @media screen and (min-width: 2000px) {
    height: 3.33vw;
  }

  @media screen and (min-width: 1024px) {
    margin-left: 2px;
    display: flex;
  }
  @media screen and (max-width: 1320px) {
    justify-content: space-between;
  }
`;

const Wrapper = styled.div`
  height: 64px;
  margin-top: 0;
  @media screen and (min-width: 2000px) {
    height: 3.33vw;
  }
`;

const HamburgerMenu = styled.div`
  width: 24px;
  height: 2px;
  background-color: black;
  position: relative;

  @media all and (max-width: 767px) {
    background-color: ${(props) => (props.transparent && 'black') || 'black'};
  }

  &::before {
    content: '';
    width: 24px;
    height: 2px;
    background-color: black;
    top: -8px;
    position: absolute;
    @media all and (max-width: 767px) {
      background-color: ${(props) => (props.transparent && 'black') || 'black'};
    }
  }

  &::after {
    content: '';
    width: 24px;
    height: 2px;
    background-color: black;
    top: 8px;
    position: absolute;
    @media all and (max-width: 767px) {
      background-color: ${(props) => (props.transparent && 'black') || 'black'};
    }
  }
`;

const HamburgerWrapper = styled.div`
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 18px;

  margin-left: auto;

  @media screen and (min-width: 1024px) {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const PageLinks = styled.div`
  height: 100%;
  display: none;

  @media screen and (min-width: 1024px) {
    margin-left: 2px;
    display: flex;
  }
`;

const LogoWrapper = styled.div`

  img{
    width: 150px;
  }
  @media screen and (max-width: 1441px) {
    img{
      width: 112.5px;
    }
  }
  @media screen and (max-width: 1367px) {
    img{
      width: 106.7px;
    }
  }

  @media screen and (max-width: 1281px) {
    img{
      width: 100px;
    }
  }

  @media screen and (max-width: 1024px) {
    img{
      width: 140px;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 0 0 0 20px;
  }
  @media screen and (min-width: 2000px) {
    img{
      height: auto;
      width: 7.81vw;
    }
  }
`;



function Nav({allowTransparentNavBar = false, user, userSubscriptions, country, ...rest}) {
  const [state, setState] = React.useState({isMenuOpen: false});
  const [transparent, setTransparent] = React.useState(allowTransparentNavBar);
  const [dropDownIsOpen, setDropDownIsOpen] = React.useState(false);
  const [isAuth, setIsAuth] = React.useState(false);
  const [userData, setUserData] = React.useState('');

  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [hasSubscription, setHasSubscription] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState('£');

  useEffect(() => {
    if (!!country['code']) {
      setCurrencySymbol(getSymbolFromCurrency(country['code']))
    }
  }, [country])

  if (allowTransparentNavBar) {
    useScrollListener((payload) => {
      if (payload.scrollTop < 60) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    }, 25);
  }

  const handleMenu = () => {
    setState({
      isMenuOpen: !state.isMenuOpen,
    });
    if (state.isMenuOpen === true) {
      setTransparent(false);// true was before
    } else {
      setTransparent(false);
    }
  };

  const handleMenuClick = () => {
    setState({
      isMenuOpen: false,
    });
    window.scrollTo(0, 0);
  };

  const todayDate = new Date().toISOString();

  useEffect(() => {
    if (userSubscriptions.length > 0) {
      const result = [];
      userSubscriptions.forEach((userSubscription) => {
        if (userSubscription.is_active === true) {
          if (userSubscription.subscription_product.type === 'Corporate Astronaut') {
            result.push('Astronaut');
          } else {
            result.push((userSubscription.cancel_at || todayDate) >= todayDate && userSubscription.subscription_product.type);
          }
        }
      });

      setActiveSubscriptions(result);
      setHasSubscription(result.length > 0);
    }

  }, [userSubscriptions]);

  useEffect(() => {
    if (!!user.id) {
      setIsAuth(true);

      const userDataObj = {
        karma: `${currencySymbol}${user.karma_amount / 100} credit`,
        subscription: hasSubscription ? activeSubscriptions[activeSubscriptions.length - 1] : 'Start now!',
        active: hasSubscription ? 'sub-active' : 'not-sub-active'
      }

      try {
        setUserData(btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(userDataObj)))))
      } catch (e) {
        setUserData('')
      }

    } else {
      setIsAuth(false)
      setUserData('')
    }
  }, [user, hasSubscription]);

  const disableTransparent = (isActive) => {
    setDropDownIsOpen(isActive);
  }

  return (
    <Wrapper>
      <Fixed transparent={transparent && !dropDownIsOpen} className={cn({'transparent': transparent, 'dropdownIsOpen': dropDownIsOpen})}>
        <Block>
          <LogoWrapper>
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <picture>
                <source
                  media="(min-width: 650px)"
                  srcSet={logo}/>
                <source
                  media="(min-width: 320px)"
                  srcSet={logo}/>
                <img src={logo} alt="Congrapps Logo"/>
              </picture>
            </Link>
          </LogoWrapper>
          <DesktopBlock>
            <PageLinks>
              {Manifest.map((link) => {
                if(!!link.hasDropdown) {
                  return (
                    <DropDownWrapper key={link.name} transparentHandler={disableTransparent}>
                        {link.name}
                        <DropDown />
                    </DropDownWrapper>
                  )
                }

                if (link.path) {
                  return (
                    <TextLink
                      key={link.name}
                      to={link.path}
                      onClick={handleMenuClick}
                    >
                      {link.name}
                    </TextLink>
                  );
                }
                return (
                  <ALink key={link.name} href={`${link.url}?user=${userData}`}>
                    {link.name}
                  </ALink>
                );
              })}
            </PageLinks>
            <SearchForm/>
            <NavSignIn {...rest} />
          </DesktopBlock>
          <HamburgerWrapper onClick={handleMenu}>
            <HamburgerMenu transparent={transparent}/>
          </HamburgerWrapper>
        </Block>
      </Fixed>
      <MobileMenu isActive={state.isMenuOpen} handleClick={handleMenuClick} isAuth={isAuth} userData={userData}/>
    </Wrapper>
  );
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  userSubscriptions: getUserSubscription(),
  country: makeSelectCountry()
});

Nav.propTypes = {};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Nav);
