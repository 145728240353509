/*
 *
 * User constants
 *
 */

export const USER_UPDATE_ACTION = 'app/User/UpdateAction';
export const RESET_STATE = 'app/User/ResetState';
export const USER_UPDATE_SUCCESS_ACTION = 'app/User/UpdateSuccessAction';
export const USER_UPDATE_ERROR_ACTION = 'app/User/UpdateErrorAction';

export const USER_GET_ACTION = 'app/User/GetAction';
export const USER_GET_SUCCESS_ACTION = 'app/User/GetSuccessAction';
export const USER_GET_ERROR_ACTION = 'app/User/GetErrorAction';

export const USER_GET_WALLET_LIST_ACTION = 'app/User/GetWalletListAction';
export const USER_GET_WALLET_LIST_SUCCESS_ACTION =
  'app/User/GetWalletListSuccessAction';
export const USER_GET_WALLET_LIST_ERROR_ACTION =
  'app/User/GetWalletListErrorAction';
export const USER_SET_TYPE_PRODUCT = 'app/User/SetTypeProduct';

export const HOME_PAGE_GET_USER_SUBSCRIPTION = 'app/User/GetUserSubscription';
export const HOME_PAGE_GET_USER_SUBSCRIPTION_SUCCESS = 'app/User/GetUserSubscriptionSuccess';
export const HOME_PAGE_GET_USER_SUBSCRIPTION_ERROR = 'app/User/GetUserSubscriptionError';

export const USER_DELETE_WALLET_ITEM_ACTION = 'app/User/DeleteWalletItemAction';
export const USER_DELETE_WALLET_ITEM_SUCCESS_ACTION =
  'app/User/DeleteWalletItemSuccessAction';
export const USER_DELETE_WALLET_ITEM_ERROR_ACTION =
  'app/User/DeleteWalletItemErrorAction';

export const FINAL_FORM_UPDATE_ACTION = 'app/Final/FormUpdateAction'
export const FINAL_FORM_SUBMIT_ACTION = 'app/Final/FormSubmitAction'
export const FINAL_FORM_SUBMIT_SUCCESS_ACTION = 'app/Final/FormSubmitSuccessAction'
export const FINAL_FORM_SUBMIT_ERROR_ACTION = 'app/Final/FormSubmitErrorAction'

export const USER_GET_BANK_LIST_ACTION = 'app/User/GetBankListAction';
export const USER_GET_BANK_LIST_SUCCESS_ACTION =
  'app/User/GetBankListSuccessAction';
export const USER_GET_BANK_LIST_ERROR_ACTION =
  'app/User/GetBankListErrorAction';
export const USER_SET_BANK_LIST_STATUS_ACTION = 'app/User/SetBankListStatusAction'
export const USER_SET_BANK_LIST_ERROR_ACTION =
  'app/User/SetBankListErrorAction';

export const USER_GET_CASH_OUT_LIST_ACTION = 'app/User/GetCashOutListAction';
export const USER_GET_CASH_OUT_LIST_SUCCESS_ACTION =
  'app/User/GetCashOutListSuccessAction';
export const USER_GET_CASH_OUT_LIST_ERROR_ACTION =
  'app/User/GetCashOutListErrorAction';
export const CLEAR_USER_SUBSCRIPTION = 'app/User/ClearUserSubscription'
