import styled, {css} from 'styled-components';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Button from '../Button';
import {isEmptyArray, isEmptyString} from '../../utils/utils';
import PasswordErrors from "./PaswordErrors";
import {TextareaAutosize} from "@material-ui/core"


const Counter = styled.div`
  color: #505e78;
  position: absolute;
  bottom: 32px;
  right: 20px;
`;
const style = css`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 5px;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transition: border 0.3s, -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s;

  background-color: #e5ecff;
  font-weight: 700;
  border: 2px solid #e5ecff;
  ${(props) =>
  props.opened &&
  css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
  color: #53596f;
  font-size: 1rem;
  height: 50px;
  // margin-top: -4px;
  line-height: 50px;
  padding-left: 25px;
  width: calc(100% - 25px);
  min-height: fit-content;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      width: '100%',
      fontSize: '16px',
      //color: '#9e9e9e',
      //color: 'hsl(0,0%,20%) !important',
      // color: '#505f79 !important',
      color: '#505f79 !important',
      border: 'none',
      borderRadius: '7px',
      fontWeight: 700,
      // backgroundColor: '#e5ecff !important',
      backgroundColor: '#e5ecff !important',
      margin: '0 0 8px 0',
      // paddingLeft: '20px !important',
      '&:hover': {
        border: '0px !important',

      }
    },
    '& .MuiInputBase-input': {
      // color: 'hsl(0,0%,20%) !important',
      color: '#333333 !important',
      height: '1.35rem',
      borderRadius: '8px',
      fontWeight: '700',
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif !important'
    },
    '& .MuiInputBase-inputMultiline': {
      // color: 'hsl(0,0%,20%) !important',
      color: '#333333 !important',
      height: 'auto',
      borderRadius: '8px',
      fontWeight: '700',
      fontSize: '16px',
      fontFamily: 'Inter, sans-serif !important'
    },
    '& .MuiFormControl-root': {
      '&:focus': {
        border: '1px solid red !important',
        borderRadius: '8px',
        backgroundColor: '#e5ecff !important',
      },
      zIndex: 0,
      "& .MuiInputLabel-outlined": {
        paddingLeft: '5px',
        // color: 'hsl(0,0%,20%)',
        fontWeight: 700,
        fontSize: "1rem",
        fontFamily: 'Arial'
      },
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        //transform: 'translate(25px, -6px) scale(0.75)',
        backgroundColor: '#e5ecff !important',
        padding: '5px 7px',
        borderRadius: '8px',
      }
    },
    '& .MuiFormControl-root:hover': {
      border: '0px !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid #e5ecff',
    },
    /*      '& .MuiOutlinedInput-notchedOutline: hover': {
           border: 'hsl(0,0%,70%) !important',
         }, */
  },
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'hsl(0,0%,20%)',
    },
    '& .MuiInput-underline:after': {
      borderColor: '#2684FF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'hsl(0,0%,70%) !important',
        transition: 'box-shadow 0.3s, border 0.3s, -webkit-box-shadow 0.3s',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#B3B3B3 !important',
        // borderColor: '#2684FF',
        // border: 'none !important'
      },
    },
  },
})(TextField);

const StyledLabel = styled.label`
  color: ${(props) => props.color || '#505f79'} !important;
  font-weight: 700;
  font-size: 1.3rem;

  color: #9e9e9e;
  position: absolute;
  top: 8px;
  left: 0;
  font-size: 1rem;
  cursor: text;
  -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  text-align: initial;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);

  left: 14px;
  display: inline-block;
  width: auto !important;
  border-radius: 10px;
  padding: 1px 10px 1px 0;
  -webkit-transform: translateY(9px);
  -ms-transform: translateY(9px);
  transform: translateY(9px);
  color: #9e9e9e;

  ${(props) =>
  props.active
    ? '-webkit-transform: translateY(-14px) scale(0.8);' +
    'transform: translateY(-14px) scale(0.8);' +
    '-webkit-transform-origin: 0 0;' +
    'transform-origin: 0 0;' +
    'background-color: #e5ecff;' +
    '-webkit-transform: translateY(-14px) scale(0.8);' +
    '-ms-transform: translateY(-14px) scale(0.8);' +
    'transform: translateY(-14px) scale(0.8);'
    : ''};
`;

const InputFieldWrapper = styled.div`
  position: relative;
  margin-bottom: 7px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiInputBase-root {
    margin-bottom: 0;
  }
  .MuiFormLabel-root{
  color: #505f79;
  }

  .select-menu__menu{
    padding-bottom: 50px;
    background: none;
    box-shadow: none;

    .select-menu__menu-list{
      background: #FFF;
      box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
      border-radius: 4px;
    }
  }
`;

const ErrorSpan = styled.span`
  color: #f44336;
  margin-left: 15px;
  ${(props) => (props.active !== undefined && props.active ? 'display: block;' : 'display: none;')}
`;

const OptionalSpan = styled.span`
  opacity: 0.6;
`;

const UploadButton = styled(Button)`
  border-radius: 8px;
  padding: 0 30px;
  text-transform: capitalize;
  background-color: #589CE8!important;
  width: 100%;
  box-shadow: none;
  height: 55px;
  margin-top: 8px;
  color: #FFF;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const HelperText = styled.span`
  position: relative;
  min-height: 18px;
  display: block;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);

  color: #8c97af;
  text-align: left;
  font-size: 0.9rem;
  margin-left: 25px;
  font-style: italic;

  &::after {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 600px) {
    margin: 0;
    text-align: center;
  }

  ${(props) => (props.active ? 'display: initial;' : 'display: none;')}
`;

const StyledDiv = styled.div`
  ${style}

  padding: 0 25px;
  margin: 0 0 0 6px;
  display: flex;
  flex-grow: 1;
  width: auto;
  overflow: hidden;

  margin-top: 8px;

  @media screen and (max-width: 670px) {
    height: fit-content;
    line-height: unset;
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const PreviewImageWrapper = styled.div`
  width: 200px;
  height: 200px;
  background-color: #e5ecff;
  border-radius: 50%;
  margin: 20px auto;
  border: 1px solid #c2cce8;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: 120px;
    height: 120px;
    margin: 10px auto;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => (props.type === 'image' && '50%;') || '100%;'};
  margin: 0 0 6px 0;
  justify-content: space-around;
  flex-direction: ${(props) => (props.type === 'image' && 'column') || 'row'};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Filename = styled.div`
color: #374266;
font-feature-settings: 'liga' off;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 20px;
text-transform: uppercase;
margin-top: 8px;
width: 100%;
`

const checkValidation = (validation, value) => {
  const pattern = new RegExp(validation);
  return pattern.test(value);
};

const clearStringFromLetters = (string) => {
  let clearedString = '';
  for(const char of string) {
    if (!char.match(/[a-zA-Z]/)) {
      clearedString +=char;
    }
  }

  return clearedString;
}

function InputField(props) {
  const {
    type,
    name,
    label,
    errors,
    items = [],
    autoComplete = 'on',
    onChange,
    validation = '^(?!\\s*$).+',
    bottomComponent,
    showFileLocation = true,
    showFileLocationBtn = false,
    inputValid = false,
    errorHidden = false,
    ...rest
  } = props;

  const classes = useStyles();

  const id = props.id || name;
  const realVal = name.split('.').reduce((a, b) => a[b], props.form);
  const value = value || realVal || '';
  const [isFocused, setIsFocused] = useState(false);

  const [isValid, setIsValid] = useState(rest.valid || null);
  const [isTouched, setIsTouched] = useState(false);
  const active = value !== '' || isFocused;
  const error = errors[name] || '';
  // const hasError = error !== '' && isTouched === name;
  const [hasError, setHasError] = useState(error !== '' && isTouched === name);
  const hasHelperText = !isEmptyString(props.helperText);

  const [passwordErrors, setPasswordErrors] = useState({
    short: false,
    case: false,
    numberSymbol: false,
    confirmation: false,
    valuePass: '',
    valueConfirmPass: ''
  })

  useEffect(() => {

    if ( props.form && name === 'password' && props.form.password ) {

      setPasswordErrors({
        length: props.form.password.length >= 8,
        case: /[a-z]/.test(props.form.password) && /[A-Z]/.test(props.form.password),
        numberSymbol: /^[0-9_\-+\.!?~`!@#$%^&*()_{}<>]+$/.test(clearStringFromLetters(props.form.password)),
        confirmation: false,
        valuePass: props.form.password,
        valueConfirmPass: ''
      })
    } else if ( props.form && name === 'password_confirm' && props.form.password_confirm) {
      setPasswordErrors({
        length: false,
        case: false,
        numberSymbol: false,
        confirmation: props.form.password === props.form.password_confirm,
        valuePass: '',
        valueConfirmPass: props.form.password_confirm
      })
    } else {
      setPasswordErrors({
        length: false,
        case: false,
        numberSymbol: false,
        confirmation: false,
        valuePass: '',
        valueConfirmPass: ''
      })
    }
  }, [props.form])

  useEffect(() => {
    setIsValid(props.valid);
  }, [rest.valid]);

  useEffect(() => {
    setIsTouched(false);
    let error = errors[name] || '';
    setHasError(error !== '');
  }, [errors]);

  useEffect(() => {
    if (isTouched === name) {
      setHasError(false);
    }
  }, [isTouched]);

  // console.log("State: ", state);
  // console.log("Value: ", value);
  // console.log("Validation status: ", checkValidation(validation, value));
  // console.log(
  //   `${name} Valid: `,
  //   isValid,
  //   props.valid,
  //   props.valid || null,
  //   props.valid || null,
  // );

  const eventHandlers = useMemo(
    () => ({
      onFocus: () => {
        setIsFocused(true);
        setIsValid(null);
      },
      onBlur: (e) => {
        console.log('onBlur:', name, e.target.value)
        setIsTouched(name);
        if (rest.handleOnBlur) {
          rest.handleOnBlur();
        }
        // console.log(`${name} cValue: `, value);
        // console.log(`${name} validation: `, validation);
        // console.log(
        //   `${name} cValidation status: `,
        //   validation !== false && checkValidation(validation, value),
        // );
        if (validation !== false) {
          setIsValid(checkValidation(validation, value));
        } else if (rest.valid === undefined) {
          setIsValid(null);
        } else {
          setIsValid(rest.valid);
        }
        setIsFocused(false);
      },
    }),
    [value, validation],
  );

  switch (type) {
    case 'image':
    case 'file':
      const fileExt = props.fileExt || ['PDF', 'DOC', 'JPEG', 'PNG'];
      const [filenameValue, setFilenameValue] = useState(
        `Acceptable file formats: ${fileExt.join(', ')}`,
      );
      const [filename, setFilename] = useState('')

      const [imageUrl, setImageUrl] = useState(value);

      const fileInput = useRef(null);

      const handleClick = () => {
        fileInput.current.click();
      };

      const handleFileChange = (event) => {
        const fileName = event.target.files[0].name;
        const fileValue = event.target.files[0];
        setFilenameValue(fileName);
        setFilename(fileName)
        setImageUrl(URL.createObjectURL(fileValue));
        onChange({target: {name, value: fileValue}});
      };

      return (
        <div
          style={{
            ...props.style,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            // justify-content: center;
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <ImageContainer type={type}>
            {type === 'image' && (
              <PreviewImageWrapper>
                <img src={imageUrl}/>
              </PreviewImageWrapper>
            )}
            {/*             <TextField
              id="standard-basic"
              hidden
              type="file"
              onChange={handleFileChange}
              ref={fileInput}
              name={name}
              label="Standard" /> */}
            <input
              id={id}
              hidden
              type="file"
              onChange={handleFileChange}
              ref={fileInput}
              name={name}
            />
            <UploadButton onClick={handleClick}>{label}</UploadButton>
            {showFileLocationBtn
              ? filename && <Filename>{filenameValue}</Filename>
              : showFileLocation && <StyledDiv>{filenameValue}</StyledDiv>
            }

          </ImageContainer>
          <HelperText
            style={{
              margin: '0 0 6px 0',
            }}
            active={hasHelperText}
          >
            {props.helperText}
          </HelperText>
          <ErrorSpan active={hasError}>{error}</ErrorSpan>
        </div>
      );
    case 'select':
      const labelAttribute = props.labelAttribute || 'label';
      const valueAttribute = props.valueAttribute || 'value';
      const isClearable = props.isClearable || true;
      const isMulti = props.isMulti || false;
      const isSearchable = props.isSearchable || true;
      const isCreatable = props.isCreatable || false;
      const isLoading = props.isLoading || false;
      const isDisabled = props.isDisabled || false;

      const SelectTag = isCreatable ? CreatableSelect : Select;
      // convert to react-select OptionsType {label: "", value: ""}
      const options = items.map((item) => ({
        value: item[valueAttribute],
        label: item[labelAttribute],
      }));
      let searchValue = [];
      if (isMulti && value) {
        if (Array.isArray(value)) {
          searchValue = value;
        } else {
          searchValue = [value];
        }
      }
      let defaultValue =
        options.filter((item) =>
          isMulti ? searchValue.includes(item.value) : item.value === value,
        ) || '';

      if (isEmptyArray(defaultValue)) {
        defaultValue = '';
      }

      const customStyles = {
        option: (provided, state) => ({
          ...provided,
          // borderBottom: '1px dotted pink',
          // color: state.isSelected ? 'red' : 'blue',
          padding: 20,
        }),
        container: (provided, state) => ({
          ...provided,
          width: '100%',
          display: 'flex',
        }),
        control: (provided, state) => ({
          // none of react-select's styles are passed to <Control />
          ...provided,
          style,
          lineHeight: 'unset',
          height: 'fit-content',
          minHeight: '50px !important',
          // marginTop: "-12px",
        }),
        valueContainer: (provided, state) => ({
          marginLeft: "-12px"
        }),
      };

      const selectRef = useRef(null);
      // console.log('selectRef', selectRef);
      // console.log('isLoading', isLoading);
      return (
        <InputFieldWrapper>
          <SelectTag
            id={id}
            classNamePrefix={'select-menu'}
            ref={selectRef}
            styles={customStyles}
            options={options}
            isClearable={isClearable}
            isSearchable={isSearchable}
            isMulti={isMulti}
            isValid={isValid}
            isLoading={isLoading}
            isDisabled={isDisabled}
            {...(!isCreatable && {value: defaultValue})}
            {...(isCreatable && {onCreateOption: props.onCreateOption})}
            onChange={(obj, action) => {
              console.log('select onChange', obj, action);
              if (obj === null || obj.length === 0) {
                onChange({target: {name, value: null}});
              } else {
                const evt = {
                  target: {
                    name,
                    value: isMulti ? obj.map((p) => p.value) : obj.value,
                  },
                };
                onChange(evt);
              }
            }}
            placeholder=""
            {...eventHandlers}
          />
          <StyledLabel
            color={isDisabled ? '#b9b9b991' : null}
            active={active}
            htmlFor={id}
            onClick={() => {
              selectRef &&
              selectRef.current &&
              selectRef.current.select.focus();
            }}
          >
            {label} {props.optional && <OptionalSpan>(Optional)</OptionalSpan>}
          </StyledLabel>
          <HelperText active={hasHelperText} className="helper-text">
            {props.helperText}
          </HelperText>
          {bottomComponent && bottomComponent}
          { !errorHidden && <ErrorSpan active={hasError}>{error}</ErrorSpan> }
        </InputFieldWrapper>
      );

    case 'text':
    case 'number':
    case 'password':
    case 'email':
    case 'textarea':
      return (
        <InputFieldWrapper
          className={classes.root}
          style={{
            ...props.style,
          }}
        >
          <CssTextField
            id={id}
            value={value}
            isValid={isValid}
            maxLength={props.maxLength}
            style={props.inputTagStyle}
            multiline={type === 'textarea'}
            rows={props.rows}
            variant="outlined"
            classes={{hover: classes.hover}}
            className="validate"
            type={type}
            inputProps={{style: {color: 'white'}}}
            onChange={onChange}
            name={name}
            {...(validation !== false && {pattern: validation})}
            autoComplete={autoComplete}
            {...eventHandlers}
            label={label}/>
          {/*           <InputTag
            id={id}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            isValid={isValid}
            maxLength={props.maxLength}
            style={props.inputTagStyle}
            rows={props.rows}
            className="validate"
            {...(validation !== false && { pattern: validation })}
            autoComplete={autoComplete}
            {...eventHandlers}
          /> */}
          {props.showCounter && <Counter>{value.length}</Counter>}
          {/*          <StyledLabel active={active} htmlFor={id}>
            {label} {props.optional && <OptionalSpan>(Optional)</OptionalSpan>}
          </StyledLabel> */}

          <HelperText className="helper-text" active={hasHelperText} dangerouslySetInnerHTML={{__html: props.helperText}}/>
          {bottomComponent && bottomComponent}
          { !errorHidden && <ErrorSpan active={hasError}>{error}</ErrorSpan> }
          {(name === 'password' || name === 'password_confirm') && inputValid ? <PasswordErrors errors={passwordErrors}/> : ''}
        </InputFieldWrapper>
      );

    case 'checkbox':
      const defaultChecked = props.defaultChecked || false;
      const onCheckboxChange = () => {
        const evt = {
          target: {
            name,
            value: !value,
          },
        };

        if (evt.target.value) setIsTouched(name);
        onChange(evt);
      };

      return (
        <>
          <input
            value={value}
            onChange={onCheckboxChange}
            checked={value}
            name={name}
            type="checkbox"
            defaultChecked={defaultChecked}
          />
          <label>
            <span
              style={{
                marginLeft: '10px',
              }}
            >
              {label}
            </span>
          </label>
          <ErrorSpan active={hasError}>{error}</ErrorSpan>
        </>
      );
  }
}

InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  validation: PropTypes.string,
  onChange: PropTypes.func,
};
// props.form && props.form[props.name]

export default InputField;
