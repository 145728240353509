/**
 *
 * ClearFix
 *
 */

import React from 'react';
import styled from 'styled-components';

const ClearFix = styled.div`
  clear: both;
`;

ClearFix.propTypes = {};

export default ClearFix;
