import React from "react";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

const ContainerCss = css`
  width: 100%;
  font-family: Inter, sans-serif;
  display: block;
  padding: 9px 20px;
  border-radius: 10px;
  background: rgba(96, 159, 229, 0);
  transition: .2s background linear;
`;

const ContainerLink = styled(NavLink)`
  ${ContainerCss};

  &:hover {
    background: rgba(96, 159, 229, 0.23);
    cursor: pointer;

    p {
      cursor: pointer;
    }
  }
`;

const Container = styled.div`
  ${ContainerCss};
`;

const Title = styled.h4`
  margin: 0 0 5px 0;
  padding: 0;
  color: #374266;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 1441px) {
    font-size: 15px;
  }

  ${(props) =>
    !!props.styleType && props.styleType === "light" &&
    css`
      color: #fff;
    `}
`;

const Badge = styled.div`
  height: 18px;
  width: auto;
  display: table;
  text-align: center;
  padding: 0 5px;
  border-radius: 6px;
  color: #FFA531;
  border: 0.1rem solid #FFA531;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.2px;
  text-transform: capitalize;
  margin-top: 5px;

  ${(props) =>
    !!props.type && props.type === "free" &&
    css`
      border-color: #589CE8;
      color: #589CE8;
    `}

  ${(props) =>
    !!props.type && props.type === "earn" &&
    css`
      border-color: #46BA6D;
      color: #46BA6D;
    `}

  ${(props) =>
    !!props.type && props.type === "coming soon" &&
    css`
      border-color: #a5a5a5;
      color: #a5a5a5;
    `}
`;

const Description = styled.p`
  color: #374266;
  padding: 0;
  margin: 0;
  font-feature-settings: 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */

  @media screen and (max-width: 1441px) {
    font-size: 12px;
  }

  ${(props) =>
    !!props.styleType && props.styleType === "light" &&
    css`
      color: #DBDBDB;
    `}
`;


function DropDownItem({ content, styleType = "dark", ...props }) {
  return (
    <>{!!content.to
      ? <ContainerLink to={content.to}>
        <Title styleType={styleType}>{content.title}</Title>
        <Description styleType={styleType}>{content.description}</Description>
        <Badge type={content.badge}>{content.badge}</Badge>
      </ContainerLink>
      : <Container>
        <Title styleType={styleType}>{content.title}</Title>
        <Badge type={content.badge}>{content.badge}</Badge>
        <Description styleType={styleType}>{content.description}</Description>
      </Container>}</>
  );
}

DropDownItem.propTypes = {
  content: require
};

export default DropDownItem;
