import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the user state domain
 */

const selectGeoDomain = (state) => state.geo || initialState;

/**
 * Other specific selectors
 */

// plopImport

/**
 * Default selector used by Geo
 */
const makeSelectCountry = () =>
  createSelector(selectGeoDomain, (substate) => substate.country);

export default selectGeoDomain;

export {
  makeSelectCountry
};

