import React from 'react';
import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  max-width: 138px;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;

  padding: 10px;
  border: 3px solid #4a7eff;
  border-radius: 43px;

  transition: .2s all linear;
  background: ${props => props.primary ? "#4a7eff" : "transparent"};
  color: ${props => props.primary ? "#ffffff" : "#4a7eff"};

  &:hover {
   background: #4a7eff;
   color: #ffffff;
  }

  @media (max-width: 400px) {
    max-width: 100%;
  }
`;

function CookieButton({
  primary = false,
  action = null,
  children
}) {

  return (
    <Button type="button" primary={primary} onClick={action}>
      { children }
    </Button>
  );
}

CookieButton.propTypes = {};

export default CookieButton;
