/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'utils/history';
import globalReducer from 'containers/App/reducer';
import userReducer from 'containers/User/reducer';
import geoReducer from 'containers/Geo/reducer'
import languageProviderReducer from 'containers/LanguageProvider/reducer';
import { reducer } from '@paztis/redux-storage';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = reducer(
    combineReducers({
      global: globalReducer,
      user: userReducer,
      geo: geoReducer,
      language: languageProviderReducer,
      router: connectRouter(history),
      ...injectedReducers,
    }),
  );

  return rootReducer;
}
