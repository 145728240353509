/**
 *
 * PageChrome
 *
 */

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import Nav from '../Nav';
import Footer from '../Footer';
import { useLocation } from 'react-router-dom'

import bottomBackgroundPng from '../../images/bottom-background.png';
import topBackgroundPng from '../../images/top-background.png';
import { getUserSubscriptionAction } from '../../containers/Stats/actions';
import reducer from '../../containers/Stats/reducer';
import saga from '../../containers/Stats/saga';
import { useInjectReducer } from '../../utils/injectReducer';
import { useInjectSaga } from '../../utils/injectSaga';
import DiscountFooter from "../DiscountFooter";

const Background = styled.div`
  position: relative;
  main {
    min-height: ${(props) => props.minHeight}px;
    padding-top: 1px;
    flex: 1 0 auto;
  }

  &::before {
    bottom: 40px;
    right: 0;
    content: '';
    background: url('${bottomBackgroundPng}') center center/contain no-repeat;
    width: 200px;
    height: 400px;
    width: 370px;
    height: 570px;
    position: absolute;
    display: inline-block;
    z-index: 10;
    @media screen and (max-width: 1280px) {
      content: none;
    }
  }
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    height: 850px;
    width: 180px;
    left: 0;
    top: ${(props) => props.bgTop || '368px'};
    background: url('${topBackgroundPng}') left top/contain no-repeat;
    @media screen and (max-width: 1280px) {
      content: none;
    }
  }
`;

const WrapperPage = styled.div`
  min-height: ${(props) => props.isFixedMinWidth ? '960px' : '0'};
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

function PageChrome({ children, showBackground = true, showFooter = true, isFixedMinWidth = true, bgTop, ...rest}) {
  useInjectReducer({ key: 'stats', reducer });
  useInjectSaga({ key: 'stats', saga });

  const [minHeight, setMinHeight] = useState(0)

  const isShowBackground = showBackground ? 1 : 0

  const location = useLocation();
  let className = '';

  if(location && !!location.pathname && location.pathname.indexOf('my-account') !== -1) {
    className = "myAccountPageFooterContainer";
  }

  useEffect(() => {
    console.log("isFixedMinWidth", isFixedMinWidth)
    const pageChrome = document.getElementById('page-chrome')
    setMinHeight(pageChrome.offsetHeight)
  }, [])

  return (
    <>
      <Nav {...rest} />
      <WrapperPage isFixedMinWidth={isFixedMinWidth} id={'page-chrome'}>
        {isShowBackground === 1 && <Background bgTop={bgTop} isFixedMinWidth={isFixedMinWidth} minHeight={minHeight}>{children}</Background>}
        {!isShowBackground && children}
      </WrapperPage>
      <div className={className}>
        <DiscountFooter coupon="LOVEAPPS" />
        {showFooter && <Footer />}
      </div>

    </>
  );
}

PageChrome.propTypes = {};

export default PageChrome;
