import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the signUp state domain
 */

const selectSignUpDomain = (state) => state.signUp || initialState;

/**
 * Other specific selectors
 */

//plopImport

const makeSelectSignUpValidateReferralCode = () =>
  createSelector(
    selectSignUpDomain,
    (substate) => substate.validateReferralCode,
  );

const makeSelectSignUpValidateReferralCodeLoading = () =>
  createSelector(
    selectSignUpDomain,
    (substate) => substate.validateReferralCodeLoading,
  );

const makeSelectSignUpValidateReferralCodeError = () =>
  createSelector(
    selectSignUpDomain,
    (substate) => substate.validateReferralCodeError,
  );

/**
 * Default selector used by SignUp
 */

const makeSelectSignUpForm = () =>
  createSelector(selectSignUpDomain, (substate) => substate.form);

const makeSelectSignUpSocial = () =>
  createSelector(selectSignUpDomain, (substate) => substate.socialForm);

const makeSelectSignUpLoading = () =>
  createSelector(selectSignUpDomain, (substate) => substate.loading);

const makeSelectSignUpError = () =>
  createSelector(selectSignUpDomain, (substate) => substate.error);

const makeSelectSignUpFormLinkRedirect = () =>
  createSelector(selectSignUpDomain, (substate) => substate.linkRedirect);

export {
  makeSelectSignUpValidateReferralCode,
  makeSelectSignUpValidateReferralCodeLoading,
  makeSelectSignUpValidateReferralCodeError,
  selectSignUpDomain,
  makeSelectSignUpForm,
  makeSelectSignUpLoading,
  makeSelectSignUpError,
  makeSelectSignUpFormLinkRedirect,
  makeSelectSignUpSocial
};
