/**
 *
 *  Discount Footer
 *
 */
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import close from '../../images/close.svg';
import {connect, useDispatch} from "react-redux";
import {hideDiscountFooter} from "../../containers/App/actions";
import {createStructuredSelector} from "reselect";
import {makeSelectIsDiscountFooter} from "../../containers/App/selectors";
import {compose} from "redux";
import {getUserSubscription} from "../../containers/Stats/selectors";
import {getUserSubscriptionAction} from "../../containers/Stats/actions";
import {makeSelectUser} from "../../containers/User/selectors";

const DiscountFooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  padding: 20px;
  background: #505E78;
  color: #ffffff;
  line-height: 1;
  font-size: 18px;
  font-weight: 500;
  text-align: center;

  @media screen and (min-width: 2000px) {
    padding: 1.04vw;
  }

  @media screen and (max-width: 1425px) {
    padding-right: 120px;
  }

  @media screen and (max-width: 992px) {
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    strong {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 520px) {
    padding-right: 20px;
  }

`;

const DiscountFooterInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 2000px) {
    .TextGridArea{
      font-size: 0.937vw;
    }
  }

  @media screen and (max-width: 520px) {
    .TextGridArea { grid-area: TextGridArea; }
    .CouponGridArea { grid-area: CouponGridArea; }
    .CloseGridArea { grid-area: CloseGridArea; }

    .CloseGridArea{
      align-self: flex-start;
    }

    display: grid;
    grid-template-columns: 1fr 1fr 72px;
    grid-template-rows: 1fr 35px;
    gap: 10px 0px;
    grid-template-areas:
      "TextGridArea TextGridArea CloseGridArea"
      "CouponGridArea CouponGridArea CloseGridArea";
  }
`;

const DiscountLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const DiscountLink = styled(Link)`
  background: #FFFFFF;
  border-radius: 8px;
  padding: 8px 12px;
  color: #7C3AED;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-left: 12px;
  transition: .2s all;
  white-space: nowrap;

  @media screen and (min-width: 2000px) {
    font-size: 0.83vw;
    padding: 0.41vw 0.625vw;
  }

  @media screen and (max-width: 992px) {
    font-size: 12px;
    line-height: 16px;
  }

  &:hover {
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 30%);
  }
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
  margin: 0 0 0 38px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (min-width: 2000px) {
    width: 1.3vw;
    height: 1.3vw;
    img{
      width: 60%;
    }
  }

  @media screen and (max-width: 992px) {
    margin-left: 19px;
  }

  img {
    display: block;
    pointer-events: none;
  }

`;


function DiscountFooter({coupon, isDiscountFooter, userSubscription, user}) {

  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(hideDiscountFooter(false));
  }

  const [showDiscount, setShowDiscount] = useState(false);

  useEffect(() => {
    if(!user.id) {
      setShowDiscount(isDiscountFooter)
    } else {

      if(userSubscription.length > 0) {
        setShowDiscount(false)
      } else {
        setShowDiscount(isDiscountFooter)
      }

    }

  }, [isDiscountFooter, userSubscription, user]);


  if (showDiscount) {
    return (
      <DiscountFooterContainer className="discountFooterContainer fade-in-bottom">
        <DiscountFooterInner>
          <span className="TextGridArea">
            <strong>NEW HERE?</strong> Get a <strong>30% discount</strong> to all subscriptions and access what you need
          to land your dream job. Use this code on checkout:
          </span>
          <DiscountLinkWrapper className="CouponGridArea">
            <DiscountLink to="/pricing">
              {coupon}
            </DiscountLink>
          </DiscountLinkWrapper>

          <div className="CloseGridArea">
            <CloseButton type="button" onClick={onClickHandler}>
              <img src={close} alt="Close"/>
            </CloseButton>
          </div>
        </DiscountFooterInner>
      </DiscountFooterContainer>
    )
  }

  return "";

}

const mapStateToProps = createStructuredSelector({
  isDiscountFooter: makeSelectIsDiscountFooter(),
  userSubscription: getUserSubscription(),
  user: makeSelectUser(),
});

DiscountFooter.propTypes = {};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(DiscountFooter)
