import React from 'react';
import styled from 'styled-components';

export const ClosePopupButton = styled.button`
  color: #374266;
  position: absolute;
  right: 14px;
  top: 13px;
  background: #FFFFFF;
  box-shadow: 0px 4px 22px rgba(48, 89, 135, 0.25);
  border-radius: 15px;
  width: 25px;
  height: 25px;
  padding: 0;
  border: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function ClosePopupBtn({action}) {
  return (
    <ClosePopupButton type="button" onClick={action}>
      <svg className="pointer-events-none" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7335_6680)">
          <path
            d="M9.15207 7.49951L14.7604 1.89098C14.9146 1.73659 14.9998 1.53061 15 1.31098C15 1.09122 14.9149 0.885 14.7604 0.730854L14.2689 0.239512C14.1144 0.0847561 13.9084 0 13.6885 0C13.469 0 13.263 0.0847561 13.1085 0.239512L7.50024 5.84768L1.89171 0.239512C1.73744 0.0847561 1.53134 0 1.31159 0C1.09207 0 0.885976 0.0847561 0.731707 0.239512L0.24 0.730854C-0.08 1.05085 -0.08 1.57134 0.24 1.89098L5.84841 7.49951L0.24 13.1078C0.0856098 13.2624 0.000609756 13.4684 0.000609756 13.688C0.000609756 13.9077 0.0856098 14.1137 0.24 14.2682L0.731585 14.7595C0.885854 14.9141 1.09207 14.999 1.31146 14.999C1.53122 14.999 1.73732 14.9141 1.89159 14.7595L7.50012 9.15122L13.1084 14.7595C13.2629 14.9141 13.4689 14.999 13.6884 14.999H13.6887C13.9083 14.999 14.1143 14.9141 14.2688 14.7595L14.7602 14.2682C14.9145 14.1138 14.9996 13.9077 14.9996 13.688C14.9996 13.4684 14.9145 13.2624 14.7602 13.1079L9.15207 7.49951Z"
            fill="#374266"/>
        </g>
        <defs>
          <clipPath id="clip0_7335_6680">
            <rect width="15" height="15" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </ClosePopupButton>
  );
}

ClosePopupBtn.propTypes = {};

export default ClosePopupBtn;
