import React, { useState } from "react";
import styled, {css} from "styled-components";
import { Colors } from "../../../styles/Theme";
import MobileDropDown from "./MobileDropDown";
import "../Nav.scoped.scss";
import cn from "classnames";

export const MobileTextLink = css`
  @media (max-width: 1024px) {
    color: ${Colors.darkBlue};
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.02rem;
    line-height: 260%;
    width: 100%;
    text-align: left;
    margin-bottom: 14px;
  }
`;

const Wrapper = styled.div`
  ${MobileTextLink};

  &.active {
    .dropdownChildren {
        display: block;
    }
  }
`;

const DropdownWrapperTitle = styled.button`
  box-shadow: none;
  border: none;
  background: none;
  text-align: left;
  padding: 0;

  display: block;
  width: 100%;
`

function MobileDropDownWrapper({ title, ...props }) {
  const [isActive, setIsActive] = useState(false);

  const menuHandler = () => {
    setIsActive(!isActive);
  }

  return (
    <Wrapper className={cn({'active': isActive})}>
      <DropdownWrapperTitle className={cn('arrow-icon', {'arrow-icon-up': isActive})} onClick={menuHandler}>{title}</DropdownWrapperTitle>
      <MobileDropDown closeAll={!isActive} />
    </Wrapper>
  );
}

MobileDropDownWrapper.propTypes = {

};

export default MobileDropDownWrapper;
