export const careerResources = {
  application: [
    {
      title: 'Past Successful Applications',
      badge: 'premium',
      description: '850 verified successful cover letters from candidates with jobs at your dream company',
      to: '/company/list/general'
    },
    {
      title: 'Application Reviews',
      badge: 'premium',
      description: 'Your cover letter reviewed by industry insiders',
      to: '/application-review/'
    },
    {
      title: 'Careers Library Courses',
      badge: 'premium',
      description: 'No-nonsense career guides to help you master the art of drafting successful applications',
      to: '/library/'
    },
    {
      title: 'Applications Tracker',
      badge: 'free',
      description: 'Boost your productivity and never miss a deadline',
      to: '/tracker/'
    },
    {
      title: 'Company Profiles',
      badge: 'free',
      description: 'A deep-dive into each Company',
      to: '/company/list/general'
    },
  ],
  interview: [
    {
      title: 'Interview Testimonials',
      badge: 'premium',
      description: 'More than 200 long-form (700+ words) interview testimonials. Not like Glassdoor',
      to: '/company/list/general'
    },
    {
      title: 'Career Library',
      badge: 'premium',
      description: 'Expert guides that will help you convert your next interview into your dream job',
      to: '/library/'
    },
    {
      title: 'Psychometrics Tests',
      badge: 'premium',
      description: 'Practice career tests and avoid surprises',
      to: '/psychometrics-test/'
    },
    {
      title: 'Video Interview Simulator',
      badge: 'premium',
      description: 'The most enhanced interview simulator. Record yourself answering real questions asked in the most competitive interviews',
      to: '/interview-simulator/'
    },
  ],
  earnWith: [
    {
      title: 'Earn Money',
      badge: 'earn',
      description: 'Upload your past successful applications, cover letters or interview testimonials to earn real money transferred directly to your bank account',
      to: '/application/upload/'
    },
  ]
}
