import React, { useState } from "react";
import styled, {css} from "styled-components";
import cn from "classnames";

export const ALinkStyles = css`
  // margin-left: 22px;
  // font-size: 14px;

  padding: 0 15px 0 30px;
  font-size: 16px;
  position: relative;
  white-space: nowrap;
  color: #374266;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 64px;
  align-self: center;

  &.active {
    background-color: rgb(229, 236, 255);

    :after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      transition: 0.3s;
      left: 50%;
      transform: translateX(-50%);
      width: 42px;
      height: 5px;
      background-color: #589ce8;
    }
  }

  &:hover {
    cursor: pointer;

    :after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      transition: 0.3s;
      left: 50%;
      transform: translateX(-50%);
      width: 42px;
      height: 4px;
      background-color: #589ce8;
    }
  }

  @media screen and (min-width: 2000px) {
    zoom: 1.1;
  }

  @media screen and (max-width: 1441px) {
    font-size: 13px;
  }

  @media screen and (max-width: 1080px) {
    font-size: 13px;
  }
`;

const Wrapper = styled.div`
  ${ALinkStyles};

  border: none;
  background-color: transparent;
  text-align: left;

  &:hover {
    :after {
      width: 100%;
      background-color: #374266;
    }
  }

  &._hide:not(.active):hover {
    :after {
      display: none;
    }
  }

  &:not(._hide):hover {

    :after {
      width: 100%;
      background-color: #374266;
    }

    cursor: pointer;
    .dropdownChildren {
      display: block;
    }
  }

  &.active {
    :after {
      width: 100%;
      background-color: #374266;
    }

    .dropdownChildren {
      display: block;
    }
  }
`;

function DropDownWrapper({ children, transparentHandler, ...props }) {

  const [isActive, setIsActive] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const menuHandler = () => {
    const isActiveTemp = !isActive;
    setIsActive(isActiveTemp);
    setIsHover(true)

    if(transparentHandler) {
      transparentHandler(isActiveTemp);
    }
  }

  return (
    <Wrapper tabindex="0" role="button" className={cn({'active': isActive, '_hide': isHover})} onMouseLeave={() => {setIsHover(false)}}  onClick={menuHandler}>{children}</Wrapper>
  );
}

DropDownWrapper.propTypes = {

};

export default DropDownWrapper;
