import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the signIn state domain
 */

const selectSignInDomain = (state) => state.signIn || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by SignIn
 */

const makeSelectSignInForm = () =>
  createSelector(selectSignInDomain, (substate) => substate.form);

const makeSelectSignInSocial = () =>
  createSelector(selectSignInDomain, (substate) => substate.socialForm);

const makeSelectSignInLoading = () =>
  createSelector(selectSignInDomain, (substate) => substate.loading);

const makeSelectSignInError = () =>
  createSelector(selectSignInDomain, (substate) => substate.error);

export {
  selectSignInDomain,
  makeSelectSignInForm,
  makeSelectSignInLoading,
  makeSelectSignInError,
  makeSelectSignInSocial
};
