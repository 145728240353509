import React from 'react';

const TrustBoxFooter = () => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div ref={ref}
         className="trustpilot-widget footer-widget"
         data-locale="en-GB"
         data-template-id="56278e9abfbbba0bdcd568bc"
         data-businessunit-id="5d04105ea847d600016be158"
         data-style-height="35px"
         data-style-width="180px"
         data-style-margin="0"
    >
      <a href="https://uk.trustpilot.com/review/www.congrapps.com"
         target="_blank"
         rel="noopener">
        Trustpilot
      </a>
    </div>
  );
};

export default TrustBoxFooter;
