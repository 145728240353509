import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from "styled-components";
import {Link} from 'react-router-dom';
import Spacer from '../../components/Spacer';
import SearchForm from '../../containers/SearchForm';

import {Manifest} from './manifest';
import {Colors, Metrics} from '../../styles/Theme';
import NavSignIn from '../../containers/NavSignIn/Loadable';
import Button from "../Button";
import MobileDropDownWrapper, { MobileTextLink } from "./components/MobileDropDownWrapper";

const Wrapper = styled.div`
  left: 0;
  background-color: white;
  width: 100%;
  height: 100%;
  box-shadow: 0 2x 6px ${Colors.shadow};
  padding: 90px 20px 6px 20px;
  position: fixed;
  z-index: 9000;

  font-weight: 500;
  font-size: 14px;
  color: black;
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  flex-direction: column;
  overflow-x: scroll;

  transform: ${(props) =>
  props.isActive ? 'translateY(0px)' : 'translateY(-750px)'};

  transition: transform 300ms;

  a {
    // display: block;
    // margin-bottom: 12px;
  }

  .header-profile {
    margin-bottom: 50px;
  }
`;




const TextLink = styled(Link)`
  ${MobileTextLink};
`;

const ALink = styled.a`
  color: ${Colors.textPrimary};

  @media (max-width: 1024px) {
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: 500;
    letter-spacing: 0.02rem;
    line-height: 260%;
    width: 100%;
    text-align: center;
  }
`;
const PageLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
`;

const LogoutButton = styled(Button)`
  width: 133px;
  height: 35px;
  margin: 0 auto;
  color: #fff;
  background: #F67770;
  border: none;
  border-radius: 8px;
  box-shadow: none;
  font-size: 16px;
  padding: 10px;
  text-transform: uppercase;
`;

const blockScroll = () => {
  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';
};
const releaseScroll = () => {
  document.body.style.position = 'initial';
  document.body.style.overflow = 'initial';
};

function MobileMenu({isActive, handleClick, isAuth = false, userData = "", ...rest}) {

  useEffect(() => {
    return releaseScroll;
  }, []);

  useEffect(() => {
    if (isActive) {
      blockScroll();
    } else {
      releaseScroll();
    }
  }, [isActive]);

  return (
    <Wrapper isActive={isActive}>
      <SearchForm/>
      <PageLinks>
        {Manifest.map((link) => {
          if(!!link.hasDropdown) {
            return (
              <MobileDropDownWrapper key={link.name} title={link.name} />
            )
          }

          if (link.path) {
            return (
              <TextLink key={link.name} to={link.path} onClick={handleClick}>
                {link.name}
              </TextLink>
            );
          }
          return (
            <ALink key={link.name} href={`${link.url}?user=${userData}`}>
              {link.name}
            </ALink>
          );
        })}
        {isAuth ?
          <TextLink key="/my-account/" to='/my-account/' onClick={handleClick}>
            Your Profile
          </TextLink>
          : ''
        }
        {/*<Spacer height={Metrics.smallSpacer}/>*/}
        {/*{isAuth ?*/}
        {/*  <LogoutButton to="/logout" onClick={handleClick}>*/}
        {/*    Logout*/}
        {/*  </LogoutButton>*/}
        {/*  : ''*/}
        {/*}*/}

      </PageLinks>
      <NavSignIn/>
    </Wrapper>
  );
}

MobileMenu.propTypes = {
  isActive: PropTypes.bool,
  handleClick: PropTypes.any,
};

export default MobileMenu;
