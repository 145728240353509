/*
 *
 * Company actions
 *
 */

import {
  DEFAULT_ACTION,
  RESET_STATE,
  // plopConstImport
  COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ACTION,
  COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_SUCCESS_ACTION,
  COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ERROR_ACTION,
  COMPANY_GET_APPLICATION_LIST_ACTION,
  COMPANY_GET_APPLICATION_LIST_SUCCESS_ACTION,
  COMPANY_GET_APPLICATION_LIST_ERROR_ACTION,
  GET_COMPANY,
  GET_COMPANY_ERROR,
  GET_COMPANY_INDUSTRY_LIST,
  GET_COMPANY_INDUSTRY_LIST_ERROR,
  GET_COMPANY_INDUSTRY_LIST_SUCCESS,
  GET_COMPANY_LIST,
  GET_COMPANY_LIST_ERROR,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_TYPE_LIST,
  GET_COMPANY_TYPE_LIST_ERROR,
  GET_COMPANY_TYPE_LIST_SUCCESS,
  GET_COMPANY_LOCATION_LIST,
  GET_COMPANY_LOCATION_LIST_SUCCESS,
  GET_COMPANY_LOCATION_LIST_ERROR,
  COMPANY_GET_LIBRARY_LIST_ACTION,
  COMPANY_GET_LIBRARY_LIST_SUCCESS_ACTION,
  COMPANY_GET_LIBRARY_LIST_ERROR_ACTION,
  COMPANY_GET_POST_LIST_ACTION,
  COMPANY_GET_POST_LIST_SUCCESS_ACTION,
  COMPANY_GET_POST_LIST_ERROR_ACTION,
  COMPANY_GET_POST_BY_TAG_LIST_ACTION,
  COMPANY_GET_POST_BY_TAG_LIST_ERROR_ACTION,
  COMPANY_GET_POST_BY_TAG_LIST_SUCCESS_ACTION,
} from './constants';

export function getCompanyListAction(payload) {
  return {
    type: GET_COMPANY_LIST,
    payload
  };
}

export function resetStateAction() {
  return {
    type: RESET_STATE,
  };
}

export function getCompanyListSuccessAction(payload) {
  return {
    type: GET_COMPANY_LIST_SUCCESS,
    payload,
  };
}

export function getCompanyListErrorAction(payload) {
  return {
    type: GET_COMPANY_LIST_ERROR,
    payload,
  };
}

export function getCompanyTypeListAction() {
  return {
    type: GET_COMPANY_TYPE_LIST,
  };
}

export function getCompanyTypeListSuccessAction(payload) {
  return {
    type: GET_COMPANY_TYPE_LIST_SUCCESS,
    payload,
  };
}

export function getCompanyTypeListErrorAction(payload) {
  return {
    type: GET_COMPANY_TYPE_LIST_ERROR,
    payload,
  };
}

export function getCompanyIndustryListAction(payload) {
  return {
    type: GET_COMPANY_INDUSTRY_LIST,
    payload
  };
}

export function getCompanyIndustryListSuccessAction(payload) {
  return {
    type: GET_COMPANY_INDUSTRY_LIST_SUCCESS,
    payload,
  };
}

export function getCompanyIndustryListErrorAction(payload) {
  return {
    type: GET_COMPANY_INDUSTRY_LIST_ERROR,
    payload,
  };
}

export function getCompanyAction(payload) {
  return {
    type: GET_COMPANY,
    payload,
  };
}

export function getCompanySuccessAction(payload) {
  return {
    type: GET_COMPANY_SUCCESS,
    payload,
  };
}

export function getCompanyErrorAction(payload) {
  return {
    type: GET_COMPANY_ERROR,
    payload,
  };
}

export function companyGetApplicationListAction(payload) {
  return {
    type: COMPANY_GET_APPLICATION_LIST_ACTION,
    payload,
  };
}

export function companyGetApplicationListSuccessAction(payload) {
  return {
    type: COMPANY_GET_APPLICATION_LIST_SUCCESS_ACTION,
    payload,
  };
}

export function companyGetApplicationListErrorAction(payload) {
  return {
    type: COMPANY_GET_APPLICATION_LIST_ERROR_ACTION,
    payload,
  };
}

export function companyGetLibraryListAction(payload) {
  return {
    type: COMPANY_GET_LIBRARY_LIST_ACTION,
    payload,
  };
}

export function companyGetLibraryListSuccessAction(payload) {
  return {
    type: COMPANY_GET_LIBRARY_LIST_SUCCESS_ACTION,
    payload,
  };
}

export function companyGetLibraryListErrorAction(payload) {
  return {
    type: COMPANY_GET_LIBRARY_LIST_ERROR_ACTION,
    payload,
  };
}

export function companyGetPostListAction(payload) {
  return {
    type: COMPANY_GET_POST_LIST_ACTION,
    payload,
  };
}

export function companyGetPostListSuccessAction(payload) {
  return {
    type: COMPANY_GET_POST_LIST_SUCCESS_ACTION,
    payload,
  };
}

export function companyGetPostListErrorAction(payload) {
  return {
    type: COMPANY_GET_POST_LIST_ERROR_ACTION,
    payload,
  };
}

export function companyGetPostByTagListAction(payload) {
  return {
    type: COMPANY_GET_POST_BY_TAG_LIST_ACTION,
    payload,
  };
}

export function companyGetPostByTagListSuccessAction(payload) {
  return {
    type: COMPANY_GET_POST_BY_TAG_LIST_SUCCESS_ACTION,
    payload,
  };
}

export function companyGetPostByTagListErrorAction(payload) {
  return {
    type: COMPANY_GET_POST_BY_TAG_LIST_ERROR_ACTION,
    payload,
  };
}

export function companyGetInterviewExperienceListAction(payload) {
  return {
    type: COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ACTION,
    payload,
  };
}

export function companyGetInterviewExperienceListSuccessAction(payload) {
  return {
    type: COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_SUCCESS_ACTION,
    payload,
  };
}

export function companyGetInterviewExperienceListErrorAction(payload) {
  return {
    type: COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ERROR_ACTION,
    payload,
  };
}

// Locations
export function getCompanyLocationListAction() {
  return {
    type: GET_COMPANY_LOCATION_LIST,
  };
}

export function getCompanyLocationListSuccessAction(payload) {
  return {
    type: GET_COMPANY_LOCATION_LIST_SUCCESS,
    payload,
  };
}

export function getCompanyLocationListErrorAction(payload) {
  return {
    type: GET_COMPANY_LOCATION_LIST_ERROR,
    payload,
  };
}
