import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { requestJSON } from '../../utils/request';
import {makeSelectSignInForm, makeSelectSignInSocial} from './selectors';
import { saveUserTokenAction } from '../App/actions';
import { SAVE_USER_TOKEN_SUCCESS } from '../App/constants';
import {
  SIGNIN_FORM_SUBMIT_ACTION,
  SIGNIN_FORM_SUBMIT_SUCCESS_ACTION,
  SIGNIN_SOCIAL_SUBMIT_ACTION
} from './constants';
import {
  signinFormSubmitErrorAction,
  signinFormSubmitSuccessAction,
} from './actions';
import {getCookie, setCookie} from "../../utils/utils";
import { COOKIE_PRODUCT_ID } from "../../utils/constants";
import {REDIRECT_LINK} from "../InterviewSimulatorLanding/costants";

export function* signinFormSubmit() {
  // Select username from store
  const payload = yield select(makeSelectSignInForm());
  const requestURL = `user/signin/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, payload, null, 'POST');
    yield put(saveUserTokenAction(result));
    yield put(signinFormSubmitSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);

    yield put(signinFormSubmitErrorAction(err));
  }
}

export function* signinFormSubmitSuccess(result) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'Signin',
  });

  const redirectLink = getCookie(REDIRECT_LINK)

  if (redirectLink) {
    setCookie(REDIRECT_LINK, '')
    yield put(push(redirectLink));
  } else {
    if(result['payload'] !== undefined && result['payload']['successPage'] !== undefined && result['payload']['successPage']) {
      yield put(push('/success/free'));
    } else {
      const productId = getCookie(COOKIE_PRODUCT_ID);
      if(productId !== undefined && productId) {
        yield put(push('/checkout/' + productId));
      } else {
        // Redirect to my account
        yield put(push('/company/list'));
      }

    }
  }


}

export function* signinSocialSubmit() {
  // Select username from store
  const payload = yield select(makeSelectSignInSocial());
  const requestURL = `dj-rest-auth/${payload.item}/`;

  try {
    // Call our request helper (see 'utils/request')
    let result = yield call(requestJSON, requestURL, payload, null, 'POST');
    result = {
      token: result.access_token
    }
    yield put(saveUserTokenAction(result));
    yield put(signinFormSubmitSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);

    yield put(signinFormSubmitErrorAction(err));
  }
}

// Individual exports for testing
export default function* signInSaga() {
  yield takeLatest(SIGNIN_FORM_SUBMIT_ACTION, signinFormSubmit);
  yield takeLatest(SIGNIN_SOCIAL_SUBMIT_ACTION, signinSocialSubmit);
  yield takeLatest(SIGNIN_FORM_SUBMIT_SUCCESS_ACTION, signinFormSubmitSuccess);
  // See example in containers/HomePage/saga.js
}


