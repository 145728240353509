import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {makeSelectSignUpForm, makeSelectSignUpFormLinkRedirect, makeSelectSignUpSocial} from './selectors';
import { requestJSON } from '../../utils/request';
import {
  // plopConstImport
  SIGN_UP_VALIDATE_REFERRAL_CODE_ACTION,
  SIGNUP_FORM_SUBMIT_ACTION,
  SIGNUP_FORM_SUBMIT_SUCCESS_ACTION,
  SIGNUP_SOCIAL_SUBMIT_ACTION
} from './constants';
import {
  // plopActionImport
  signUpValidateReferralCodeSuccessAction,
  signUpValidateReferralCodeErrorAction,
  signupFormSubmitErrorAction,
  signupFormSubmitSuccessAction,
} from './actions';
import { saveUserTokenAction } from '../App/actions';
import { userGetAction } from '../User/actions';
import { makeSelectUserToken } from '../App/selectors';
import { makeSelectUser } from '../User/selectors';
import { USER_GET_SUCCESS_ACTION } from '../User/constants';
import {getCookie, setCookie} from "../../utils/utils";
import { COOKIE_PRODUCT_ID } from "../../utils/constants";
import {REDIRECT_LINK} from "../InterviewSimulatorLanding/costants";

// plopSagaExports
export function* signUpValidateReferralCode(action) {
  const { referralCode } = action.payload;
  const requestURL = `referral/${referralCode}/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, null, 'GET');
    yield put(signUpValidateReferralCodeSuccessAction(result));
  } catch (err) {
    console.log('There was an error: ', err);
    yield put(signUpValidateReferralCodeErrorAction(err));
  }
}

export function* signupFormSubmit() {
  // Select username from store
  const payload = yield select(makeSelectSignUpForm());
  const requestURL = `user/signup/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, payload, null, 'POST');
    yield put(saveUserTokenAction(result));
    yield put(userGetAction());
    yield take([USER_GET_SUCCESS_ACTION]);
    yield put(signupFormSubmitSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);
    yield put(signupFormSubmitErrorAction(err));
  }
}

export function* signupFormSubmitSuccess(result) {
  const user = yield select(makeSelectUser());
  const linkRedirect = yield select(makeSelectSignUpFormLinkRedirect());

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'Signup',
    userId: user.id,
  });

  const productId = getCookie(COOKIE_PRODUCT_ID);
  const redirectLink = getCookie(REDIRECT_LINK)

  if (redirectLink) {
    setCookie(REDIRECT_LINK, '')
    yield put(push(redirectLink));
  } else {
    if(productId !== undefined && productId) {
      yield put(push('/checkout/' + productId));
    } else {
      if(result['payload'] !== undefined && result['payload']['successPage'] !== undefined && result['payload']['successPage']) {
        yield put(push('/success/free'));
      } else {
        // Redirect to my account
        const link = linkRedirect ? linkRedirect : '/company/list/general';
        if(link !== 'pay_process') {
          yield put(push(link));
        }
      }
    }
  }

}

export function* signupSocialSubmit() {
  // Select username from store
  const payload = yield select(makeSelectSignUpSocial());
  const requestURL = `dj-rest-auth/${payload.item}/`;

  try {
    // Call our request helper (see 'utils/request')
    let result = yield call(requestJSON, requestURL, payload, null, 'POST');
    result = {
      token: result.access_token
    }
    yield put(saveUserTokenAction(result));
    yield put(userGetAction());
    yield take([USER_GET_SUCCESS_ACTION]);
    yield put(signupFormSubmitSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);

    yield put(signupFormSubmitErrorAction(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* signUpSaga() {
  // plopDefaultSagaExports
  yield takeLatest(
    SIGN_UP_VALIDATE_REFERRAL_CODE_ACTION,
    signUpValidateReferralCode,
  );

  yield takeLatest(SIGNUP_FORM_SUBMIT_ACTION, signupFormSubmit);
  yield takeLatest(SIGNUP_SOCIAL_SUBMIT_ACTION, signupSocialSubmit);
  yield takeLatest(SIGNUP_FORM_SUBMIT_SUCCESS_ACTION, signupFormSubmitSuccess);
}
