export const Manifest = [
  {
    name: 'Career Resources',
    hasDropdown: true,
  },
  {
    name: 'Companies',
    path: '/company/list',
  },
  {
    name: 'Library',
    path: '/library/',
  },
  // {
  //   name: 'Application Review',
  //   path: '/application-review/',
  //   mobile: true,
  // },
  // {
  //   name: 'Interview Simulator',
  //   path: '/interview-simulator',
  //   mobile: true
  // },
  /*  {
      name: 'How it Works',
      path: '/how-it-works/',
    },*/
  // Remove "Earn" tab from the header and delete that page completely.
  // {
  //   name: 'Earn',
  //   path: '/congrapps-credit',
  // },
  {
    name: 'Pricing',
    path: '/pricing',
  },
  {
    name: 'Earn',
    path: '/application/upload/',
  },
  // {
  //   name: 'Tracker',
  //   path: '/tracker/',
  //   mobile: 'true'
  // },
];
