import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import facebookFooterSvg from '../../images/facebookFooter.svg';
import linkedInFooterSvg from '../../images/linkedinFooter.svg';
import instagramFooterSvg from '../../images/instFooter.svg';
import emailFooterSvg from '../../images/mailFooter.svg';
import TrustBoxFooter from '../TrustBoxFooter/TrustBoxFooter';

const Wrapper = styled.footer`
  display: flex;
  width: 100%;
  background: #24285B;
  flex-direction: column;
  position: relative;
  padding-left: 10.28vw;
  padding-right: 10.28vw;

  @media screen and (max-width: 1025px) {
    padding-left: 38px;
    padding-right: 38px;
  }
`;

const FooterWrapper = styled.footer`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 1525px;
  height: 100%;
  margin: 0 auto;
  background: #24285B;
  gap: 2.5vw;

  .copyright-text{
    color: #FFF;
    font-family: Inter, sans-serif;
    font-size: max(10px, 0.989vw);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0 0 8px 0;
  }

  @media screen and (min-width: 2000px) {
    max-width: 79.42vw;
  }

`;

const FooterMenu = styled.div`
  display: flex;
  width: 100%;
  padding-top: max(15px, 2.81vw);
  padding-bottom: 0px;

  color: white;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: space-between;
  align-content: flex-start;

  @media screen and (max-width: 1025px) {
    row-gap: 15px;
    padding-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    column-gap: 54px;
    justify-content: flex-start;
  }
`;

const TextLinks = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: max(28px, 3.645vw);
  flex-flow: row wrap;
`;

const ContactUs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: max(10.67px, 0.83vw);
  padding: max(13.33px, 1.04vw) 0;

  .footer-widget{
    margin-top: 0;
    margin-left: -23px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: max(10.67px, 0.83vw);
  padding: max(13.33px, 1.04vw) 0;
`;

const titleStyle = css`
  font-size: 1.4em;
  // font-weight: 600;
`;

const Li = styled(Link)`
  color: #FFF;
  font-family: Inter, sans-serif;
  font-size: max(8px, 0.859vw);
  font-style: normal;
  font-weight: 400;
  color: #BDBDBD; 
  line-height: 100%; /* 28.5px */


  &:hover {
    color: #61a9f6;
  }
`;

const Icon = styled.div`
  width: max(16px, 1.25vw);
  height: max(16px, 1.25vw);

  img{
    width: 100%;
  }
`;

const IconsWrapper = styled.div`
  gap: 0.625vw;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: 1025px) {
    display: none;
  }
`;

const ContactTitle = styled.div`
  color: #FFF;
  font-family: Inter, sans-serif;
  font-size: max(12px, 1.25vw);
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  display: flex;
  gap: 11px;
  align-items: center;

  div{
    display: none;
    @media screen and (max-width: 1025px) {
      display: flex;
    }
  }
`;

const ContactTitleHidden = styled(ContactTitle)`
  visibility: hidden;
`

function Footer() {
  return (
    <Wrapper className="mainFooterWrapper">
      <FooterWrapper>
        <FooterMenu>
          <TextLinks>
            <List>
              <ContactTitle>Career Resources</ContactTitle>
              <Li to="/company/list">Past Successful Applications</Li>
              <Li to="/application-review/">Application Reviews</Li>
              <Li to="/library/">Career Library Courses</Li>
              <Li to="/application/tracker/">Application Tracker</Li>
            </List>
            <List>
              <ContactTitleHidden>Help & FAQ</ContactTitleHidden>
              <Li to="/company/list">Interview Testimonials</Li>
              <Li to="/interview-simulator/">Interview Simulator</Li>
              <Li to="/psychometrics-test/">Watson Glaser Tests</Li>
              <Li to="/application/upload/">Earn Money</Li>
            </List>
          </TextLinks>
          <List>
            <ContactTitle>Useful Information</ContactTitle>
            <Li to="/library/">Articles</Li>
            <Li to="/pricing">Pricing</Li>
            <Li to="/blog/the-congrapps-guide">Congrapps Guide</Li>
          </List>
          <List>
            <ContactTitle>Legal</ContactTitle>
            <Li to="/about-us">About Us</Li>
            <Li to="/privacy-policy">Privacy Policy</Li>
            <Li to="/terms-and-conditions">Terms & Conditions</Li>
          </List>
          <ContactUs>
            <ContactTitle>
              Contact us
              <IconsWrapper>
                <Icon className="icon">
                  <a href="https://www.instagram.com/congrapps.official/">
                    <img src={instagramFooterSvg} />
                  </a>
                </Icon>
                <Icon className="icon">
                  <a href="https://www.facebook.com/Congrapps">
                    <img src={facebookFooterSvg} />
                  </a>
                </Icon>
                <Icon className="icon">
                  <a href="https://www.linkedin.com/company/congrapps/">
                    <img src={linkedInFooterSvg} />
                  </a>
                </Icon>
                <Icon className="icon">
                  <a href="mailto:info@congrapps.com">
                    <img src={emailFooterSvg} />
                  </a>
                </Icon>
              </IconsWrapper>
            </ContactTitle>
            <IconsWrapper>
              <Icon className="icon">
                <a href="https://www.instagram.com/congrapps.official/">
                  <img src={instagramFooterSvg} />
                </a>
              </Icon>
              <Icon className="icon">
                <a href="https://www.facebook.com/Congrapps">
                  <img src={facebookFooterSvg} />
                </a>
              </Icon>
              <Icon className="icon">
                <a href="https://www.linkedin.com/company/congrapps/">
                  <img src={linkedInFooterSvg} />
                </a>
              </Icon>
              <Icon className="icon">
                <a href="mailto:info@congrapps.com">
                  <img src={emailFooterSvg} />
                </a>
              </Icon>
            </IconsWrapper>
            <TrustBoxFooter />
          </ContactUs>
        </FooterMenu>
        <div className="copyright-text-container">
          <div className="copyright-text left" style={{ color: 'white' }}>
            © 2020-{new Date().getFullYear()} All Rights Reserved{' '}
            <a className="site-name" href="https://congrapps.com/" rel="home">
              Congrapps
            </a>
            ,<span className="developed-by"> Created with ❤ in Europe 🇪🇺</span>
          </div>
        </div>
      </FooterWrapper>
    </Wrapper>
  );
}

export default Footer;
