/**
 *
 * SignIn
 *
 */

import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import Divider from '../../components/Divider';
import { FormGroup } from '../../components/Form';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet';
import {
  makeSelectSignInError,
  makeSelectSignInForm,
  makeSelectSignInLoading,
  makeSelectSignInSocial
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import PageChrome from '../../components/PageChrome';
import InputField from '../../components/InputField';
import {
  resetStateAction,
  signinFormSubmitAction,
  signinFormUpdateAction,
  signinSocialSubmitAction,
  signinSocialUpdateAction,
} from './actions';
import Button from '../../components/Button';
import { Colors } from '../../styles/Theme';
import WhiteModal from '../../components/WhiteModal';
import Title from '../../components/WhiteModal/Title';
import ContentDescription from '../../components/WhiteModal/ContentDescription';
import isModalErrorMessage from '../../utils/isModalErrorMessage';
import useBreadcrumbs from '../../utils/useBreadcrumbs';
import { resetBreadcrumbs } from '../App/actions';
import SocialLogin from "../../components/SocialLogin";
import AuthViewList from "../../components/AuthViewList";
import AuthView, { AuthPadding, AuthSubTitle, AuthTitle } from "../../components/AuthView";
import Main from "../../components/Main";
import AuthViewForm from "../../components/AuthViewForm";
import eye from "../../images/auth/eye.svg"
import eyeSlash from "../../images/auth/eye-slash.svg"
import { makeSelectStatsGet } from "../Stats/selectors";
import statsReducer from "../Stats/reducer";
import statsSaga from "../Stats/saga";
import { statsGetAction } from "../Stats/actions";
import { isEmptyObject } from "../../utils/utils";

const BlueLink = styled(Link)`
  color: ${Colors.textAccent};
  font-weight: bold;
`;
const LoginButton = styled(Button)`
  margin: ${(props) => props.top || '20px'} auto 26px;
  font-size: 22px;
  width: 100%;
`;

const SpanInP = styled.span`
  color: #505e78;
  font-weight: 700;
`;

const SpanCenter = styled.span`
  display: flex;
  align-items: center;
`;

const FlexBetween = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 7px;
`;

const EyeButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 24px;
  color: #7C869A;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 17px;
  right: 17px;
  z-index: 1;
  cursor: pointer;


  img {
    width: 24px;
    display: block;
    height: auto;
  }
`;

export const LoginBlockTitle = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: #787885;
  position: relative;
  padding-bottom: 15px;
  margin: 0 auto 18px;
  width: 100%;
  max-width: 700px;
  padding-top: ${props => props.paddingTop ? props.paddingTop : "0"};

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #e5ecff;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const centerSectionList = [
  {
    fields: [
      {
        type: 'text',
        label: 'Email',
        name: 'email',
      },
      {
        type: 'password',
        label: 'Password',
        name: 'password',
      },
    ],
  },
];

const passwordViewType = {
  'password': 'text',
  'text': 'password'
}



Modal.setAppElement(document.getElementById('app'));

export function SignIn({
  dispatch,
  form,
  loading,
  error,
  formSubmit,
  formUpdate,
  socialForm,
  socialUpdate,
  socialSubmit, stats,
  location
}) {
  useInjectReducer({ key: 'signIn', reducer });
  useInjectSaga({ key: 'signIn', saga });

  useInjectReducer({ key: 'stats', reducer: statsReducer });
  useInjectSaga({ key: 'stats', saga: statsSaga });

  const errorModalRef = useRef(null);
  const [errorsList, setErrorsList] = useState({})
  const [passwordType, setPasswordType] = useState('password')
  const [pageTitle, setPageTitle] = useState('Welcome back!');

  useEffect(() => {
    if(isEmptyObject(stats)) {
      dispatch(statsGetAction());
    }

    if (!isEmptyObject(location.state) && location.state['fromSignUp'] !== undefined && location.state.fromSignUp) {
      setPageTitle('Step 1/2: Sign In');
      window.history.replaceState({}, document.title)
      // setIsCustomPageTitle(true);
    }
    const cleanup = () => {
      dispatch(resetStateAction());
      dispatch(resetBreadcrumbs());
    };

    return cleanup;
  }, []);

  useEffect(() => {
    setErrorsList(error);
    if (isModalErrorMessage(error)) {
      errorModalRef.current.openModal();
    }
  }, [error]);

  useBreadcrumbs([
    {
      to: '/sign-in/',
      title: 'Login',
    },
  ]);

  const onFromSubmit = (evt) => {
    if (evt !== undefined && evt.preventDefault) evt.preventDefault();
    formSubmit();
  };

  const onFormChange = (evt) => {
    if (evt !== undefined && evt.persist) evt.persist();
    const newForm = { ...form };
    newForm[evt.target.name] = evt.target.value;
    formUpdate(newForm);
  };

  const handleSubmit = (payload) => {
    formUpdate(payload);
    formSubmit();
  }

  const handleSocialSignin = (payload) => {
    const data = payload.data;
    const newSocialForm = { ...socialForm };
    if(payload.item === 'facebook') {
      newSocialForm['access_token'] = data.accessToken;
    } else {
      newSocialForm['code'] = data.code;
    }
    newSocialForm['item'] = payload.item;
    socialUpdate(newSocialForm);
    socialSubmit();
  }

  const modalErrorMessage = error.message || error.detail;


  const initialValues = {
    email: null,
    password: null
  }

  return (
    <PageChrome showBackground={false} showFooter={false} isFixedMinWidth={false}>
      <Helmet>
        <title>Sign In | Congrapps</title>
        <meta
          name="description"
          content="Log into your Congrapps account to get access to thousands of past successful applications for different jobs and companies. Find discounts and gifts.
"
        />
      </Helmet>
      <WhiteModal
        ref={errorModalRef}
        style={{
          content: {
            maxWidth: '768px',
          },
        }}
      >
        <>
          <Title>There was a problem logging you in!</Title>

          <ContentDescription>{modalErrorMessage}</ContentDescription>
        </>
      </WhiteModal>

      <Main>
        <AuthView>
          <AuthViewList stats={stats} />
          <AuthViewForm>
            <AuthTitle><h1>{pageTitle}</h1></AuthTitle>
            <AuthSubTitle marginBottom="27px">Continue your career journey</AuthSubTitle>
            <SocialLogin loginListener={handleSocialSignin}/>
            <AuthPadding padding={"13px 0 9px"}>
              <AuthSubTitle>or continue with email</AuthSubTitle>
            </AuthPadding>
            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                // email: Yup.string().required('This field is required'),
                // password: Yup.string().required('This field is required'),
              })}
              onSubmit={async (values) => {
                handleSubmit(values);
              }}
              // validateOnChange={false}
              // validateOnBlur={true}
            >
              {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  setTouched,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                <form className="signin-form" onSubmit={handleSubmit}>
                  <FormGroup>
                    <div>
                      <InputField
                        name="email"
                        type="email"
                        form={values}
                        value={values.email}
                        onChange={handleChange}
                        errors={!!errors.email ? errors : errorsList}
                        label="Email"
                      />
                      <Divider border="none" />
                      <div  className="position-relative">
                        <InputField
                          name="password"
                          type={passwordType}
                          form={values}
                          onChange={handleChange}
                          errors={!!errors.password ? errors : errorsList }
                          value={values.password}
                          label="Password"
                        />
                        <EyeButton onClick={() => setPasswordType(passwordViewType[passwordType])}><img src={passwordType === 'password' ? eyeSlash : eye} alt="eye" /></EyeButton>

                      </div>

                    </div>
                    <FlexBetween>
                      <div>
                        <AuthSubTitle>
                          <SpanCenter><InputField
                            name="remember_me"
                            type="checkbox"
                            form={values}
                            errors={errorsList}
                            onChange={handleChange}
                            label={""}
                          />Remember me</SpanCenter></AuthSubTitle>
                      </div>
                      <div>
                        <AuthSubTitle><Link to="/forgot-password" className="highlight-blue">Forgot your password?</Link></AuthSubTitle>
                      </div>
                    </FlexBetween>
                  </FormGroup>
                  <LoginButton type="submit" color="cyanBlue" height='60px' width="100%" radius="8px">
                      Login
                  </LoginButton>
                  <div>
                    <AuthSubTitle>Don't have an account? <Link to="/sign-up" className="highlight-blue">sign up</Link></AuthSubTitle>
                  </div>
                </form>
              )}
            </Formik>
          </AuthViewForm>
        </AuthView>

      </Main>


    </PageChrome>
  );
}

SignIn.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formUpdate: PropTypes.func.isRequired,
  formSubmit: PropTypes.func.isRequired,
  socialUpdate: PropTypes.func.isRequired,
  socialSubmit: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  form: makeSelectSignInForm(),
  socialForm: makeSelectSignInSocial(),
  loading: makeSelectSignInLoading(),
  error: makeSelectSignInError(),
  stats: makeSelectStatsGet(),
});

function mapDispatchToProps(dispatch) {

  return {
    formUpdate: (payload) => dispatch(signinFormUpdateAction(payload)),
    formSubmit: () => dispatch(signinFormSubmitAction()),
    socialUpdate: (payload) => dispatch(signinSocialUpdateAction(payload)),
    socialSubmit: () => dispatch(signinSocialSubmitAction()),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SignIn);
