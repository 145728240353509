/*
 *
 * Company reducer
 *
 */
import produce from 'immer';
import {
  // plopConstImport
  COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ACTION,
  COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_SUCCESS_ACTION,
  COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ERROR_ACTION,
  COMPANY_GET_APPLICATION_LIST_ACTION,
  COMPANY_GET_APPLICATION_LIST_SUCCESS_ACTION,
  COMPANY_GET_APPLICATION_LIST_ERROR_ACTION,
  GET_COMPANY,
  GET_COMPANY_ERROR,
  GET_COMPANY_INDUSTRY_LIST,
  GET_COMPANY_INDUSTRY_LIST_ERROR,
  GET_COMPANY_INDUSTRY_LIST_SUCCESS,
  GET_COMPANY_LIST,
  GET_COMPANY_LIST_ERROR,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_TYPE_LIST,
  GET_COMPANY_TYPE_LIST_ERROR,
  GET_COMPANY_TYPE_LIST_SUCCESS,
  GET_COMPANY_LOCATION_LIST,
  GET_COMPANY_LOCATION_LIST_SUCCESS,
  GET_COMPANY_LOCATION_LIST_ERROR,
  COMPANY_GET_LIBRARY_LIST_ACTION,
  COMPANY_GET_LIBRARY_LIST_ERROR_ACTION,
  COMPANY_GET_LIBRARY_LIST_SUCCESS_ACTION,
  COMPANY_GET_POST_LIST_ACTION,
  COMPANY_GET_POST_LIST_SUCCESS_ACTION,
  COMPANY_GET_POST_LIST_ERROR_ACTION,
  COMPANY_GET_POST_BY_TAG_LIST_ACTION,
  COMPANY_GET_POST_BY_TAG_LIST_ERROR_ACTION,
  COMPANY_GET_POST_BY_TAG_LIST_SUCCESS_ACTION,
} from './constants';
import {
  DEFAULT_ACTION,
  RESET_STATE,
} from '../JobTrackerEditJobModal/constants';

export const initialState = {
  getInterviewExperienceList: [],
  getInterviewExperienceListLoading: true,
  getInterviewExperienceListError: {},

  getApplicationList: [],
  getApplicationListLoading: true,
  getApplicationListError: {},

  getLibraryList: [],
  getLibraryListLoading: true,
  getLibraryListError: {},

  getPostList: [],
  getPostListLoading: true,
  getPostListError: {},

  getPostByTagList: [],
  getPostByTagListLoading: true,
  getPostByTagListError: {},

  list: [],
  companyTypeList: [],
  companyIndustryList: [],
  companyLocationList: [],
  current: {},
  error: {},
  loading: false,
};

/* eslint-disable default-case, no-param-reassign */
const companyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      case DEFAULT_ACTION:
        break;
      // plopCasesImport
      case COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ACTION:
        draft.getInterviewExperienceListLoading = true;
        draft.getInterviewExperienceListError = {};
        break;

      case COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_SUCCESS_ACTION:
        draft.getInterviewExperienceList = action.payload;
        draft.getInterviewExperienceListLoading = false;
        draft.getInterviewExperienceListError = {};
        break;

      case COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ERROR_ACTION:
        draft.getInterviewExperienceListLoading = false;
        draft.getInterviewExperienceListError = action.payload;
        break;

      case COMPANY_GET_APPLICATION_LIST_ACTION:
        draft.getApplicationListLoading = true;
        draft.getApplicationListError = {};
        break;

      case COMPANY_GET_APPLICATION_LIST_SUCCESS_ACTION:
        draft.getApplicationList = action.payload;
        draft.getApplicationListLoading = false;
        draft.getApplicationListError = {};
        break;

      case COMPANY_GET_APPLICATION_LIST_ERROR_ACTION:
        draft.getApplicationListLoading = false;
        draft.getApplicationListError = action.payload;
        break;

      case COMPANY_GET_LIBRARY_LIST_ACTION:
        draft.getLibraryListLoading = true;
        draft.getLibraryListError = {};
        break;

      case COMPANY_GET_LIBRARY_LIST_SUCCESS_ACTION:
        draft.getLibraryList = action.payload;
        draft.getLibraryListLoading = false;
        draft.getLibraryListError = {};
        break;

      case COMPANY_GET_LIBRARY_LIST_ERROR_ACTION:
        draft.getLibraryListLoading = false;
        draft.getLibraryListError = action.payload;
        break;

      case COMPANY_GET_POST_LIST_ACTION:
        draft.getPostListLoading = true;
        draft.getPostListError = {};
        break;

      case COMPANY_GET_POST_LIST_SUCCESS_ACTION:
        draft.getPostList = action.payload;
        draft.getPostListLoading = false;
        draft.getPostListError = {};
        break;

      case COMPANY_GET_POST_LIST_ERROR_ACTION:
        draft.getPostListLoading = false;
        draft.getPostListError = action.payload;
        break;

      case COMPANY_GET_POST_BY_TAG_LIST_ACTION:
        draft.getPostByTagListLoading = true;
        draft.getPostByTagListError = {};
        break;

      case COMPANY_GET_POST_BY_TAG_LIST_SUCCESS_ACTION:
        draft.getPostByTagList = action.payload;
        draft.getPostByTagListLoading = false;
        draft.getPostByTagListError = {};
        break;

      case COMPANY_GET_POST_BY_TAG_LIST_ERROR_ACTION:
        draft.getPostByTagListLoading = false;
        draft.getPostByTagListError = action.payload;
        break;

      case GET_COMPANY_LIST:
        draft.loading = true;
        draft.error = {};
        break;
      case GET_COMPANY_LIST_SUCCESS:
        draft.list = action.payload;
        draft.loading = false;
        draft.error = {};
        break;
      case GET_COMPANY_LIST_ERROR:
        draft.list = [];
        draft.loading = false;
        draft.error = action.payload;
        break;

      case GET_COMPANY_TYPE_LIST:
        draft.loading = true;
        draft.error = {};
        break;
      case GET_COMPANY_TYPE_LIST_SUCCESS:
        draft.companyTypeList = action.payload;
        draft.loading = false;
        draft.error = {};
        break;
      case GET_COMPANY_TYPE_LIST_ERROR:
        draft.companyTypeList = [];
        draft.loading = false;
        draft.error = action.payload;
        break;

      case GET_COMPANY_INDUSTRY_LIST:
        draft.loading = true;
        draft.error = {};
        break;
      case GET_COMPANY_INDUSTRY_LIST_SUCCESS:
        draft.companyIndustryList = action.payload;
        draft.loading = false;
        draft.error = {};
        break;
      case GET_COMPANY_INDUSTRY_LIST_ERROR:
        draft.companyTypeList = [];
        draft.loading = false;
        draft.error = action.payload;
        break;

      case GET_COMPANY_LOCATION_LIST:
        draft.loading = true;
        draft.error = {};
        break;
      case GET_COMPANY_LOCATION_LIST_SUCCESS:
        draft.companyLocationList = action.payload;
        draft.loading = false;
        draft.error = {};
        break;
      case GET_COMPANY_LOCATION_LIST_ERROR:
        draft.companyTypeList = [];
        draft.loading = false;
        draft.error = action.payload;
        break;
    }
  });

export default companyReducer;
