import styled from 'styled-components';
import { Colors } from '../../styles/Theme';

const P = styled.p`
  color: ${Colors.textPrimary};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
`;

export default P;
