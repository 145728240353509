const isEmptyObject = (obj) =>
  obj === undefined ||
  obj === null ||
  (Object.keys(obj).length === 0 && obj.constructor === Object);
const isEmptyArray = (arr) => Array.isArray(arr) && arr.length <= 0;
const isEmptyString = (obj) =>
  obj === undefined ||
  ((typeof obj === 'string' || obj instanceof String) && obj === '');

const isLoading = (obj, isLoading) =>
  (isEmptyObject(obj) || isEmptyArray(obj) || isEmptyString(obj)) && isLoading;

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

const scrollToTop = (el) => {

  setTimeout(() => {
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;

    if (el) {
      const box = el.getBoundingClientRect();
      window.scrollTo({
        top: !!box.top ? box.top + scrollTop - clientTop - 64 : scrollTop - clientTop - 64,
        behavior: "smooth"
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }

  }, 300)
}

const toQuery = (obj) => {
  return (
    '?' +
    Object.keys(obj)
      .map((key) => key + '=' + (obj[key] || ''))
      .join('&')
  );
};

const round = (value) => {

  if (!value) return value;
  const valueNumber = parseInt(value);
  if(valueNumber < 1000) return value;
  const number = Math.floor(valueNumber/1000) * 1000;
  return Intl.NumberFormat('en-EN').format(number)
}

const monthOptionData = (monthOption) => {
  if(monthOption && monthOption === '3months') {
    return ['Planner 3 months', 'Achiever 3 months', 'Dreamer 3 months']
  }

  return ['Planner', 'Achiever', 'Dreamer']
}

const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const setCookie = (name, value, period = 0) => {
  if(period) {
    let date = new Date(Date.now() + (60*60*24*1000));
    date = date.toUTCString();
    document.cookie = `${name}=${value}; path=/; expires=${date}`;
  } else {
    document.cookie = `${name}=${value}; path=/;`;
  }
}

const clearDesc = (el, stats) => {
  return {
    type: el.type,
    text: el.text.replace(
      "{{application_total}}",
      stats.application_total
    ).replace(
      "{{company_total}}",
      stats.company_total
    ).replace(
      "{{interview_experience_total}}",
      stats.interview_experience_total
    ).replace(
      "{{interview_experience_questions_data_count}}",
      stats.interview_experience_questions_data_count
    ).replace(
      "{{company_total}}",
      stats.company_total
    ).replace(
      "{{application_question_count}}",
      stats.company_total
    )
  };
}

const createNewListContent = (content, type) => {
  return content.map(item => {
    return {
      type,
      text: item
    }
  })
}

export {
  isEmptyObject,
  isEmptyArray,
  isEmptyString,
  isLoading,
  capitalizeFirstLetter,
  scrollToTop,
  toQuery,
  round,
  monthOptionData,
  getCookie,
  setCookie,
  clearDesc,
  createNewListContent,
};
