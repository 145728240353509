import { DEFAULT_KEY } from 'redux-cache';

const defaultAccessStrategy = (state, cacheKey) => {
  return state && state[cacheKey];
};

export const checkCacheValid = (stateDomain, args = {}) => {
  const {
    cacheKey = DEFAULT_KEY,
    accessStrategy = defaultAccessStrategy,
  } = args;

  const cacheUntil = accessStrategy(stateDomain, cacheKey);

  const currentTime = Date.now();

  return !!(cacheUntil > currentTime);
};

export default checkCacheValid;
