import { css } from 'styled-components';

const buttonStyles = css`
    outline: none !important;

    height: ${(props) => props.height || '50px'};
    // line-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 40px;
    border-radius: ${(props) => props.radius || '68px'};
    margin-top: ${(props) => props.marginTop || '10px'};
    margin-left: ${(props) => props.marginLeft || 'unset'};
    margin-bottom: ${(props) => props.marginBottom || '0px'};

    border: 1px solid #61a9f6;
    //transition: 0.2s ease;
    // display: inline-block;
    transition: all 0.2s ease 0s;
    cursor: ${(props) => (props.disabled && 'not-allowed') || 'pointer'};
    text-align: center;
    background-color: white;
    text-decoration: none;
    min-width: fit-content;

  ${(props) =>
          props.width !== undefined &&
          css`
          width: ${(props) => props.width || ''};
          min-width: auto;
      `}


    ${(props) =>
      !props.disabled &&
      css`
        &:hover {

          svg, img {
            right: 14px;
          }}

          text-decoration: none;
          ${(props) =>
            props.shadow &&
            css`
              box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
                0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.3);
            `}
        }
      `}


    color: #589ce8;

    ${(props) =>
      props.color === 'orange' &&
      css`
        // background: -webkit-gradient(linear, left top, left bottom, from(#ffa421), to(#fd7b49));
        // background: -o-linear-gradient(right, #ffa421, #fd7b49);
        // background: linear-gradient(#ffa421, #fd7b49);

        background: ${(props) =>
          (props.outline && '#fff') ||
          'linear-gradient(126.54deg, #FFA531 13.19%, #FE7D4A 72.85%)'};
        border-color: #ff9e27;
        color: ${(props) => (props.outline && '#ff9e27') || '#fff'};
      `}

       ${(props) =>
         props.color === 'red' &&
         css`
           // background: -webkit-gradient(linear, left top, left bottom, from(#ffa421), to(#fd7b49));
           // background: -o-linear-gradient(right, #ffa421, #fd7b49);
           // background: linear-gradient(#ffa421, #fd7b49);
           background: linear-gradient(
             165.52deg,
             #ff5a5a 13.19%,
             #ff5a5a 72.85%
           );
           border-color: #ff5a5a;
           color: #fff;
         `}


          ${(props) =>
            props.color === 'green' &&
            css`
              // background: -webkit-gradient(linear, left top, left bottom, from(#ffa421), to(#fd7b49));
              // background: -o-linear-gradient(right, #ffa421, #fd7b49);
              // background: linear-gradient(#ffa421, #fd7b49);

              background: ${(props) =>
                (props.outline && '#fff') ||
                'linear-gradient(165.52deg, #55d480 13.19%, #55d480 72.85%)'};
              border-color: #55d480;
              color: ${(props) => (props.outline && '#55d480') || '#fff'};

              &:hover {
                color: ${(props) => (props.outline && '#55d480') || '#fff'};
                background-color: ${(props) =>
                  (props.outline && '#fff') ||
                  'linear-gradient(165.52deg, #55d480 13.19%, #55d480 72.85%)'};
                border-color: #55d480;
            `}

    ${(props) =>
      props.color === 'purple' &&
      css`
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(#596bae),
          to(#5264a9)
        );
        background: -o-linear-gradient(right, #596bae, #5264a9);
        background: linear-gradient(#596bae, #5264a9);
        border-color: #5264a9;
        color: #fff;
      `}

    ${(props) =>
      props.color === 'blue' &&
      css`
        background-color: ${(props) => (props.outline && '#fff') || '#61a9f6'};
        border-color: #61a9f6;
        color: ${(props) => (props.outline && '#61a9f6') || '#fff'};
        &:hover {
          color: ${(props) => (props.outline && '#4798ed') || '#fff'};
          background-color: ${(props) =>
            (props.outline && '#fff') || '#4798ed'};
          border-color: #4798ed;
        }
      `}

    ${(props) =>
            props.color === 'cyanBlue' &&
            css`
        box-shadow: none;
        background-color: ${(props) => (props.outline && '#fff') || '#589CE8'};
        border-color: #589CE8;
        color: ${(props) => (props.outline && '#589CE8') || '#fff'};
        &:hover {
          color: ${(props) => (props.outline && '#4798ed') || '#fff'};
          background-color: ${(props) =>
                    (props.outline && '#fff') || '#4798ed'};
          border-color: #4798ed;
        }
      `}

    ${(props) =>
            props.color === 'tealishBlue' &&
            css`
        box-shadow: none;
        background-color: ${(props) => (props.outline && '#fff') || '#24285B'};
        border-color: #24285B;
        color: ${(props) => (props.outline && '#24285B') || '#fff'};
        &:hover {
          color: ${(props) => (props.outline && '#4798ed') || '#fff'};
          background-color: ${(props) =>
                    (props.outline && '#fff') || '#4798ed'};
          border-color: #4798ed;
        }
      `}

    ${(props) =>
            props.color === 'tealishBlueOutline' &&
            css`
        box-shadow: none;
        background-color: #fff;
        border-color: #24285B;
        color: #24285B;
        &:hover {
          color: ${(props) => (props.outline && '#4798ed') || '#fff'};
          background-color: ${(props) =>
                    (props.outline && '#fff') || '#4798ed'};
          border-color: #4798ed;
        }
      `}

    ${(props) =>
      props.color === 'darkBlueGrey' &&
      css`
        box-shadow: none;
        background-color: ${(props) => (props.outline && '#fff') || '#24285B'};
        border-color: #24285B;
        color: ${(props) => (props.outline && '#24285B') || '#fff'};
        &:hover {
          color: ${(props) => (props.outline && '#24285B') || '#fff'};
          background-color: ${(props) =>
        (props.outline && '#fff') || '#24285B'};
          border-color: #24285B;
        }
      `}

    ${(props) =>
      props.color === 'darkBlueGreyOutline' &&
      css`
        box-shadow: none;
        background-color: #fff;
        border-color: #24285B;
        color: #24285B;
        &:hover {
          color: ${(props) => (props.outline && '#24285B') || '#fff'};
          background-color: ${(props) =>
        (props.outline && '#fff') || '#24285B'};
          border-color: #24285B;
        }
      `}

    & > * {
      transition: all .3s;
    }
`;

export default buttonStyles;
