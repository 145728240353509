import styled from 'styled-components';

const ContentDescription = styled.p`
  line-height: 1.5;
  letter-spacing: normal;
  color: #989eaa;
  margin-bottom: 0.6em;

  b {
    font-size: 19px;
    color: #505e78;
  }
`;

export default ContentDescription;
