import React from 'react';
import styled from 'styled-components';
import X from "./X";
import Check from "./Check";

const Flex = styled.div`
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;

  ul {
    width: 50%;
  }
`;

const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

const LI = styled.li`
  padding: 0 5px;
  margin: 0;
  color: ${props => props.valid ? "#0f7276" : "#fc404d"};
`;

const Span = styled.span`
  padding-left: 5px;
`;


function PasswordErrors({
                          errors
                        }) {

  if (errors.valuePass) {
    return (
      <Flex>
        <UL>
          {errors.valuePass ? <LI valid={errors.length}>{ !errors.length ? <X /> : <Check /> }<Span>At least 8 characters.</Span></LI> : ''}
          {errors.valuePass ? <LI valid={errors.case}>{ !errors.case ? <X /> : <Check /> }<Span>Lowercase (a-z) and uppercase (A-Z)</Span></LI> : ''}
        </UL>
        <UL>
          {errors.valuePass ? <LI valid={errors.numberSymbol}>{ !errors.numberSymbol ? <X /> : <Check /> }<Span>At least one number (0 - 9) or a symbol</Span></LI> : ''}
          {/*{errors.valueConfirmPass ? <LI valid={errors.confirmation}>{ !errors.confirmation ? <X /> : <Check /> }<Span>Passwords must match</Span></LI> : ''}*/}
        </UL>
      </Flex>
    );
  } else if (errors.valueConfirmPass) {
    return (
      <Flex>
        <UL>
          {errors.valueConfirmPass ? <LI valid={errors.confirmation}>{ !errors.confirmation ? <X /> : <Check /> }<Span>Passwords must match</Span></LI> : ''}
        </UL>
      </Flex>
    );
  } else {
    return '';
  }


}

PasswordErrors.propTypes = {};

export default PasswordErrors;
