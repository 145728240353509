import styled, { css } from 'styled-components';
import celebrateLeftSvg from '../../images/celebrate-left.svg';
import celebrateRightSvg from '../../images/celebrate-right.svg';

const Title = styled.h1`
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 46px;
  color: #505e78;
  margin: 0;
  position: relative;
  padding: 0 15px;
  display: flex;

  ${(props) =>
    props.success &&
    css`

  &::before {
    content: '';
    position: absolute;
    left: -60px;
    display: inline-block;
    width: 100px;
    height: 100px;
    top: 50%;
    transform: translateY(-50%);
    background: url("${celebrateLeftSvg}") center center/cover no-repeat;
    @media all and (max-width: 767px) {
      width: 30px;
      height: 30px;
      left: -20px;

    }
  }

    &::after {
      content: '';
      position: absolute;
      right: -60px;
      display: inline-block;
      width: 100px;
      height: 100px;
      top: 50%;
      transform: translateY(-50%);
      background: url("${celebrateRightSvg}") center center/cover no-repeat;
      @media all and (max-width: 767px) {
          width: 30px;
          height: 30px;
          right: -20px;
      }
    }
  `}
`;

export default Title;
