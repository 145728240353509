import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { careerResources } from "../data/content";
import MobileDropDownItem from "./MobileDropDownItem";
import { MobileTextLink } from "./MobileDropDownWrapper";
import "../Nav.scoped.scss";
import cn from "classnames";
import MobileDropDownColumn from "./MobileDropDownColumn";

const Container = styled.div`
  display: none;
`;

const Raw = styled.div`
  padding-top: 14px;
`;

function MobileDropDown({ closeAll, ...props }) {
  const [activeTitle, setIsActiveTitle] = useState('');

  const menuHandler = (title) => {
    setIsActiveTitle(title);
  }

  useEffect(() => {
    if(closeAll) setIsActiveTitle('');
  }, [closeAll]);


  return (
    <Container className="dropdownChildren fade-in-top">
      <Raw>
        <MobileDropDownColumn title='Application Stage' data={careerResources.application} activeTitle={activeTitle} setActiveTitle={menuHandler} />
        <MobileDropDownColumn title='Interview Stage' data={careerResources.interview} activeTitle={activeTitle} setActiveTitle={menuHandler} />
        <MobileDropDownColumn title='Earn Money' data={careerResources.earnWith} activeTitle={activeTitle} setActiveTitle={menuHandler} />
      </Raw>
    </Container>
  );
}

MobileDropDown.propTypes = {};

export default MobileDropDown;
