/**
 *
 * Divider
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const Divider = styled.div`
  content: '';
  margin: 8px 0;
  display: block;
  width: ${(props) => props.width || '100%'};
  border-bottom: ${(props) => props.border || 'solid #e5ecff'};
  height: ${(props) => props.height || '0'};

  border-width: ${(props) => props.borderWidth || '1px'};
`;

Divider.propTypes = {};

export default Divider;
