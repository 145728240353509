/**
 *
 * Form
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputField from '../InputField';
import { studiesList } from '../../data/studiesList';
import P from '../P';
import Divider from '../Divider';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;
export const MiddleSection = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;
export const BottomSection = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
`;

export const SubTitleWrapper = styled.div`
  margin-top: 0.8em;
  margin-bottom: 0.2em;
  // text-align: center;
`;

export const SubTitle = styled.span`
  align-self: flex-start;
  color: #505f79;
  text-align: left;
  font-size: 1.3rem;
  line-height: 1.13rem;
  font-weight: 700;
  display: block;
  margin: 0 0 12px 0;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const SubDescription = styled(P)`
  padding: 0;
  margin-top: 0;
  font-weight: 500;
  font-size: 1.05rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;
export const SectionListWrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  padding: 0 20px;
`;

export const FormGroup = styled.div`
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
`;

const Section = ({
  className,
  list,
  form,
  errors,
  onFormChange,
  children,
  topSection,
  bottomSection,
}) => {
  if (!list || list.length <= 0) {
    return null;
  }
  return (
    <SectionListWrapper className={className}>
      {topSection}
      {list.map((section) => (
        <FormGroup>
          {(section.title || section.description) && (
            <SubTitleWrapper>
              <SubTitle>{section.title}</SubTitle>
              <SubDescription>{section.description}</SubDescription>
              <Divider width="2px" />
            </SubTitleWrapper>
          )}

          {section.fields && (
            <div>
              {section.fields.map((formItem) => (
                <InputField
                  {...formItem}
                  form={form}
                  errors={errors}
                  onChange={onFormChange}
                />
              ))}
            </div>
          )}
        </FormGroup>
      ))}

      {bottomSection}
    </SectionListWrapper>
  );
};

const LeftSection = styled(Section)``;
const CenterSection = styled(Section)`
  flex-grow: 4;
`;
const RightSection = styled(Section)`
  flex-grow: 0;
`;

function Form({
  style = {},
  className,
  form,
  errors,
  onFormChange,
  handleSubmit,
  children,
  leftSectionList,
  centerSectionList,
  rightSectionList,
  CenterSectionBottom = () => null,
  BottomSectionContent = () => null,
  BottomSectionTop = () => null,
  CenterSectionTop = () => null,
}) {
  return (
    <FormWrapper
      className={className}
      style={style.formWrapper}
      onSubmit={handleSubmit}
    >
      <MiddleSection>
        <LeftSection
          list={leftSectionList}
          form={form}
          errors={errors}
          onFormChange={onFormChange}
        />
        <CenterSection
          list={centerSectionList}
          form={form}
          errors={errors}
          onFormChange={onFormChange}
          topSection={<CenterSectionTop />}
          bottomSection={<CenterSectionBottom />}
        />
        <RightSection
          list={rightSectionList}
          form={form}
          errors={errors}
          onFormChange={onFormChange}
        />
      </MiddleSection>
      <BottomSection>
        <BottomSectionContent />
      </BottomSection>
    </FormWrapper>
  );
}

Form.propTypes = {};

export default Form;
