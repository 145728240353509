/*
 *
 * Stats reducer
 *
 */
import produce from 'immer';
import {
  DEFAULT_ACTION,
  RESET_STATE,
  // plopConstImport
  STATS_GET_ACTION,
  STATS_GET_SUCCESS_ACTION,
  STATS_GET_ERROR_ACTION,
  QWE,
  QWE_S,
  QWE_F, GET_USER_SUBSCRIPTION_SUCCESS_ACTION,
} from './constants';

export const initialState = {
  get: {},
  getLoading: true,
  getError: {},
  userSubscription: [],
};

/* eslint-disable default-case, no-param-reassign */
const statsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      case DEFAULT_ACTION:
        break;
      //plopCasesImport
      case STATS_GET_ACTION:
        draft.getLoading = true;
        draft.getError = {};
        break;

      case STATS_GET_SUCCESS_ACTION:
        draft.get = action.payload;
        draft.getLoading = false;
        draft.getError = {};
        break;

      case STATS_GET_ERROR_ACTION:
        draft.getLoading = false;
        draft.getError = action.payload;
        break;
      case GET_USER_SUBSCRIPTION_SUCCESS_ACTION:
        draft.userSubscription = action.payload;
        break;
    }
  });

export default statsReducer;
