/*
 *
 * User reducer
 *
 */
import produce from 'immer';
import {
  GET_CURRENT_COUNTRY_ACTION,
  RESET_STATE,
  DEFAULT_ACTION,
  GET_CURRENT_COUNTRY_SUCCESS_ACTION
} from './constants';

export const initialState = {
  country: {
    country_code: "GB",
    country_name: "United Kingdom",
    code: "GBP"
  }
};

/* eslint-disable default-case, no-param-reassign */
const userReducer = (state = initialState, action) =>
  // eslint-disable-next-line consistent-return
  produce(state, (draft) => {
    switch (action.type) {
      case RESET_STATE:
        return initialState;
      case DEFAULT_ACTION:
      case GET_CURRENT_COUNTRY_ACTION:
        break;
      case GET_CURRENT_COUNTRY_SUCCESS_ACTION:
        draft.country = {...action.payload};
        break;
    }
  });

export default userReducer;
