/*
 *
 * SignIn actions
 *
 */

import {
  DEFAULT_ACTION,
  RESET_STATE,
  SIGNIN_FORM_SUBMIT_ACTION,
  SIGNIN_FORM_SUBMIT_ERROR_ACTION,
  SIGNIN_FORM_SUBMIT_SUCCESS_ACTION, SIGNIN_FORM_SUBMIT_SUCCESS_ACTION_WITH_UPLOAD,
  SIGNIN_FORM_UPDATE_ACTION,
  SIGNIN_SOCIAL_SUBMIT_ACTION,
  SIGNIN_SOCIAL_UPDATE_ACTION
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export function resetStateAction() {
  return {
    type: RESET_STATE,
  };
}

export function signinFormUpdateAction(payload) {
  return {
    type: SIGNIN_FORM_UPDATE_ACTION,
    payload,
  };
}
export function signinFormSubmitAction(payload) {
  return {
    type: SIGNIN_FORM_SUBMIT_ACTION,
    payload,
  };
}
export function signinFormSubmitSuccessAction(payload) {
  return {
    type: SIGNIN_FORM_SUBMIT_SUCCESS_ACTION,
    payload,
  };
}
export function signinFormSubmitSuccessActionWithUpload(payload) {
  return {
    type: SIGNIN_FORM_SUBMIT_SUCCESS_ACTION_WITH_UPLOAD,
    payload,
  };
}
export function signinFormSubmitErrorAction(payload) {
  return {
    type: SIGNIN_FORM_SUBMIT_ERROR_ACTION,
    payload,
  };
}
export function signinSocialUpdateAction(payload) {
  return {
    type: SIGNIN_SOCIAL_UPDATE_ACTION,
    payload,
  };
}
export function signinSocialSubmitAction(payload) {
  return {
    type: SIGNIN_SOCIAL_SUBMIT_ACTION,
    payload,
  };
}
