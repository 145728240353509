import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useQuery = () => {
  const [searchParams, setSearchParams] = useState(new URLSearchParams());
  const search = useLocation().search;
  useEffect(() => {
    setSearchParams(new URLSearchParams(search));
  }, [search]);

  return searchParams;
};

export default useQuery;
