import React from "react";
import styled, {css}  from "styled-components";
import DropDownItem from "./DropDownItem";
import { careerResources } from "../data/content";

const Container = styled.div`
  width: 1200px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 4px 0 rgba(67, 85, 166, 0.15);
  background: #FFF;
  position: absolute;
  left: 0;
  top: 100%;
  display: none;
  z-index: 1;
  font-family: Inter, sans-serif;
  white-space: normal;
  will-change: transform,opacity;

  &:hover {
    cursor: auto;
  }

  @media screen and (max-width: 1441px) {
    width: 1164px;
  }
`

const Raw = styled.div`
  width: 100%;
  display: flex;
`;
const Column = styled.div`
  width: 33.3333%;
  padding: 40px 20px;
  background: #fff;

  @media screen and (max-width: 1441px) {
    padding: 20px 10px;
  }

  ${(props) =>
    !!props.position && props.position === '1' &&
    css`
      background: #374266;
      border-radius: 0 0 0 8px;

      @media screen and (max-width: 1441px) {
        width: 34.36%;
      }

    `}

  ${(props) =>
    !!props.position && props.position === '2' &&
    css`
      background: #F3F6F9;

      @media screen and (max-width: 1441px) {
        width: 34.36%;
      }
    `}

  ${(props) =>
    !!props.position && props.position === '3' &&
    css`
      border-radius: 0 0 8px 0;

      @media screen and (max-width: 1441px) {
        width: 31.27%;
      }
    `}
`;

const ColumnTitle = styled.h3`
  margin: 0 0 8px 0;
  padding: 0 20px;
  color: #68A3FD;
  font-feature-settings: 'liga' off;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 215.385% */
  text-transform: uppercase;

  @media screen and (max-width: 1441px) {
    padding: 0 20px;
    font-size: 12px;
  }
`;

function DropDown({...props}) {
  return (
    <Container className='dropdownChildren fade-in-bottom'>
      <Raw>
        <Column position="1">
          <ColumnTitle>Application Stage</ColumnTitle>
          <div>
            {careerResources.application.map((item, index) => <DropDownItem key={index} content={item} styleType="light"/>)}
          </div>
        </Column>
        <Column position="2">
          <ColumnTitle>Interview Stage</ColumnTitle>
          <div>
            {careerResources.interview.map((item, index) => <DropDownItem key={index} content={item} />)}
          </div>
        </Column>
        <Column position="3">
          <ColumnTitle>EARN WITH CONGRAPPS</ColumnTitle>
          <div>
            {careerResources.earnWith.map((item, index) => <DropDownItem key={index} content={item} />)}
          </div>
        </Column>
      </Raw>
    </Container>
  );
}

DropDown.propTypes = {};

export default DropDown;
