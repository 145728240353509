/**
 *
 * ApplicationView
 *
 */

import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function LoggedInRoute({ component: Component, ...rest }) {
  const storageItem = localStorage.getItem(process.env.STORAGE_KEY);
  const storageData = JSON.parse(storageItem);
  const token =
    (storageData && storageData.global && storageData.global.token) || null;
  const isAuth = token !== null;

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/my-account',
            }}
          />
        )
      }
    />
  );
}
