import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';
import {makeSelectCompanyList} from '../Company/selectors';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import './SearchForm.scoped.scss';
import search from '../../images/search1.svg';
import companySaga from '../Company/saga';
import companyReducer from '../Company/reducer';
import {useInjectReducer} from 'utils/injectReducer';
import {useInjectSaga} from 'utils/injectSaga';
import {getCompanyListAction} from "../Company/actions";

export function SearchForm({dispatch, applicationList}) {

  useInjectReducer({key: 'company', reducer: companyReducer});
  useInjectSaga({key: 'company', saga: companySaga});

   const [inputValue, setInputValue] = useState('');
   const [searchListLength, setSearchListLength] = useState(4);
   let searchList=[];
   const handleSearch = (e) => {
      setInputValue(e.target.value);
      if(searchListLength > 4) {
        setSearchListLength(4);
      }
   }

   const searchCompany = (str, val) => {
     let result = str.split(' ');
     let isCompany = result.find(el => el.startsWith(val) ? true : false);
     return isCompany ? true : false;
   }

/*    const filterListCompany = (val) => {
     searchCompany(val)
     let searhCompany = applicationList.filter(item => item.title.toLowerCase().includes(val.toLowerCase()));
     return searhCompany;
   }
 */
   const filterListCompany = (val) => {
     console.log("APP list", applicationList)
     return applicationList.filter(item => searchCompany(item.title.toLowerCase(), val.toLowerCase()));
  }

   if(inputValue) {
     searchList = filterListCompany(inputValue);
   }

   useEffect(() => {
     dispatch(getCompanyListAction());
     window.addEventListener('click', function(e){
       if (!document.getElementById('search-form').contains(e.target)){
         setInputValue('')
       }
     });
   }, [])


    return (
      <div className="search-form" id={'search-form'}>
        <img src={search} alt="🔍"/>
        <input
            className={`${inputValue ? 'active-input' : ''}`}
            type="text"
            value={inputValue}
            onChange={handleSearch}
            placeholder="Search for a company"
        />
       {inputValue && searchList.length ? <div className="modal">
          <div>
            {searchList.slice(0, searchListLength).map(item => <p>
              <Link to={`/company/${item.slug}`}>{item.title}</Link>
            </p>)}
            {/* <p onClick={() => setSearchListLength(searchList.length)}>
             {searchListLength <= 4 && searchList.length > 4 ? "View all..." : ""}
            </p> */}
            <p>
              <Link to={'/company/list/'}>View all...</Link>
            </p>
          </div>
        </div> : ''}
      </div>
    )
}

SearchForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  applicationList: makeSelectCompanyList(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SearchForm);
