import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MobileDropDownItem from "./MobileDropDownItem";
import { MobileTextLink } from "./MobileDropDownWrapper";
import "../Nav.scoped.scss";
import cn from "classnames";

const Column = styled.div`
  padding-left: 10px;

  &:last-child {
    button {
        margin-bottom: 0;

    }

    .show {
        padding-top: 14px;
    }
  }
`;

const ColumnContent = styled.div`
  display: none;
  padding-left: 10px;

  &.show {
    display: block;
  }
`;

const ColumnTitle = styled.button`
  ${MobileTextLink};
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
  margin-bottom: 14px;
  width: 100%;
  text-align: left;
`;

function MobileDropDownColumn({ title, data, activeTitle, setActiveTitle, ...props }) {
  const [isActive, setIsActive] = useState(false);

  const menuHandler = () => {
    setIsActive(!isActive);
    if(setActiveTitle !== undefined) {
      setActiveTitle(title);
    }
  }

  useEffect(() => {
    setIsActive(activeTitle === title)
  }, [activeTitle]);


  return (
    <Column>
      <ColumnTitle className={cn('arrow-icon', {'arrow-icon-up': isActive})} onClick={menuHandler}>{title}</ColumnTitle>
      <ColumnContent  className={cn({'show fade-in-top': isActive})}>
        {data.map((item, index) => <MobileDropDownItem key={index} content={item} />)}
      </ColumnContent>
    </Column>
  );
}

MobileDropDownColumn.propTypes = {};

export default MobileDropDownColumn;
