import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the user state domain
 */

const selectUserDomain = (state) => state.user || initialState;

/**
 * Other specific selectors
 */

// plopImport

/**
 * Default selector used by User
 */

const makeSelectUser = () =>
  createSelector(selectUserDomain, (substate) => substate.current);

const makeSelectUserLoading = () =>
  createSelector(selectUserDomain, (substate) => substate.loading);

const makeSelectUserWallet = () =>
  createSelector(selectUserDomain, (substate) => substate.walletList);

const makeSelectUserWalletLoading = () =>
  createSelector(selectUserDomain, (substate) => substate.walletListLoading);

const makeSelectUserTypeProduct = () =>
  createSelector(selectUserDomain, (substate) => substate.typeProduct);

const makeSelectDeleteUserWalletItem = () =>
  createSelector(selectUserDomain, (substate) => substate.deleteWalletItem);

const makeSelectDeleteUserWalletItemLoading = () =>
  createSelector(
    selectUserDomain,
    (substate) => substate.deleteWalletItemLoading,
  );

const makeSelectFinalForm = () =>
  createSelector(selectUserDomain, (substate) => substate.finalForm);

const makeSelectFinalLoading = () =>
  createSelector(selectUserDomain, (substate) => substate.finalLoading);

const makeSelectFinalError = () =>
  createSelector(selectUserDomain, (substate) => substate.finalError);

const makeSelectFinalFormLinkRedirect = () =>
  createSelector(selectUserDomain, (substate) => substate.linkRedirect);

const makeSelectUserBank = () =>
  createSelector(selectUserDomain, (substate) => substate.bankList);

const makeSelectUserBankLoading = () =>
  createSelector(selectUserDomain, (substate) => substate.bankListLoading);

const makeSelectUserBankError = () =>
  createSelector(selectUserDomain, (substate) => substate.setBankListError);

const makeSelectUserCashOut = () =>
  createSelector(selectUserDomain, (substate) => substate.cashOutList);

export {
  makeSelectUser,
  makeSelectUserLoading,
  makeSelectUserWallet,
  makeSelectUserWalletLoading,
  makeSelectUserTypeProduct,
  makeSelectDeleteUserWalletItem,
  makeSelectDeleteUserWalletItemLoading,
  makeSelectFinalForm,
  makeSelectFinalLoading,
  makeSelectFinalError,
  makeSelectFinalFormLinkRedirect,
  makeSelectUserBank,
  makeSelectUserBankLoading,
  makeSelectUserBankError,
  makeSelectUserCashOut
};
export default selectUserDomain;
