import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { makeSelectUserToken } from '../App/selectors';
import { requestJSON } from '../../utils/request';
import {
  DEFAULT_ACTION,
  GET_USER_SUBSCRIPTION_ACTION,
  // plopConstImport
  STATS_GET_ACTION,
} from './constants';

import {
  defaultAction,
  // plopActionImport
  statsGetSuccessAction,
  statsGetErrorAction,
  getUserSubscriptionSuccessAction,
  getUserSubscriptionErrorAction,
} from './actions';

// plopSagaExports
export function* statsGet(action) {
  const requestURL = `stats/count/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, null, 'GET');
    yield put(statsGetSuccessAction(result));
  } catch (err) {
    console.log('There was an error: ', err);
    yield put(statsGetErrorAction(err));
  }
}

export function* getUserSubscription() {
  const requestURL = `subscription/access/`;
  const token = yield select(makeSelectUserToken());

  if(!token) {
    return;
  }

  try {
    const data = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(getUserSubscriptionSuccessAction(data));
  } catch (err) {
    yield put(getUserSubscriptionErrorAction(err));
  }
}

// Individual exports for testing
export default function* statsSaga() {
  // plopDefaultSagaExports
  yield takeLatest(STATS_GET_ACTION, statsGet);
  yield takeLatest(GET_USER_SUBSCRIPTION_ACTION, getUserSubscription);
}
