/*
 *
 * SignUp actions
 *
 */

import {
  DEFAULT_ACTION,
  RESET_STATE,
  // plopConstImport
  SIGN_UP_VALIDATE_REFERRAL_CODE_ACTION,
  SIGN_UP_VALIDATE_REFERRAL_CODE_SUCCESS_ACTION,
  SIGN_UP_VALIDATE_REFERRAL_CODE_ERROR_ACTION,
  SIGNUP_FORM_SUBMIT_ACTION,
  SIGNUP_FORM_SUBMIT_ERROR_ACTION,
  SIGNUP_FORM_SUBMIT_SUCCESS_ACTION,
  SIGNUP_FORM_UPDATE_ACTION,
  SIGNUP_SOCIAL_UPDATE_ACTION,
  SIGNUP_SOCIAL_SUBMIT_ACTION
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function resetStateAction() {
  return {
    type: RESET_STATE,
  };
}

export function signupFormUpdateAction(payload) {
  return {
    type: SIGNUP_FORM_UPDATE_ACTION,
    payload,
  };
}

export function signupFormSubmitAction(payload) {
  return {
    type: SIGNUP_FORM_SUBMIT_ACTION,
    payload,
  };
}

export function signupFormSubmitSuccessAction(payload) {
  return {
    type: SIGNUP_FORM_SUBMIT_SUCCESS_ACTION,
    payload,
  };
}

export function signupFormSubmitErrorAction(payload) {
  return {
    type: SIGNUP_FORM_SUBMIT_ERROR_ACTION,
    payload,
  };
}

export function signUpValidateReferralCodeAction(payload) {
  return {
    type: SIGN_UP_VALIDATE_REFERRAL_CODE_ACTION,
    payload,
  };
}

export function signUpValidateReferralCodeSuccessAction(payload) {
  return {
    type: SIGN_UP_VALIDATE_REFERRAL_CODE_SUCCESS_ACTION,
    payload,
  };
}

export function signUpValidateReferralCodeErrorAction(payload) {
  return {
    type: SIGN_UP_VALIDATE_REFERRAL_CODE_ERROR_ACTION,
    payload,
  };
}

export function signupSocialUpdateAction(payload) {
  return {
    type: SIGNUP_SOCIAL_UPDATE_ACTION,
    payload,
  };
}
export function signupSocialSubmitAction(payload) {
  return {
    type: SIGNUP_SOCIAL_SUBMIT_ACTION,
    payload,
  };
}
