
import React from 'react';
import {Route} from 'react-router-dom';

const BlogRoute = () => {
	return (
		<>
			<Route path='/blog/category/1/post/10' component={() => {
				window.location.href = 'https://congrapps.com/blog/which-law-firms-recruit-on-a-rolling-basis-2020-21';
				return null;
			}}/>

			<Route path='/blog/category/1/post/11' component={() => {
				window.location.href = 'https://congrapps.com/blog/which-law-firms-recruit-on-a-rolling-basis-2020-21';
				return null;
			}}/>

			<Route path='/blog/category/1/post/12' component={() => {
				window.location.href = 'https://congrapps.com/blog/winter-vacation-schemes-2021';
				return null;
			}}/>
			<Route path='/blog/category/1/post/13' component={() => {
				window.location.href = 'https://congrapps.com/blog/legal-career-tips';
				return null;
			}}/>
			<Route path='/blog/category/1/post/16' component={() => {
				window.location.href = 'https://congrapps.com/blog/winter-vacation-schemes-2021';
				return null;
			}}/>
			<Route path='/blog/category/1/post/17' component={() => {
				window.location.href = 'https://congrapps.com/blog/how-much-do-solicitors-make';
				return null;
			}}/>
			<Route path='/blog/category/1/post/18' component={() => {
				window.location.href = 'https://congrapps.com/blog/psychometric-tests-guide';
				return null;
			}}/>
			<Route path='/blog/category/1/post/19' component={() => {
				window.location.href = 'https://congrapps.com/blog/legal-practice-course-tips';
				return null;
			}}/>
			<Route path='/blog/category/1/post/2' component={() => {
				window.location.href = 'https://congrapps.com/blog/vacation-scheme-or-training-contract';
				return null;
			}}/>
			<Route path='/blog/category/1/post/20' component={() => {
				window.location.href = 'https://congrapps.com/blog/video-interviews-the-new-normal-for-job-interviews';
				return null;
			}}/>
			<Route path='/blog/category/1/post/21' component={() => {
				window.location.href = 'https://congrapps.com/blog/demystifying-sqe-the-new-route-to-qualification';
				return null;
			}}/>
			<Route path='/blog/category/1/post/22' component={() => {
				window.location.href = 'https://congrapps.com/blog/group-work-tips';
				return null;
			}}/>
			<Route path='/blog/category/1/post/23' component={() => {
				window.location.href = 'https://congrapps.com/blog/difference-between-solicitor-and-a-barrister';
				return null;
			}}/>
			<Route path='/blog/category/1/post/24' component={() => {
				window.location.href = 'https://congrapps.com/blog/personal-branding-for-lawyers-law-students';
				return null;
			}}/>
			<Route path='/blog/category/1/post/25' component={() => {
				window.location.href = 'https://congrapps.com/blog/how-to-stand-out-on-a-vacation-scheme';
				return null;
			}}/>
			<Route path='/blog/category/1/post/26' component={() => {
				window.location.href = 'https://congrapps.com/blog/what-to-expect-on-a-vacation-scheme';
				return null;
			}}/>
			<Route path='/blog/category/1/post/27' component={() => {
				window.location.href = 'https://congrapps.com/blog/which-extra-curricular-activities-to-take-while-studying';
				return null;
			}}/>
			<Route path='/blog/category/1/post/28' component={() => {
				window.location.href = 'https://congrapps.com/blog/how-to-revise-for-law-exams';
				return null;
			}}/>
			<Route path='/blog/category/1/post/29' component={() => {
				window.location.href = 'https://congrapps.com/blog/differences-between-us-and-magic-circle-law-firms';
				return null;
			}}/>
			<Route path='/blog/category/1/post/3' component={() => {
				window.location.href = 'https://congrapps.com/blog/when-to-quit-the-pursuit-of-a-vacation-scheme-or-training-contract';
				return null;
			}}/>
			<Route path='/blog/category/1/post/30' component={() => {
				window.location.href = 'https://congrapps.com/blog/which-law-societies-to-join';
				return null;
			}}/>
			<Route path='/blog/category/1/post/31' component={() => {
				window.location.href = 'https://congrapps.com/blog/how-to-get-a-first-at-university';
				return null;
			}}/>
			<Route path='/blog/category/1/post/32' component={() => {
				window.location.href = 'https://congrapps.com/blog/difference-between-city-law-firms-and-national-law-firms';
				return null;
			}}/>
			<Route path='/blog/category/1/post/33' component={() => {
				window.location.href = 'https://congrapps.com/blog/winter-vacation-scheme-deadlines-law-2022-2023-london';
				return null;
			}}/>
			<Route path='/blog/category/1/post/4' component={() => {
				window.location.href = 'https://congrapps.com/blog/financial-law-overview';
				return null;
			}}/>
			<Route path='/blog/category/2/post/1' component={() => {
				window.location.href = 'https://congrapps.com/blog/technology-valuations-the-key-things-to-remember-for-your-interview';
				return null;
			}}/>
			<Route path='/blog/category/2/post/5' component={() => {
				window.location.href = 'https://congrapps.com/blog/how-to-break-into-investment-banking-a-practical-and-honest-guide-for-london';
				return null;
			}}/>
			<Route path='/blog/category/2/post/6' component={() => {
				window.location.href = 'https://congrapps.com/blog/nvestment-banking-interview-questions';
				return null;
			}}/>
			<Route path='/blog/category/2/post/9' component={() => {
				window.location.href = 'https://congrapps.com/blog/are-cover-letters-necessary-in-investment-banking';
				return null;
			}}/>
			<Route path='/blog/category/5/post/14' component={() => {
				window.location.href = 'https://congrapps.com/blog/how-to-use-the-extra-curricular-questions-as-an-edge-on-applications';
				return null;
			}}/>
			<Route path='/blog/category/5/post/15' component={() => {
				window.location.href = 'https://congrapps.com/blog/how-to-write-the-perfect-cover-letter';
				return null;
			}}/>
			<Route path='/blog/category/5/post/7' component={() => {
				window.location.href = 'https://congrapps.com/blog/the-congrapps-guide';
				return null;
			}}/>
		</>
	);
};

export default BlogRoute;
