/*
 *
 * Company constants
 *
 */

export const DEFAULT_ACTION = 'app/Company/DEFAULT_ACTION';
export const RESET_STATE = 'app/Company/ResetState';

export const GET_COMPANY_LIST = 'app/Company/GetCompanyList';
export const GET_COMPANY_LIST_SUCCESS = 'app/Company/GetCompanyListSuccess';
export const GET_COMPANY_LIST_ERROR = 'app/Company/GetCompanyListError';

export const GET_COMPANY_TYPE_LIST = 'app/Company/GetCompanyTypeList';
export const GET_COMPANY_TYPE_LIST_SUCCESS =
  'app/Company/GetCompanyTypeListSuccess';
export const GET_COMPANY_TYPE_LIST_ERROR =
  'app/Company/GetCompanyTypeListError';

export const GET_COMPANY_INDUSTRY_LIST = 'app/Company/GetCompanyIndustryList';
export const GET_COMPANY_INDUSTRY_LIST_SUCCESS =
  'app/Company/GetCompanyIndustryListSuccess';
export const GET_COMPANY_INDUSTRY_LIST_ERROR =
  'app/Company/GetCompanyIndustryListError';

export const GET_COMPANY = 'app/Company/GetCompany';
export const GET_COMPANY_SUCCESS = 'app/Company/GetCompanySuccess';
export const GET_COMPANY_ERROR = 'app/Company/GetCompanyError';

export const COMPANY_GET_APPLICATION_LIST_ACTION =
  'app/Company/GetApplicationListAction';
export const COMPANY_GET_APPLICATION_LIST_SUCCESS_ACTION =
  'app/Company/GetApplicationListSuccessAction';
export const COMPANY_GET_APPLICATION_LIST_ERROR_ACTION =
  'app/Company/GetApplicationListErrorAction';

export const COMPANY_GET_LIBRARY_LIST_ACTION =
  'app/Company/GetLibraryListAction';
export const COMPANY_GET_LIBRARY_LIST_SUCCESS_ACTION =
  'app/Company/GetLibraryListSuccessAction';
export const COMPANY_GET_LIBRARY_LIST_ERROR_ACTION =
  'app/Company/GetLibraryListErrorAction';

export const COMPANY_GET_POST_LIST_ACTION =
  'app/Company/GetPostListAction';
export const COMPANY_GET_POST_LIST_SUCCESS_ACTION =
  'app/Company/GetPostListSuccessAction';
export const COMPANY_GET_POST_LIST_ERROR_ACTION =
  'app/Company/GetPostListErrorAction';

export const COMPANY_GET_POST_BY_TAG_LIST_ACTION =
  'app/Company/GetPostByTagListAction';
export const COMPANY_GET_POST_BY_TAG_LIST_SUCCESS_ACTION =
  'app/Company/GetPostByTagListSuccessAction';
export const COMPANY_GET_POST_BY_TAG_LIST_ERROR_ACTION =
  'app/Company/GetPostByTagListErrorAction';

export const COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ACTION =
  'app/Company/GetInterviewExperienceListAction';
export const COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_SUCCESS_ACTION =
  'app/Company/GetInterviewExperienceListSuccessAction';
export const COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ERROR_ACTION =
  'app/Company/GetInterviewExperienceListErrorAction';

export const GET_COMPANY_LOCATION_LIST = 'app/Company/GetCompanyLocationList';
export const GET_COMPANY_LOCATION_LIST_SUCCESS =
  'app/Company/GetCompanyLocationListSuccess';
export const GET_COMPANY_LOCATION_LIST_ERROR =
  'app/Company/GetCompanyLocationListError';
