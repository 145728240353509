/*
 *
 * JobTrackerEditJobModal constants
 *
 */

export const DEFAULT_ACTION = 'app/JobTrackerEditJobModal/DEFAULT_ACTION';
export const RESET_STATE = 'app/JobTrackerEditJobModal/ResetState';

export const JOB_TRACKER_EDIT_JOB_MODAL_FORM_UPDATE_ACTION =
  'app/JobTrackerEditJobModal/FormUpdateAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_GET_JOB_ACTION =
  'app/JobTrackerEditJobModal/GetJobAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_GET_JOB_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/GetJobSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_GET_JOB_ERROR_ACTION =
  'app/JobTrackerEditJobModal/GetJobErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_FORM_SUBMIT_ACTION =
  'app/JobTrackerEditJobModal/FormSubmitAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_FORM_SUBMIT_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/FormSubmitSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_FORM_SUBMIT_ERROR_ACTION =
  'app/JobTrackerEditJobModal/FormSubmitErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_TASK_FORM_UPDATE_ACTION =
  'app/JobTrackerEditJobModal/TaskFormUpdateAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_NOTE_FORM_UPDATE_ACTION =
  'app/JobTrackerEditJobModal/NoteFormUpdateAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_DOCUMENT_FORM_UPDATE_ACTION =
  'app/JobTrackerEditJobModal/DocumentFormUpdateAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_CREATE_TASK_ACTION =
  'app/JobTrackerEditJobModal/CreateTaskAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_CREATE_TASK_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/CreateTaskSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_CREATE_TASK_ERROR_ACTION =
  'app/JobTrackerEditJobModal/CreateTaskErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_UPDATE_TASK_ACTION =
  'app/JobTrackerEditJobModal/UpdateTaskAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_UPDATE_TASK_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/UpdateTaskSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_UPDATE_TASK_ERROR_ACTION =
  'app/JobTrackerEditJobModal/UpdateTaskErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_DELETE_TASK_ACTION =
  'app/JobTrackerEditJobModal/DeleteTaskAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_DELETE_TASK_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/DeleteTaskSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_DELETE_TASK_ERROR_ACTION =
  'app/JobTrackerEditJobModal/DeleteTaskErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_CREATE_NOTE_ACTION =
  'app/JobTrackerEditJobModal/CreateNoteAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_CREATE_NOTE_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/CreateNoteSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_CREATE_NOTE_ERROR_ACTION =
  'app/JobTrackerEditJobModal/CreateNoteErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_UPDATE_NOTE_ACTION =
  'app/JobTrackerEditJobModal/UpdateNoteAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_UPDATE_NOTE_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/UpdateNoteSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_UPDATE_NOTE_ERROR_ACTION =
  'app/JobTrackerEditJobModal/UpdateNoteErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_DELETE_NOTE_ACTION =
  'app/JobTrackerEditJobModal/DeleteNoteAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_DELETE_NOTE_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/DeleteNoteSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_DELETE_NOTE_ERROR_ACTION =
  'app/JobTrackerEditJobModal/DeleteNoteErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_GET_TASK_LIST_ACTION =
  'app/JobTrackerEditJobModal/GetTaskListAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_GET_TASK_LIST_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/GetTaskListSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_GET_TASK_LIST_ERROR_ACTION =
  'app/JobTrackerEditJobModal/GetTaskListErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_GET_NOTE_LIST_ACTION =
  'app/JobTrackerEditJobModal/GetNoteListAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_GET_NOTE_LIST_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/GetNoteListSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_GET_NOTE_LIST_ERROR_ACTION =
  'app/JobTrackerEditJobModal/GetNoteListErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_CREATE_DOCUMENT_ACTION =
  'app/JobTrackerEditJobModal/CreateDocumentAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_CREATE_DOCUMENT_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/CreateDocumentSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_CREATE_DOCUMENT_ERROR_ACTION =
  'app/JobTrackerEditJobModal/CreateDocumentErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_DELETE_DOCUMENT_ACTION =
  'app/JobTrackerEditJobModal/DeleteDocumentAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_DELETE_DOCUMENT_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/DeleteDocumentSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_DELETE_DOCUMENT_ERROR_ACTION =
  'app/JobTrackerEditJobModal/DeleteDocumentErrorAction';

export const JOB_TRACKER_EDIT_JOB_MODAL_GET_DOCUMENT_LIST_ACTION =
  'app/JobTrackerEditJobModal/GetDocumentListAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_GET_DOCUMENT_LIST_SUCCESS_ACTION =
  'app/JobTrackerEditJobModal/GetDocumentListSuccessAction';
export const JOB_TRACKER_EDIT_JOB_MODAL_GET_DOCUMENT_LIST_ERROR_ACTION =
  'app/JobTrackerEditJobModal/GetDocumentListErrorAction';
