/*
 *
 * SignIn constants
 *
 */

export const DEFAULT_ACTION = 'app/SignIn/DEFAULT_ACTION';
export const RESET_STATE = 'app/SignIn/ResetState';
export const SIGNIN_FORM_UPDATE_ACTION = 'app/SignIn/FormUpdateAction';
export const SIGNIN_FORM_SUBMIT_ACTION = 'app/SignIn/FormSubmitAction';
export const SIGNIN_FORM_SUBMIT_SUCCESS_ACTION = 'app/SignIn/FormSubmitSuccessAction';
export const SIGNIN_FORM_SUBMIT_SUCCESS_ACTION_WITH_UPLOAD = 'app/SignIn/FormSubmitSuccessActionWithUpload';
export const SIGNIN_FORM_SUBMIT_ERROR_ACTION =
  'app/SignIn/FormSubmitErrorAction';
export const SIGNIN_SOCIAL_UPDATE_ACTION = 'app/SignIn/SocialUpdateAction';
export const SIGNIN_SOCIAL_SUBMIT_ACTION = 'app/SignIn/SocialSubmitAction';
