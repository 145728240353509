/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, {useEffect, useState} from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import HomePage from 'containers/HomePage/Loadable';
import HomePageNew from 'containers/HomePageNew/Loadable';
import SignUp from 'containers/SignUp/Loadable';
import ForgotPasswordPage from 'containers/ForgotPasswordPage/Loadable';
import ResetPasswordPage from 'containers/ResetPasswordPage/Loadable';
import SignOut from 'containers/SignOut/Loadable';
import SignIn from 'containers/SignIn/Loadable';
import ImpersonatePage from 'containers/ImpersonatePage/Loadable';
import MyAccount from 'containers/MyAccount/Loadable';
import BlogPostPage from 'containers/BlogPostPage/Loadable';
import CompanyView from 'containers/CompanyView/Loadable';
import CompanyList from 'containers/CompanyList/Loadable';
import ApplicationUploadPage from 'containers/ApplicationUploadPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import NewHowItWorks from 'containers/NewHowItWorks/Loadable';
import PricingPage from 'containers/PricingPage/Loadable';
import FaqPage from 'containers/FaqPage/Loadable';
import TermsAndConditions from 'containers/TermsAndConditions/Loadable';
import PrivacyPolicy from 'containers/PrivacyPolicy/Loadable';
import TrackerPage from 'containers/TrackerPage/Loadable';
import CheckoutPage from 'containers/Checkout/Loadable';
import AboutUs from 'containers/AboutUs/Loadable';
import Library from 'containers/Library/Loadable';
import LibraryResource from 'containers/LibraryResource/Loadable';
import SuccessPage from 'containers/Success/Loadable';
import SuccessPageApplicationReview from 'containers/SuccessApplicationReview/Loadable';
import ApplicationReview from 'containers/ApplicationReview/Loadable';
import InterviewSimulatorLanding from "containers/InterviewSimulatorLanding/Loadable";
import InterviewSimulatorSelector from "containers/InterviewSimulatorSelector/Loadable";
import PsychometricsTest from "containers/PsychometricsTest/Loadable";
import TestSimulator from "containers/TestSimulator/Loadable";

import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import tawkto from 'react-tawkto';
import GlobalStyle from '../../global-styles';
import PrivateRoute from './PrivateRoute';
import LoggedInRoute from './LoggedInRoute';
import saga from '../User/saga';
import geoSaga from '../Geo/saga';
import { useInjectSaga } from '../../utils/injectSaga';

import ScrollToTop from '../../components/ScrollToTop';
import CookiesPopup from "../../components/CookiesPopup";
import FinalisingAccount from "../../components/FinalisingAccount";

import BlogRoute from "./BlogRoute";
import ReactDOM from "react-dom";
import styled from 'styled-components';
import { makeSelectUser } from "../User/selectors";
import { getCookie } from "../../utils/utils";


const LoginContainer = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    input{
      height: 40px;
      padding-left: 10px;
      border: 1px solid gainsboro;
      border-radius: 4px;
    }

    button{
      height: 40px;
      border: none;
      margin-left: 10px;
      border-radius: 4px;
      padding: 0 15px;
      cursor: pointer;
    }
`

export function App({user}) {

  useInjectSaga({ key: 'user', saga });
  useInjectSaga({ key: 'geo', saga: geoSaga });

  // simple password protection

  const [isAuth, setIsAuth] = useState(false)
  const [password, setPassword] = useState('')

  useEffect(() => {



    const cookie = getCookie('_congrapps_password')
    if (cookie !== undefined) {
      setIsAuth(true)
    }
  }, [])

  const siteLogin = () => {
    if (password === '$1CUGrYV*7!6') {
      let date = new Date(Date.now() + (60*60*24*1000));
      date = date.toUTCString();
      document.cookie = "_congrapps_password=true; path=/; expires=" + date;
      setIsAuth(true)
    }
  }


  if (!isAuth && (window.location.hostname.indexOf('staging') !== -1 || window.location.hostname.indexOf('localhost') !== -1)) {
    return (
      <LoginContainer>
        <input type={'password'} value={password} onChange={(e) => setPassword(e.target.value)} placeholder={'Enter password...'} />
        <button onClick={siteLogin}>login</button>
      </LoginContainer>
    );
  }

  // simple password protection

  tawkto.init(process.env.TAWKTO_ID, () => {});

  return (
    <>

      <BlogRoute/>

      <ScrollToTop />
      <Switch>

        <Route exact path="/" component={HomePageNew} />

        {/* AUTH */}
        <LoggedInRoute exact path="/sign-in" component={SignIn} />
        <LoggedInRoute exact path="/sign-up" component={SignUp} />
        <LoggedInRoute
          exact
          path="/forgot-password"
          component={ForgotPasswordPage}
        />
        <LoggedInRoute
          exact
          path="/reset-password"
          component={ResetPasswordPage}
        />
        <PrivateRoute exact path="/logout" component={SignOut} />
        <PrivateRoute path="/my-account" component={MyAccount} />

        <Route path="/impersonate" component={ImpersonatePage} />
        <Route path="/how-it-works" component={NewHowItWorks} />
        {/* <Route path="/new-how-it-works" component={NewHowItWorks}/> */}
        <Route exact path="/tracker" component={TrackerPage} />
        <Route exact path="/about-us" component={AboutUs} />
        <PrivateRoute
          exact
          path="/checkout/:subscriptionTypeId"
          component={CheckoutPage}
        />
        <PrivateRoute exact path="/checkout" component={CheckoutPage} />
        {/* <Route exact path="/tracker/no-applications" component={Tracker} /> */}
        {/* <Route exact path="/tracker/one-application" component={Tracker} /> */}
        {/* <Route exact path="/tracker/two-applications" component={Tracker} /> */}
        <Route exact path="/pricing" component={PricingPage} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/faq" component={FaqPage} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/blog/:blogSlug"
          component={BlogPostPage}
        />
        {/*<PrivateRoute exact path="/congrapps-credit" component={KarmaPage} />*/}

        <Route exact path="/library/:resourceSlug" component={LibraryResource} />
        {/*<Route exact path="/library/page/:page" component={Library} />*/}
        <Route path="/library" component={Library} />

        <Route path="/company/list" component={CompanyList} />
        <Route path="/company/:companyId" component={CompanyView} />

        <Route path="/application-review" component={ApplicationReview} />

        <PrivateRoute exact path="/psychometrics-test/simulator" component={TestSimulator} />
        <Route path="/psychometrics-test" component={PsychometricsTest} />

        <PrivateRoute exact path="/interview-simulator/selector" component={InterviewSimulatorSelector} />
        <Route path="/interview-simulator" component={InterviewSimulatorLanding} />

        <PrivateRoute
          exact
          path="/success/application-review"
          component={SuccessPageApplicationReview}
        />


        <Route
          exact
          path="/application/upload"
          component={ApplicationUploadPage}
        />

        <PrivateRoute
          exact
          path="/success/:subscriptionTypeId"
          component={SuccessPage}
        />

        {/* <Route exact path="/forgot-password" component={ForgotPassword}/> */}
        {/* <Route */}
        {/* exact */}
        {/* path="/forgot-password/verify" */}
        {/* component={ForgotPasswordVerify} */}
        {/* /> */}
        {/* <Route exact path="/logout" component={Logout}/> */}

        {/* LEGAL PAGES */}
        {/* <Route exact path="/privacy-policy" component={TermsAndConditions}/> */}
        {/* <Route */}
        {/* exact */}
        {/* path="/terms-and-conditions" */}
        {/* component={TermsAndConditions} */}
        {/* /> */}
        <Redirect from="/law-firms" to="/company/list/legal" />
        <Redirect from="/register" to="/sign-up" />
        <Redirect from="/login" to="/sign-in" />
        <Redirect from="/upload-application" to="/application/upload" />
        <Redirect
          from="/successful-applications/2-cleary-successful-application/"
          to="/company/cleary-gottlieb-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/bclp-successful-application/"
          to="/company/bclp-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/dla-successful-application/"
          to="/company/dla-piper-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/past-application-baker-mckenzie/"
          to="/company/baker-mckenzie-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/uploaded-application-addleshaw-goddard-1/"
          to="/company/addleshaw-goddard-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/uploaded-application-latham-watkins-04/"
          to="/company/latham-and-watkins-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/uploaded-application-travers-smith-2/"
          to="/company/travers-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/shearman-sterling-7/"
          to="/company/shearman-and-sterling-vacation-scheme/applications"
        />
        <Redirect from="/company" to="/company/list/general" />
        <Redirect
          from="/successful-applications/01-arnold-porter-tc-application/"
          to="/company/arnold-and-porter-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/01-slaughter-may/"
          to="/company/slaughter-and-may-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/01-white-case-application/"
          to="/company/white-and-case-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/1-cleary-successful-application/"
          to="/company/cleary-gottlieb-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/1-clifford-chance/"
          to="/company/clifford-chance-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/1-sullivan-cromwell-successful-application/"
          to="/company/sullivan-and-cromwell-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/2-cleary-successful-application/"
          to="/company/cleary-gottlieb-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/allen-overy-2018-vac-scheme-application/"
          to="/company/allen-and-overy-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/application-sample-vacation-scheme-pinsent-masons/"
          to="/company/pinsent-masons-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/bird-bird-successful-application-01/"
          to="/company/bird-and-bird-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/gibson-dunn-successful-application/"
          to="/company/gibson-dunn-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/jones-day-successful-application-05/"
          to="/company/jones-day-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/latham-watkins-successful-application2/"
          to="/company/latham-and-watkins-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/linklaters-successful-application-2/"
          to="/company/linklaters-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/linklaters-successful-application/"
          to="/company/linklaters-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/macfarlanes-successful-application/"
          to="/company/macfarlanes-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/norton-rose-fulbright-successful-application-01/"
          to="/company/norton-rose-fulbright-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/pas-application-cms/"
          to="/company/cms-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/past-applicataddleshaw-goddard-vs-application/"
          to="/company/addleshaw-goddard-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/past-application-rsuccessful-sample-baker-mckenzie/"
          to="/company/baker-mckenzie-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/past-application-shearman-and-sterling-london/"
          to="/company/shearman-and-sterling-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/past-application-slaughter-and-may/"
          to="/company/slaughter-and-may-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/past-application-vacation-scheme-dentons-successful/"
          to="/company/dentons-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/penningtons-manches-uploaded-application-10-27-2019-0634/"
          to="/company/penningtons-manches-cooper-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/sample-application-clifford-chance-successful-vacation-scheme-questions/"
          to="/company/clifford-chance-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/sample-application-nortonrosefulbright-vacationscheme/"
          to="/company/norton-rose-fulbright-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/successful-application-allen-overy-sample/"
          to="/company/allen-and-overy-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/successful-application-mayer-brown-training-contract/"
          to="/company/mayer-brown-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/successful-application-osborne-clarke/"
          to="/company/osborne-clarke-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/successful-application-sample-baker-mckenzie/"
          to="/company/baker-mckenzie-training-contract/applications"
        />
        <Redirect
          from="/successful-applications/successful-application-sample-bird-and-bird/"
          to="/company/bird-and-bird-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/successful-application-travers-smith-vacation-scheme/"
          to="/company/travers-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/uploaded-application-norton-rose-fulbright-3/"
          to="/company/norton-rose-fulbright-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/uploaded-application-travers-smith-2/"
          to="/company/travers-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/successful-applications/weil-successful-application/"
          to="/company/weil-gotshal-and-manges-vacation-scheme/applications"
        />
        <Redirect
          from="/2019/09/23/vacation-scheme-or-training-contract-which-one-is-for-you/"
          to="/"
        />
        <Redirect
          from="/company/view/157/"
          to="/company/apollo-global-management-graduate-scheme/applications"
        />
         <Redirect
          from="/company/view/157/applications"
          to="/company/apollo-global-management-graduate-scheme/applications"
        />
         <Redirect
          from="/company/view/157/interview-experiences"
          to="/company/apollo-global-management-graduate-scheme/interview-experiences"
        />
         <Redirect
          from="/company/view/157/profile"
          to="/company/apollo-global-management-graduate-scheme/applications"
        />
         <Redirect
          from="/company/view/157/facts"
          to="/company/apollo-global-management-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/100"
          to="/company/de-shaw-internship/applications"
        />
         <Redirect
          from="/company/view/100/applications"
          to="/company/de-shaw-internship/applications"
        />
         <Redirect
          from="/company/view/100/interview-experiences"
          to="/company/de-shaw-internship/interview-experiences"
        />
         <Redirect
          from="/company/view/100/profile"
          to="/company/de-shaw-internship/applications"
        />
         <Redirect
          from="/company/view/100/facts"
          to="/company/de-shaw-internship/applications"
        />
        <Redirect
          from="/company/view/103"
          to="/company/fidelity-investments-summer-internship/applications"
        />
        <Redirect
          from="/company/view/103/applications"
          to="/company/fidelity-investments-summer-internship/applications"
        />
        <Redirect
          from="/company/view/103/interview-experiences"
          to="/company/fidelity-investments-summer-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/103/profile"
          to="/company/fidelity-investments-summer-internship/applications"
        />
        <Redirect
          from="/company/view/103/facts"
          to="/company/fidelity-investments-summer-internship/applications"
        />
        <Redirect
          from="/company/view/156"
          to="/company/illiquidx-internship/applications"
        />
        <Redirect
          from="/company/view/156/applications"
          to="/company/illiquidx-internship/applications"
        />
        <Redirect
          from="/company/view/156/interview-experiences"
          to="/company/illiquidx-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/156/profile"
          to="/company/illiquidx-internship/applications"
        />
        <Redirect
          from="/company/view/156/facts"
          to="/company/illiquidx-internship/applications"
        />
        <Redirect
          from="/company/view/173"
          to="/company/jane-street-internship/applications"
        />
        <Redirect
          from="/company/view/173/applications"
          to="/company/jane-street-internship/applications"
        />
        <Redirect
          from="/company/view/173/interview-experiences"
          to="/company/jane-street-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/173/profile"
          to="/company/jane-street-internship/applications"
        />
        <Redirect
          from="/company/view/173/facts"
          to="/company/jane-street-internship/applications"
        />
        <Redirect
          from="/company/view/172"
          to="/company/man-group-internship/applications"
        />
        <Redirect
          from="/company/view/172/applications"
          to="/company/man-group-internship/applications"
        />
        <Redirect
          from="/company/view/172/interview-experiences"
          to="/company/man-group-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/172/profile"
          to="/company/man-group-internship/applications"
        />
        <Redirect
          from="/company/view/172/facts"
          to="/company/man-group-internship/applications"
        />
        <Redirect
          from="/company/view/118"
          to="/company/m-g-investments-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/118/applications"
          to="/company/m-g-investments-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/118/interview-experiences"
          to="/company/m-g-investments-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/118/profile"
          to="/company/m-g-investments-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/118/facts"
          to="/company/m-g-investments-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/120"
          to="/company/redburn-summer-internship/applications"
        />
        <Redirect
          from="/company/view/120/applications"
          to="/company/redburn-summer-internship/applications"
        />
        <Redirect
          from="/company/view/120/interview-experiences"
          to="/company/redburn-summer-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/120/profile"
          to="/company/redburn-summer-internship/applications"
        />
        <Redirect
          from="/company/view/120/facts"
          to="/company/redburn-summer-internship/applicationss"
        />
        <Redirect
          from="/company/view/158"
          to="/company/schonfeld-careers/applications"
        />
        <Redirect
          from="/company/view/158/applications"
          to="/company/schonfeld-careers/applications"
        />
        <Redirect
          from="/company/view/158/interview-experiencess"
          to="/company/schonfeld-careers/interview-experiences"
        />
        <Redirect
          from="/company/view/158/profile"
          to="/company/schonfeld-careers/applications"
        />
        <Redirect
          from="/company/view/158/facts"
          to="/company/schonfeld-careers/applications"
        />
        <Redirect
          from="/company/view/124"
          to="/company/spx-capital-careers/applications"
        />
        <Redirect
          from="/company/view/124/applications"
          to="/company/spx-capital-careers/applications"
        />
        <Redirect
          from="/company/view/124/interview-experiences"
          to="/company/spx-capital-careers/interview-experiences"
        />
        <Redirect
          from="/company/view/124/profile"
          to="/company/spx-capital-careers/applications"
        />
        <Redirect
          from="/company/view/124/facts"
          to="/company/spx-capital-careers/applications"
        />
        <Redirect
          from="/company/view/176"
          to="/company/ardian-internship/applications"
        />
        <Redirect
          from="/company/view/176/applications"
          to="/company/ardian-internship/applications"
        />
        <Redirect
          from="/company/view/176/interview-experiences"
          to="/company/ardian-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/176/profile"
          to="/company/ardian-internship/applications"
        />
        <Redirect
          from="/company/view/176/facts"
          to="/company/ardian-internship/applications"
        />
        <Redirect
          from="/company/view/142"
          to="/company/global-founders-capital-internship/applications"
        />
        <Redirect
          from="/company/view/142/applications"
          to="/company/global-founders-capital-internship/applications"
        />
        <Redirect
          from="/company/view/142/interview-experiences"
          to="/company/global-founders-capital-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/142/profile"
          to="/company/global-founders-capital-internship/applications"
        />
        <Redirect
          from="/company/view/142/facts"
          to="/company/global-founders-capital-internship/applications"
        />
        <Redirect
          from="/company/view/177"
          to="/company/hg-capital-internship/applications"
        />
        <Redirect
          from="/company/view/177/applications"
          to="/company/hg-capital-internship/applications"
        />
        <Redirect
          from="/company/view/177/interview-experiences"
          to="/company/hg-capital-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/177/profile"
          to="/company/hg-capital-internship/applications"
        />
        <Redirect
          from="/company/view/177/facts"
          to="/company/hg-capital-internship/applications"
        />
        <Redirect
          from="/company/view/141"
          to="/company/nauta-capital-internship/applications"
        />
        <Redirect
          from="/company/view/141/applications"
          to="/company/nauta-capital-internship/applications"
        />
        <Redirect
          from="/company/view/141/interview-experiences"
          to="/company/nauta-capital-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/141/profile"
          to="/company/nauta-capital-internship/applications"
        />
        <Redirect
          from="/company/view/141/facts"
          to="/company/nauta-capital-internship/applications"
        />
        <Redirect
          from="/company/view/125"
          to="/company/terra-firma-internship/applications"
        />
        <Redirect
          from="/company/view/125/applications"
          to="/company/terra-firma-internship/applications"
        />
        <Redirect
          from="/company/view/125/interview-experiences"
          to="/company/terra-firma-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/125/profile"
          to="/company/terra-firma-internship/applications"
        />
        <Redirect
          from="/company/view/125/facts"
          to="/company/terra-firma-internship/applications"
        />
        <Redirect
          from="/company/view/132"
          to="/company/deloitte-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/132/applications"
          to="/company/deloitte-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/132/interview-experiences"
          to="/company/deloitte-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/132/profile"
          to="/company/deloitte-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/132/facts"
          to="/company/deloitte-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/128"
          to="/company/ernst-and-young-internship/applications"
        />
        <Redirect
          from="/company/view/128/applications"
          to="/company/ernst-and-young-internship/applications"
        />
        <Redirect
          from="/company/view/128/interview-experiences"
          to="/company/ernst-and-young-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/128/profile"
          to="/company/ernst-and-young-internship/applications"
        />
        <Redirect
          from="/company/view/128/facts"
          to="/company/ernst-and-young-internship/applications"
        />
        <Redirect
          from="/company/view/131"
          to="/company/grant-thornton-internship/applications"
        />
        <Redirect
          from="/company/view/131/applications"
          to="/company/grant-thornton-internship/applications"
        />
        <Redirect
          from="/company/view/131/interview-experiences"
          to="/company/grant-thornton-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/131/profile"
          to="/company/grant-thornton-internship/applications"
        />
        <Redirect
          from="/company/view/131/facts"
          to="/company/grant-thornton-internship/applications"
        />
        <Redirect
          from="/company/view/130"
          to="/company/kpmg-internship/applications"
        />
        <Redirect
          from="/company/view/130/applications"
          to="/company/kpmg-internship/applications"
        />
        <Redirect
          from="/company/view/130/interview-experiences"
          to="/company/kpmg-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/130/profile"
          to="/company/kpmg-internship/applications"
        />
        <Redirect
          from="/company/view/130/facts"
          to="/company/kpmg-internship/applications"
        />
        <Redirect
          from="/company/view/129"
          to="/company/pwc-internships/applications"
        />
        <Redirect
          from="/company/view/129/applications/"
          to="/company/pwc-internships/applications"
        />
        <Redirect
          from="/company/view/129/interview-experiences"
          to="/company/pwc-internships/interview-experiences"
        />
        <Redirect
          from="/company/view/129/profile"
          to="/company/pwc-internships/applications"
        />
        <Redirect
          from="/company/view/129/facts"
          to="/company/pwc-internships/applications"
        />
        <Redirect
          from="/company/view/61"
          to="/company/addleshaw-goddard-training-contract/applications"
        />
        <Redirect
          from="/company/view/61/applications"
          to="/company/addleshaw-goddard-training-contract/applications"
        />
        <Redirect
          from="/company/view/61/interview-experiences"
          to="/company/addleshaw-goddard-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/61/profile"
          to="/company/addleshaw-goddard-training-contract/applications"
        />
        <Redirect
          from="/company/view/61/facts"
          to="/company/addleshaw-goddard-training-contract/applications"
        />
        <Redirect
          from="/company/view/62"
          to="/company/akin-gump-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/62/applications"
          to="/company/akin-gump-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/62/interview-experiences"
          to="/company/akin-gump-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/62/profile"
          to="/company/akin-gump-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/62/facts"
          to="/company/akin-gump-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/155"
          to="/company/a-l-goodbody-internship/applications"
        />
        <Redirect
          from="/company/view/155/applications"
          to="/company/a-l-goodbody-internship/applications"
        />
        <Redirect
          from="/company/view/155/interview-experiences"
          to="/company/a-l-goodbody-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/155/profile"
          to="/company/a-l-goodbody-internship/applications"
        />
        <Redirect
          from="/company/view/155/facts"
          to="/company/a-l-goodbody-internship/applications"
        />
        <Redirect
          from="/company/view/11"
          to="/company/allen-and-overy-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/11/applications"
          to="/company/allen-and-overy-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/11/interview-experiences"
          to="/company/allen-and-overy-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/11/profile"
          to="/company/allen-and-overy-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/11/facts"
          to="/company/allen-and-overy-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/96"
          to="/company/arnold-and-porter-training-contract/applications"
        />
        <Redirect
          from="/company/view/96/applications"
          to="/company/arnold-and-porter-training-contract/applications"
        />
        <Redirect
          from="/company/view/96/interview-experiences"
          to="/company/arnold-and-porter-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/96/profile"
          to="/company/arnold-and-porter-training-contract/applications"
        />
        <Redirect
          from="/company/view/96/facts"
          to="/company/arnold-and-porter-training-contract/applications"
        />
        <Redirect
          from="/company/view/63"
          to="/company/ashfords-training-contract/applications"
        />
        <Redirect
          from="/company/view/63/applications"
          to="/company/ashfords-training-contract/applications"
        />
        <Redirect
          from="/company/view/63/interview-experiences"
          to="/company/ashfords-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/63/profile"
          to="/company/ashfords-training-contract/applications"
        />
        <Redirect
          from="/company/view/63/facts"
          to="/company/ashfords-training-contract/applications"
        />
        <Redirect
          from="/company/view/59"
          to="/company/ashurst-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/59/applications"
          to="/company/ashurst-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/59/interview-experiences"
          to="/company/ashurst-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/59/profile"
          to="/company/ashurst-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/59/facts"
          to="/company/ashurst-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/49"
          to="/company/baker-mckenzie-training-contract/applications"
        />
        <Redirect
          from="/company/view/49/applications"
          to="/company/baker-mckenzie-training-contract/applications"
        />
        <Redirect
          from="/company/view/49/interview-experiences"
          to="/company/baker-mckenzie-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/49/profile"
          to="/company/baker-mckenzie-training-contract/applications"
        />
        <Redirect
          from="/company/view/49/facts"
          to="/company/baker-mckenzie-training-contract/applications"
        />
        <Redirect
          from="/company/view/28"
          to="/company/bclp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/28/applications"
          to="/company/bclp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/28/interview-experiences"
          to="/company/bclp-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/28/profile"
          to="/company/bclp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/28/facts"
          to="/company/bclp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/64"
          to="/company/bird-and-bird-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/64/applications"
          to="/company/bird-and-bird-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/64/interview-experiences"
          to="/company/bird-and-bird-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/64/profile"
          to="/company/bird-and-bird-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/64/facts"
          to="/company/bird-and-bird-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/65"
          to="/company/blake-morgan-training-contract/applications"
        />
        <Redirect
          from="/company/view/65/applications"
          to="/company/blake-morgan-training-contract/applications"
        />
        <Redirect
          from="/company/view/65/interview-experiences"
          to="/company/blake-morgan-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/65/profile"
          to="/company/blake-morgan-training-contract/applications"
        />
        <Redirect
          from="/company/view/65/facts"
          to="/company/blake-morgan-training-contract/applications"
        />
        <Redirect
          from="/company/view/66"
          to="/company/burges-salmon-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/66/applications"
          to="/company/burges-salmon-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/66/interview-experiences"
          to="/company/burges-salmon-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/66/profile"
          to="/company/burges-salmon-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/66/facts"
          to="/company/burges-salmon-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/179"
          to="/company/burness-paul-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/179/applications"
          to="/company/burness-paul-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/179/interview-experiences"
          to="/company/burness-paul-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/179/profile"
          to="/company/burness-paul-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/179/facts"
          to="/company/burness-paul-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/67"
          to="/company/charles-russell-speechlys-training-contract/applications"
        />
        <Redirect
          from="/company/view/67/applications"
          to="/company/charles-russell-speechlys-training-contract/applications"
        />
        <Redirect
          from="/company/view/67/interview-experiences"
          to="/company/charles-russell-speechlys-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/67/profile"
          to="/company/charles-russell-speechlys-training-contract/applications"
        />
        <Redirect
          from="/company/view/67/facts"
          to="/company/charles-russell-speechlys-training-contract/applications"
        />
        <Redirect
          from="/company/view/39"
          to="/company/clifford-chance-training-contract/applications"
        />
        <Redirect
          from="/company/view/39/applications"
          to="/company/clifford-chance-training-contract/applications"
        />
        <Redirect
          from="/company/view/39/interview-experiences"
          to="/company/clifford-chance-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/39/profile"
          to="/company/clifford-chance-training-contract/applications"
        />
        <Redirect
          from="/company/view/39/facts"
          to="/company/clifford-chance-training-contract/applications"
        />
        <Redirect
          from="/company/view/68"
          to="/company/clyde-and-co-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/68/applications"
          to="/company/clyde-and-co-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/68/interview-experiences"
          to="/company/clyde-and-co-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/68/profile"
          to="/company/clyde-and-co-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/68/facts"
          to="/company/clyde-and-co-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/69"
          to="/company/cms-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/69/applications"
          to="/company/cms-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/69/interview-experiences"
          to="/company/cms-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/69/profile"
          to="/company/cms-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/69/facts"
          to="/company/cms-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/70"
          to="/company/covington-and-burling-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/70/applications"
          to="/company/covington-and-burling-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/70/interview-experiences"
          to="/company/covington-and-burling-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/70/profile"
          to="/company/covington-and-burling-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/70/facts"
          to="/company/covington-and-burling-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/174"
          to="/company/dac-beachcroft-training-contract/applications"
        />
        <Redirect
          from="/company/view/174/applications"
          to="/company/dac-beachcroft-training-contract/applications"
        />
        <Redirect
          from="/company/view/174/interview-experiences"
          to="/company/dac-beachcroft-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/174/profile"
          to="/company/dac-beachcroft-training-contract/applications"
        />
        <Redirect
          from="/company/view/174/facts"
          to="/company/dac-beachcroft-training-contract/applications"
        />
        <Redirect
          from="/company/view/21"
          to="/company/davis-polk-and-wardwell-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/21/applications"
          to="/company/davis-polk-and-wardwell-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/21/interview-experiences"
          to="/company/davis-polk-and-wardwell-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/21/profile"
          to="/company/davis-polk-and-wardwell-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/21/facts"
          to="/company/davis-polk-and-wardwell-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/37"
          to="/company/debevoise-and-plimpton-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/37/applications"
          to="/company/debevoise-and-plimpton-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/37/interview-experiences"
          to="/company/debevoise-and-plimpton-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/37/profile"
          to="/company/debevoise-and-plimpton-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/37/facts"
          to="/company/debevoise-and-plimpton-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/30"
          to="/company/dechert-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/30/applications"
          to="/company/dechert-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/30/interview-experiences"
          to="/company/dechert-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/30/profile"
          to="/company/dechert-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/30/facts"
          to="/company/dechert-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/32"
          to="/company/dentons-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/32/applications"
          to="/company/dentons-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/32/interview-experiences"
          to="/company/dentons-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/32/profile"
          to="/company/dentons-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/32/facts"
          to="/company/dentons-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/35"
          to="/company/dla-piper-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/35/applications"
          to="/company/dla-piper-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/35/interview-experiences"
          to="/company/dla-piper-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/35/profile"
          to="/company/dla-piper-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/35/facts"
          to="/company/dla-piper-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/71"
          to="/company/eversheds-sutherland-vacation-scheme/applications"
        />
         <Redirect
          from="/company/view/71/applications"
          to="/company/eversheds-sutherland-vacation-scheme/applications"
        />
         <Redirect
          from="/company/view/71/interview-experiences"
          to="/company/eversheds-sutherland-vacation-scheme/interview-experiences"
        />
         <Redirect
          from="/company/view/71/profile"
          to="/company/eversheds-sutherland-vacation-scheme/applications"
        />
         <Redirect
          from="/company/view/71/facts"
          to="/company/eversheds-sutherland-vacation-scheme/applications"
        />
         <Redirect
          from="/company/view/60"
          to="/company/farrer-and-co-training-contract/applications"
        />
        <Redirect
          from="/company/view/60/applications"
          to="/company/farrer-and-co-training-contract/applications"
        />
        <Redirect
          from="/company/view/60/interview-experiences"
          to="/company/farrer-and-co-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/60/profile"
          to="/company/farrer-and-co-training-contract/applications"
        />
        <Redirect
          from="/company/view/60/facts"
          to="/company/farrer-and-co-training-contract/applications"
        />
        <Redirect
          from="/company/view/72"
          to="/company/fieldfisher-training-contract/applications"
        />
        <Redirect
          from="/company/view/72/applications"
          to="/company/fieldfisher-training-contract/applications"
        />
        <Redirect
          from="/company/view/72/interview-experiences"
          to="/company/fieldfisher-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/72/profile"
          to="/company/fieldfisher-training-contract/applications"
        />
        <Redirect
          from="/company/view/72/facts"
          to="/company/fieldfisher-training-contract/applications"
        />
        <Redirect
          from="/company/view/169"
          to="/company/forsters-llp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/169/applications"
          to="/company/forsters-llp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/169/interview-experiences"
          to="/company/forsters-llp-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/169/profile"
          to="/company/forsters-llp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/169/facts"
          to="/company/forsters-llp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/190"
          to="/company/fox-williams-training-contract/applications"
        />
        <Redirect
          from="/company/view/190/applications"
          to="/company/fox-williams-training-contract/applications"
        />
        <Redirect
          from="/company/view/190/interview-experiences"
          to="/company/fox-williams-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/190/profile"
          to="/company/fox-williams-training-contract/applications"
        />
        <Redirect
          from="/company/view/190/facts"
          to="/company/fox-williams-training-contract/applications"
        />
        <Redirect
          from="/company/view/22"
          to="/company/freshfields-training-contract/applications"
        />
        <Redirect
          from="/company/view/22/applications"
          to="/company/freshfields-training-contract/applications"
        />
        <Redirect
          from="/company/view/22/interview-experiences"
          to="/company/freshfields-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/22/profile"
          to="/company/freshfields-training-contract/applications"
        />
        <Redirect
          from="/company/view/22/facts"
          to="/company/freshfields-training-contract/applications"
        />
        <Redirect
          from="/company/view/94"
          to="/company/fried-frank-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/94/applications"
          to="/company/fried-frank-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/94/interview-experiences"
          to="/company/fried-frank-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/94/profile"
          to="/company/fried-frank-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/94/facts"
          to="/company/fried-frank-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/16"
          to="/company/gibson-dunn-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/16/applications"
          to="/company/gibson-dunn-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/16/interview-experiences"
          to="/company/gibson-dunn-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/16/profile"
          to="/company/gibson-dunn-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/16/facts"
          to="/company/gibson-dunn-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/189"
          to="/company/goodwin-procter-training-contract/applications"
        />
        <Redirect
          from="/company/view/189/applications"
          to="/company/goodwin-procter-training-contract/applications"
        />
        <Redirect
          from="/company/view/189/interview-experiences"
          to="/company/goodwin-procter-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/189/profile"
          to="/company/goodwin-procter-training-contract/applications"
        />
        <Redirect
          from="/company/view/189/facts"
          to="/company/goodwin-procter-training-contract/applications"
        />
        <Redirect
          from="/company/view/25"
          to="/company/herbert-smith-freehills-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/25/applications"
          to="/company/herbert-smith-freehills-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/25/interview-experiences"
          to="/company/herbert-smith-freehills-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/25/profile"
          to="/company/herbert-smith-freehills-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/25/facts"
          to="/company/herbert-smith-freehills-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/153"
          to="/company/hfw-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/153/applications"
          to="/company/hfw-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/153/interview-experiences"
          to="/company/hfw-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/153/profile"
          to="/company/hfw-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/153/facts"
          to="/company/hfw-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/73"
          to="/company/hill-dickinson-training-contract/applications"
        />
        <Redirect
          from="/company/view/73/applications"
          to="/company/hill-dickinson-training-contract/applications"
        />
        <Redirect
          from="/company/view/73/interview-experiences"
          to="/company/hill-dickinson-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/73/profile"
          to="/company/hill-dickinson-training-contract/applications"
        />
        <Redirect
          from="/company/view/73/facts"
          to="/company/hill-dickinson-training-contract/applications"
        />
        <Redirect
          from="/company/view/74"
          to="/company/hogan-lovells-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/74/applications"
          to="/company/hogan-lovells-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/74/interview-experiences"
          to="/company/hogan-lovells-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/74/profile"
          to="/company/hogan-lovells-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/74/facts"
          to="/company/hogan-lovells-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/75"
          to="/company/ince-co-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/75/applications"
          to="/company/ince-co-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/75/interview-experiences"
          to="/company/ince-co-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/75/profile"
          to="/company/ince-co-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/75/facts"
          to="/company/ince-co-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/14"
          to="/company/jones-day-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/14/applications"
          to="/company/jones-day-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/14/interview-experiences"
          to="/company/jones-day-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/14/profile"
          to="/company/jones-day-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/14/facts"
          to="/company/jones-day-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/97"
          to="/company/kennedys-training-contract/applications"
        />
        <Redirect
          from="/company/view/97/applications"
          to="/company/kennedys-training-contract/applications"
        />
        <Redirect
          from="/company/view/97/interview-experiences"
          to="/company/kennedys-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/97/profile"
          to="/company/kennedys-training-contract/applications"
        />
        <Redirect
          from="/company/view/97/facts"
          to="/company/kennedys-training-contract/applications"
        />
        <Redirect
          from="/company/view/18"
          to="/company/kirkland-and-ellis-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/18/applications"
          to="/company/kirkland-and-ellis-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/18/interview-experiences"
          to="/company/kirkland-and-ellis-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/18/profile"
          to="/company/kirkland-and-ellis-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/18/facts"
          to="/company/kirkland-and-ellis-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/76"
          to="/company/k-l-gates-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/76/applications"
          to="/company/k-l-gates-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/76/interview-experiences"
          to="/company/k-l-gates-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/76/profile"
          to="/company/k-l-gates-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/76/facts"
          to="/company/k-l-gates-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/23"
          to="/company/latham-and-watkins-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/23/applications"
          to="/company/latham-and-watkins-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/23/interview-experiences"
          to="/company/latham-and-watkins-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/23/profile"
          to="/company/latham-and-watkins-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/23/facts"
          to="/company/latham-and-watkins-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/24"
          to="/company/linklaters-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/24/applications"
          to="/company/linklaters-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/24/interview-experiences"
          to="/company/linklaters-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/24/profile"
          to="/company/linklaters-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/24/facts"
          to="/company/linklaters-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/26"
          to="/company/macfarlanes-vacation-scheme/applications`"
        />
        <Redirect
          from="/company/view/26/applications"
          to="/company/macfarlanes-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/26/interview-experiences"
          to="/company/macfarlanes-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/26/profile"
          to="/company/macfarlanes-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/26/facts"
          to="/company/macfarlanes-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/154"
          to="/company/matheson-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/154/applications"
          to="/company/matheson-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/154/interview-experiences"
          to="/company/matheson-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/154/profile"
          to="/company/matheson-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/154/facts"
          to="/company/matheson-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/77"
          to="/company/mayer-brown-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/77/applications"
          to="/company/mayer-brown-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/77/interview-experiences"
          to="/company/mayer-brown-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/77/profile"
          to="/company/mayer-brown-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/77/facts"
          to="/company/mayer-brown-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/78"
          to="/company/milbank-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/78/applications"
          to="/company/milbank-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/78/interview-experiences"
          to="/company/milbank-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/78/profile"
          to="/company/milbank-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/78/facts"
          to="/company/milbank-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/79"
          to="/company/mills-and-reeve-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/79/applications"
          to="/company/mills-and-reeve-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/79/interview-experiences"
          to="/company/mills-and-reeve-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/79/profile"
          to="/company/mills-and-reeve-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/79/facts"
          to="/company/mills-and-reeve-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/80"
          to="/company/mishcon-de-reya-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/80/applications"
          to="/company/mishcon-de-reya-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/80/interview-experiences"
          to="/company/mishcon-de-reya-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/80/profile"
          to="/company/mishcon-de-reya-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/80/facts"
          to="/company/mishcon-de-reya-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/168"
          to="/company/morgan-lewis-training-contract/applications"
        />
        <Redirect
          from="/company/view/168/applications"
          to="/company/morgan-lewis-training-contract/applications"
        />
        <Redirect
          from="/company/view/168/interview-experiences"
          to="/company/morgan-lewis-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/168/profile"
          to="/company/morgan-lewis-training-contract/applications"
        />
        <Redirect
          from="/company/view/168/facts"
          to="/company/morgan-lewis-training-contract/applications"
        />
        <Redirect
          from="/company/view/27"
          to="/company/norton-rose-fulbright-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/27/applications"
          to="/company/norton-rose-fulbright-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/27/interview-experiences"
          to="/company/norton-rose-fulbright-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/27/profile"
          to="/company/norton-rose-fulbright-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/27/facts"
          to="/company/norton-rose-fulbright-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/170"
          to="/company/orrick-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/170/applications"
          to="/company/orrick-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/170/interview-experiences"
          to="/company/orrick-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="company/view/170/profile"
          to="/company/orrick-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/170/facts"
          to="/company/orrick-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/81"
          to="/company/osborne-clarke-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/81/applications"
          to="/company/osborne-clarke-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/81/interview-experiences"
          to="/company/osborne-clarke-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/81/profile"
          to="/company/osborne-clarke-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/81/facts"
          to="/company/osborne-clarke-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/50"
          to="/company/paul-hastings-training-contract/applications"
        />
        <Redirect
          from="/company/view/50/applications"
          to="/company/paul-hastings-training-contract/applications"
        />
        <Redirect
          from="/company/view/50/interview-experiences"
          to="/company/paul-hastings-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/50/profile"
          to="/company/paul-hastings-training-contract/applications"
        />
        <Redirect
          from="/company/view/50/facts"
          to="/company/paul-hastings-training-contract/applications"
        />
        <Redirect
          from="/company/view/82"
          to="/company/penningtons-manches-cooper-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/82/applications"
          to="/company/penningtons-manches-cooper-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/82/interview-experiences"
          to="/company/penningtons-manches-cooper-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/82/profile"
          to="/company/penningtons-manches-cooper-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/82/facts"
          to="/company/penningtons-manches-cooper-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/83"
          to="/company/pinsent-masons-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/83/applications"
          to="/company/pinsent-masons-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/83/interview-experiences"
          to="/company/pinsent-masons-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/83/profile"
          to="/company/pinsent-masons-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/83/facts"
          to="/company/pinsent-masons-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/19"
          to="/company/reed-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/19/applications"
          to="/company/reed-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/19/interview-experiences"
          to="/company/reed-smith-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/19/profile"
          to="/company/reed-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/19/facts"
          to="/company/reed-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/84"
          to="/company/ropes-and-gray-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/84/applications"
          to="/company/ropes-and-gray-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/84/interview-experiences"
          to="/company/ropes-and-gray-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/84/profile"
          to="/company/ropes-and-gray-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/84/facts"
          to="/company/ropes-and-gray-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/85"
          to="/company/rpc-training-contract/applications"
        />
        <Redirect
          from="/company/view/85/applications"
          to="/company/rpc-training-contract/applications"
        />
        <Redirect
          from="/company/view/85/interview-experiences"
          to="/company/rpc-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/85/profile"
          to="/company/rpc-training-contract/applications"
        />
        <Redirect
          from="/company/view/85/facts"
          to="/company/rpc-training-contract/applications"
        />
        <Redirect
          from="/company/view/29"
          to="/company/shearman-and-sterling-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/29/applications"
          to="/company/shearman-and-sterling-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/29/interview-experiences"
          to="/company/shearman-and-sterling-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/29/profile"
          to="/company/shearman-and-sterling-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/29/facts"
          to="/company/shearman-and-sterling-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/86"
          to="/company/shoosmiths-training-contract/applications"
        />
        <Redirect
          from="/company/view/86/applications"
          to="/company/shoosmiths-training-contract/applications"
        />
        <Redirect
          from="/company/view/86/interview-experiences"
          to="/company/shoosmiths-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/86/profile"
          to="/company/shoosmiths-training-contract/applications"
        />
        <Redirect
          from="/company/view/86/facts"
          to="/company/shoosmiths-training-contract/applications"
        />
        <Redirect
          from="/company/view/33"
          to="/company/sidley-austin-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/33/applications"
          to="/company/sidley-austin-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/33/interview-experiences"
          to="/company/sidley-austin-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/33/profile"
          to="/company/sidley-austin-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/33/facts"
          to="/company/sidley-austin-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/87"
          to="/company/simmons-and-simmons-training-contract/applications"
        />
        <Redirect
          from="/company/view/87/applications"
          to="/company/simmons-and-simmons-training-contract/applications"
        />
        <Redirect
          from="/company/view/87/interview-experiences"
          to="/company/simmons-and-simmons-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/87/profile"
          to="/company/simmons-and-simmons-training-contract/applications"
        />
        <Redirect
          from="/company/view/87/facts"
          to="/company/simmons-and-simmons-training-contract/applications"
        />
        <Redirect
          from="/company/view/88"
          to="/company/skadden-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/88/applications"
          to="/company/skadden-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/88/interview-experiences"
          to="/company/skadden-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/88/profile"
          to="/company/skadden-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/88/facts"
          to="/company/skadden-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/34"
          to="/company/slaughter-and-may-training-contract/applications"
        />
        <Redirect
          from="/company/view/34/applications"
          to="/company/slaughter-and-may-training-contract/applications"
        />
        <Redirect
          from="/company/view/34/interview-experiences"
          to="/company/slaughter-and-may-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/34/profile"
          to="/company/slaughter-and-may-training-contract/applications"
        />
        <Redirect
          from="/company/view/34/facts"
          to="/company/slaughter-and-may-training-contract/applications"
        />
        <Redirect
          from="/company/view/182"
          to="/company/squire-patton-boggs-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/182/applications"
          to="/company/squire-patton-boggs-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/182/interview-experiences"
          to="/company/squire-patton-boggs-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/182/profile"
          to="/company/squire-patton-boggs-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/182/facts"
          to="/company/squire-patton-boggs-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/89"
          to="/company/stephenson-harwood-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/89/applications"
          to="/company/stephenson-harwood-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/89/interview-experiences"
          to="/company/stephenson-harwood-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/89/profile"
          to="/company/stephenson-harwood-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/89/facts"
          to="/company/stephenson-harwood-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/38"
          to="/company/sullivan-and-cromwell-training-contract/applications"
        />
        <Redirect
          from="/company/view/38/applications"
          to="/company/sullivan-and-cromwell-training-contract/applications"
        />
        <Redirect
          from="/company/view/38/interview-experiences"
          to="/company/sullivan-and-cromwell-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/38/profile"
          to="/company/sullivan-and-cromwell-training-contract/applications"
        />
        <Redirect
          from="/company/view/38/facts"
          to="/company/sullivan-and-cromwell-training-contract/applications"
        />
        <Redirect
          from="/company/view/90"
          to="/company/taylor-wessing-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/90/applications"
          to="/company/taylor-wessing-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/90/interview-experiences"
          to="/company/taylor-wessing-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/90/profile"
          to="/company/taylor-wessing-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/90/facts"
          to="/company/taylor-wessing-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/191"
          to="/company/tlt-llp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/191/applications"
          to="/company/tlt-llp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/191/interview-experiences"
          to="/company/tlt-llp-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/191/profile"
          to="/company/tlt-llp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/191/facts"
          to="/company/tlt-llp-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/91"
          to="/company/travers-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/91/applications"
          to="/company/travers-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/91/interview-experiences"
          to="/company/travers-smith-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/91/profile"
          to="/company/travers-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/91/facts"
          to="/company/travers-smith-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/178"
          to="/company/trowers-and-hamlins-training-contract/applications"
        />
        <Redirect
          from="/company/view/178/applications"
          to="/company/trowers-and-hamlins-training-contract/applications"
        />
        <Redirect
          from="/company/view/178/interview-experiences"
          to="/company/trowers-and-hamlins-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/178/profile"
          to="/company/trowers-and-hamlins-training-contract/applications"
        />
        <Redirect
          from="/company/view/178/facts"
          to="/company/trowers-and-hamlins-training-contract/applications"
        />
        <Redirect
          from="company/view/181"
          to="/company/vinson-and-elkins-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/181/applications"
          to="/company/vinson-and-elkins-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/181/interview-experiences"
          to="/company/vinson-and-elkins-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/181/profile"
          to="/company/vinson-and-elkins-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/181/facts"
          to="/company/vinson-and-elkins-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/163"
          to="/company/walker-morris-training-contract/applications"
        />
        <Redirect
          from="/company/view/163/applications"
          to="/company/walker-morris-training-contract/applications"
        />
        <Redirect
          from="/company/view/163/interview-experiences"
          to="/company/walker-morris-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/163/profile"
          to="/company/walker-morris-training-contract/applications"
        />
        <Redirect
          from="/company/view/163/facts"
          to="/company/walker-morris-training-contract/applications"
        />
        <Redirect
          from="/company/view/165"
          to="/company/watson-farley-williams-training-contract/applications"
        />
        <Redirect
          from="/company/view/165/applications"
          to="/company/watson-farley-williams-training-contract/applications"
        />
        <Redirect
          from="/company/view/165/interview-experiences"
          to="/company/watson-farley-williams-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/165/profile"
          to="/company/watson-farley-williams-training-contract/applications"
        />
        <Redirect
          from="/company/view/165/facts"
          to="/company/watson-farley-williams-training-contract/applications"
        />
        <Redirect
          from="/company/view/31"
          to="/company/weil-gotshal-and-manges-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/31/applications"
          to="/company/weil-gotshal-and-manges-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/31/interview-experiences"
          to="/company/weil-gotshal-and-manges-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/31/profile"
          to="/company/weil-gotshal-and-manges-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/31/facts"
          to="/company/weil-gotshal-and-manges-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/51"
          to="/company/white-and-case-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/51/applications"
          to="/company/white-and-case-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/51/interview-experiences"
          to="/company/white-and-case-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/51/profile"
          to="/company/white-and-case-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/51/facts"
          to="/company/white-and-case-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/175"
          to="/company/willkie-farr-and-gallagher-training-contract/applications"
        />
        <Redirect
          from="/company/view/175/applications"
          to="/company/willkie-farr-and-gallagher-training-contract/applications"
        />
        <Redirect
          from="/company/view/175/interview-experiences"
          to="/company/willkie-farr-and-gallagher-training-contract/interview-experiences"
        />
        <Redirect
          from="/company/view/175/profile"
          to="/company/willkie-farr-and-gallagher-training-contract/applications"
        />
        <Redirect
          from="/company/view/175/facts"
          to="/company/willkie-farr-and-gallagher-training-contract/applications"
        />
        <Redirect
          from="/company/view/180"
          to="/company/winckworth-sherwood-llp-training-contract/applications"
        />
        <Redirect
          from="/company/view/180/applications"
          to="/company/winckworth-sherwood-llp-training-contract/applications"
        />
        <Redirect
          from="/company/view/180/interview-experiences"
          to="/company/winckworth-sherwood-llp-training-contract/interview-experiencess"
        />
        <Redirect
          from="/company/view/180/profile"
          to="/company/winckworth-sherwood-llp-training-contract/applications"
        />
        <Redirect
          from="/company/view/180/facts"
          to="/company/winckworth-sherwood-llp-training-contract/applications"
        />
        <Redirect
          from="/company/view/152"
          to="/company/withers-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/152/applications"
          to="/company/withers-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/152/interview-experiences"
          to="/company/withers-vacation-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/152/profile"
          to="/company/withers-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/152/facts"
          to="/company/withers-vacation-scheme/applications"
        />
        <Redirect
          from="/company/view/134"
          to="/company/arthur-d-little-internship/applications"
        />
        <Redirect
          from="/company/view/134/applications"
          to="/company/arthur-d-little-internship/applications"
        />
        <Redirect
          from="/company/view/134/interview-experiences"
          to="/company/arthur-d-little-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/134/profile"
          to="/company/arthur-d-little-internship/applications"
        />
        <Redirect
          from="/company/view/134/facts"
          to="/company/arthur-d-little-internship/applications"
        />
        <Redirect
          from="/company/view/123"
          to="/company/at-kearney-summer-internship/applications"
        />
        <Redirect
          from="/company/view/123/applications"
          to="/company/at-kearney-summer-internship/applications"
        />
        <Redirect
          from="/company/view/123/interview-experiences"
          to="/company/at-kearney-summer-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/123/profile"
          to="/company/at-kearney-summer-internship/applications"
        />
        <Redirect
          from="/company/view/123/facts"
          to="/company/at-kearney-summer-internship/applications"
        />
        <Redirect
          from="/company/view/13"
          to="/company/bain-and-company-internship/applications"
        />
        <Redirect
          from="/company/view/13/applications"
          to="/company/bain-and-company-internship/applications"
        />
        <Redirect
          from="/company/view/13/interview-experiences"
          to="/company/bain-and-company-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/13/profile"
          to="/company/bain-and-company-internship/applications"
        />
        <Redirect
          from="/company/view/13/facts"
          to="/company/bain-and-company-internship/applications"
        />
        <Redirect
          from="/company/view/15"
          to="/company/boston-consulting-group-internship/applications"
        />
        <Redirect
          from="/company/view/15/applications"
          to="/company/boston-consulting-group-internship/applications"
        />
        <Redirect
          from="/company/view/15/interview-experiences"
          to="/company/boston-consulting-group-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/15/profile"
          to="/company/boston-consulting-group-internship/applications"
        />
        <Redirect
          from="/company/view/15/facts"
          to="/company/boston-consulting-group-internship/applications"
        />
        <Redirect
          from="/company/view/136"
          to="/company/corporate-value-associates-internship/applications"
        />
        <Redirect
          from="/company/view/136/applications"
          to="/company/corporate-value-associates-internship/applications"
        />
        <Redirect
          from="/company/view/136/interview-experiences"
          to="/company/corporate-value-associates-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/136/profile"
          to="/company/corporate-value-associates-internship/applications"
        />
        <Redirect
          from="/company/view/136/facts"
          to="/company/corporate-value-associates-internship/applications"
        />
        <Redirect
          from="/company/view/126"
          to="/company/ey-parthenon-summer-internship/applications"
        />
        <Redirect
          from="/company/view/126/applications"
          to="/company/ey-parthenon-summer-internship/applications"
        />
        <Redirect
          from="/company/view/126/interview-experiences"
          to="/company/ey-parthenon-summer-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/126/profile"
          to="/company/ey-parthenon-summer-internship/applications"
        />
        <Redirect
          from="/company/view/126/facts"
          to="/company/ey-parthenon-summer-internship/applications"
        />
        <Redirect
          from="/company/view/171"
          to="/company/fti-consulting-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/171/applications"
          to="/company/fti-consulting-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/171/interview-experiences"
          to="/company/fti-consulting-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/171/profile"
          to="/company/fti-consulting-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/171/facts"
          to="/company/fti-consulting-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/184"
          to="/company/lek-consulting-internship/applications"
        />
        <Redirect
          from="/company/view/184/applications"
          to="/company/lek-consulting-internship/applications"
        />
        <Redirect
          from="/company/view/184/interview-experiences"
          to="/company/lek-consulting-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/184/profile"
          to="/company/lek-consulting-internship/applications"
        />
        <Redirect
          from="/company/view/184/facts"
          to="/company/lek-consulting-internship/applications"
        />
        <Redirect
          from="/company/view/12"
          to="/company/mckinsey-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/12/applications"
          to="/company/mckinsey-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/12/interview-experiences"
          to="/company/mckinsey-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/12/profile"
          to="/company/mckinsey-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/12/facts"
          to="/company/mckinsey-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/99"
          to="/company/oc-and-c-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/99/applications"
          to="/company/oc-and-c-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/99/interview-experiences"
          to="/company/oc-and-c-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/99/profile"
          to="/company/oc-and-c-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/99/facts"
          to="/company/oc-and-c-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/143"
          to="/company/oxera-consulting-internship/applications"
        />
        <Redirect
          from="/company/view/143/applications"
          to="/company/oxera-consulting-internship/applications"
        />
        <Redirect
          from="/company/view/143/interview-experiences"
          to="/company/oxera-consulting-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/143/profile"
          to="/company/oxera-consulting-internship/applications"
        />
        <Redirect
          from="/company/view/143/facts"
          to="/company/oxera-consulting-internship/applications"
        />
        <Redirect
          from="/company/view/133"
          to="/company/plural-strategy-group-internship/applications"
        />
        <Redirect
          from="/company/view/133/applications"
          to="/company/plural-strategy-group-internship/applications"
        />
        <Redirect
          from="/company/view/133/interview-experiences"
          to="/company/plural-strategy-group-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/133/profile"
          to="/company/plural-strategy-group-internship/applications"
        />
        <Redirect
          from="/company/view/133/facts"
          to="/company/plural-strategy-group-internship/applications"
        />
        <Redirect
          from="/company/view/137"
          to="/company/roland-berger-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/137/applications"
          to="/company/roland-berger-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/137/interview-experiences"
          to="/company/roland-berger-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/137/profile"
          to="/company/roland-berger-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/137/facts"
          to="/company/roland-berger-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/139"
          to="/company/solon-management-consulting-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/139/applications"
          to="/company/solon-management-consulting-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/139/interview-experiences"
          to="/company/solon-management-consulting-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/139/profile"
          to="/company/solon-management-consulting-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/139/facts"
          to="/company/solon-management-consulting-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/138"
          to="/company/space-global-strategy-graduate-program/applications"
        />
        <Redirect
          from="/company/view/138/applications"
          to="/company/space-global-strategy-graduate-program/applications"
        />
        <Redirect
          from="/company/view/138/interview-experiences"
          to="/company/space-global-strategy-graduate-program/interview-experiences"
        />
        <Redirect
          from="/company/view/138/profile"
          to="/company/space-global-strategy-graduate-program/applications"
        />
        <Redirect
          from="/company/view/138/facts"
          to="/company/space-global-strategy-graduate-program/applications"
        />
        <Redirect
          from="/company/view/127"
          to="/company/pwc-strategy-internship/applications"
        />
        <Redirect
          from="/company/view/127/applications"
          to="/company/pwc-strategy-internship/applications"
        />
        <Redirect
          from="/company/view/127/interview-experiences"
          to="/company/pwc-strategy-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/127/profile"
          to="/company/pwc-strategy-internship/applications"
        />
        <Redirect
          from="/company/view/127/facts"
          to="/company/pwc-strategy-internship/applications"
        />
        <Redirect
          from="/company/view/192"
          to="/company/tata-consultancy-graduate-program/applications"
        />
        <Redirect
          from="/company/view/192/applications"
          to="/company/tata-consultancy-graduate-program/applications"
        />
        <Redirect
          from="/company/view/192/interview-experiences"
          to="/company/tata-consultancy-graduate-program/interview-experiences"
        />
        <Redirect
          from="/company/view/192/profile"
          to="/company/tata-consultancy-graduate-program/applications"
        />
        <Redirect
          from="/company/view/192/facts"
          to="/company/tata-consultancy-graduate-program/applications"
        />
        <Redirect
          from="/company/view/167"
          to="/company/willis-towers-watson-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/167/applications"
          to="/company/willis-towers-watson-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/167/interview-experiences"
          to="/company/willis-towers-watson-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/167/profile"
          to="/company/willis-towers-watson-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/167/facts"
          to="/company/willis-towers-watson-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/109"
          to="/company/bank-of-america-merrill-lynch-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/109/applications"
          to="/company/bank-of-america-merrill-lynch-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/109/interview-experiences"
          to="/company/bank-of-america-merrill-lynch-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/109/profile"
          to="/company/bank-of-america-merrill-lynch-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/109/facts"
          to="/company/bank-of-america-merrill-lynch-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/114"
          to="/company/barclays-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/114/applications"
          to="/company/barclays-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/114/interview-experience"
          to="/company/barclays-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/114/profile"
          to="/company/barclays-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/114/facts"
          to="/company/barclays-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/160"
          to="/company/bloomberg-internship/applications"
        />
        <Redirect
          from="/company/view/160/applications"
          to="/company/bloomberg-internship/applications"
        />
        <Redirect
          from="/company/view/160/interview-experiences"
          to="/company/bloomberg-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/160/profile"
          to="/company/bloomberg-internship/applications"
        />
        <Redirect
          from="/company/view/160/facts"
          to="/company/bloomberg-internship/applications"
        />
        <Redirect
          from="/company/view/113"
          to="/company/bnp-paribas-internship/applications"
        />
        <Redirect
          from="/company/view/113/applications"
          to="/company/bnp-paribas-internship/applications"
        />
        <Redirect
          from="/company/view/113/interview-experiences"
          to="/company/bnp-paribas-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/113/profile"
          to="/company/bnp-paribas-internship/applications"
        />
        <Redirect
          from="/company/view/113/facts"
          to="/company/bnp-paribas-internship/applications"
        />
        <Redirect
          from="/company/view/111"
          to="/company/citibank-internship/applications"
        />
        <Redirect
          from="/company/view/111/applications"
          to="/company/citibank-internship/applications"
        />
        <Redirect
          from="/company/view/111/interview-experiences"
          to="/company/citibank-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/111/profile"
          to="/company/citibank-internship/applications"
        />
        <Redirect
          from="/company/view/111/facts"
          to="/company/citibank-internship/applications"
        />
        <Redirect
          from="/company/view/144"
          to="/company/clearwater-international-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/144/applications"
          to="/company/clearwater-international-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/144/interview-experiences"
          to="/company/clearwater-international-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/144/profile"
          to="/company/clearwater-international-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/144/facts"
          to="/company/clearwater-international-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/112"
          to="/company/commerzbank-internship/applications"
        />
        <Redirect
          from="/company/view/112/applications"
          to="/company/commerzbank-internship/applications"
        />
        <Redirect
          from="/company/view/112/interview-experiences"
          to="/company/commerzbank-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/112/profile"
          to="/company/commerzbank-internship/applications"
        />
        <Redirect
          from="/company/view/112/facts"
          to="/company/commerzbank-internship/applications"
        />
        <Redirect
          from="/company/view/105"
          to="/company/credit-suisse-internship/applications"
        />
        <Redirect
          from="/company/view/105/applications"
          to="/company/credit-suisse-internship/applications"
        />
        <Redirect
          from="/company/view/105/interview-experiences"
          to="/company/credit-suisse-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/105/profile"
          to="/company/credit-suisse-internship/applications"
        />
        <Redirect
          from="/company/view/105/facts"
          to="/company/credit-suisse-internship/applications"
        />
        <Redirect
          from="/company/view/162"
          to="/company/dc-advisory-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/162/applications"
          to="/company/dc-advisory-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/162/interview-experiences"
          to="/company/dc-advisory-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/162/profile"
          to="/company/dc-advisory-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/162/facts"
          to="/company/dc-advisory-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/104"
          to="/company/deutsche-bank-internship/applications"
        />
        <Redirect
          from="/company/view/104/applications"
          to="/company/deutsche-bank-internship/applications"
        />
        <Redirect
          from="/company/view/104/interview-experiences"
          to="/company/deutsche-bank-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/104/profile"
          to="/company/deutsche-bank-internship/applications"
        />
        <Redirect
          from="/company/view/104/facts"
          to="/company/deutsche-bank-internship/applications"
        />
        <Redirect
          from="/company/view/20"
          to="/company/evercore-spring-week/applications"
        />
        <Redirect
          from="/company/view/20/applications"
          to="/company/evercore-spring-week/applications"
        />
        <Redirect
          from="/company/view/20/interview-experiences"
          to="/company/evercore-spring-week/interview-experiences"
        />
        <Redirect
          from="/company/view/20/profile"
          to="/company/evercore-spring-week/applications"
        />
        <Redirect
          from="/company/view/20/facts"
          to="/company/evercore-spring-week/applications"
        />
        <Redirect
          from="/company/view/140"
          to="/company/fe-international-internship/applications"
        />
        <Redirect
          from="/company/view/140/applications"
          to="/company/fe-international-internship/applications"
        />
        <Redirect
          from="/company/view/140/interview-experiences"
          to="/company/fe-international-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/140/profile"
          to="/company/fe-international-internship/applications"
        />
        <Redirect
          from="/company/view/140/facts"
          to="/company/fe-international-internship/applications"
        />
        <Redirect
          from="/company/view/122"
          to="/company/ft-partners-internship/applications"
        />
        <Redirect
          from="/company/view/122/applications"
          to="/company/ft-partners-internship/applications"
        />
        <Redirect
          from="/company/view/122/interview-experiences"
          to="/company/ft-partners-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/122/profile"
          to="/company/ft-partners-internship/applications"
        />
        <Redirect
          from="/company/view/122/facts"
          to="/company/ft-partners-internship/applications"
        />
        <Redirect
          from="/company/view/135"
          to="/company/gleacher-shacklock-internship/applications"
        />
        <Redirect
          from="/company/view/135/applications"
          to="/company/gleacher-shacklock-internship/applications"
        />
        <Redirect
          from="/company/view/135/interview-experiences"
          to="/company/gleacher-shacklock-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/135/profile"
          to="/company/gleacher-shacklock-internship/applications"
        />
        <Redirect
          from="/company/view/135/facts"
          to="/company/gleacher-shacklock-internship/applications"
        />
        <Redirect
          from="/company/view/17"
          to="/company/goldman-sachs-internship/applications"
        />
        <Redirect
          from="/company/view/17/applications"
          to="/company/goldman-sachs-internship/applications"
        />
        <Redirect
          from="/company/view/17/interview-experiences"
          to="/company/goldman-sachs-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/17/profile"
          to="/company/goldman-sachs-internship/applications"
        />
        <Redirect
          from="/company/view/17/facts"
          to="/company/goldman-sachs-internship/applications"
        />
        <Redirect
          from="/company/view/121"
          to="/company/gp-bullhound-internship/applications"
        />
        <Redirect
          from="/company/view/121/applications"
          to="/company/gp-bullhound-internship/applications"
        />
        <Redirect
          from="/company/view/121/interview-experiences"
          to="/company/gp-bullhound-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/121/profile"
          to="/company/gp-bullhound-internship/applications"
        />
        <Redirect
          from="/company/view/121/facts"
          to="/company/gp-bullhound-internship/applications"
        />
        <Redirect
          from="/company/view/40"
          to="/company/houlihan-lokey-internship/applications"
        />
        <Redirect
          from="/company/view/40/applications"
          to="/company/houlihan-lokey-internship/applications"
        />
        <Redirect
          from="/company/view/40/interview-experiences"
          to="/company/houlihan-lokey-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/40/profile"
          to="/company/houlihan-lokey-internship/applications"
        />
        <Redirect
          from="/company/view/40/facts"
          to="/company/houlihan-lokey-internship/applications"
        />
        <Redirect
          from="/company/view/119"
          to="/company/hsbc-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/119/applications"
          to="/company/hsbc-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/119/interview-experiences"
          to="/company/hsbc-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/119/profile"
          to="/company/hsbc-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/119/facts"
          to="/company/hsbc-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/107"
          to="/company/investec-graduate-programme/applications"
        />
        <Redirect
          from="/company/view/107/applications"
          to="/company/investec-graduate-programme/applications"
        />
        <Redirect
          from="/company/view/107/interview-experiences"
          to="/company/investec-graduate-programme/interview-experiences"
        />
        <Redirect
          from="/company/view/107/profile"
          to="/company/investec-graduate-programme/applications"
        />
        <Redirect
          from="/company/view/115"
          to="/company/jp-morgan-internship/applications"
        />
        <Redirect
          from="/company/view/115/applications"
          to="/company/jp-morgan-internship/applications"
        />
        <Redirect
          from="/company/view/115/interview-experiences"
          to="/company/jp-morgan-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/115/profile"
          to="/company/jp-morgan-internship/applications"
        />
        <Redirect
          from="/company/view/115/facts"
          to="/company/jp-morgan-internship/applications"
        />
        <Redirect
          from="/company/view/161"
          to="/company/lazard-spring-week/applications"
        />
        <Redirect
          from="/company/view/161/applications"
          to="/company/lazard-spring-week/applications"
        />
        <Redirect
          from="/company/view/161/interview-experiences"
          to="/company/lazard-spring-week/interview-experiences"
        />
        <Redirect
          from="/company/view/161/profile"
          to="/company/lazard-spring-week/applications"
        />
        <Redirect
          from="/company/view/161/facts"
          to="/company/lazard-spring-week/applications"
        />
        <Redirect
          from="/company/view/117"
          to="/company/macquarie-graduate-program/applications"
        />
        <Redirect
          from="/company/view/117/applications"
          to="/company/macquarie-graduate-program/applications"
        />
        <Redirect
          from="/company/view/117/interview-experiences"
          to="/company/macquarie-graduate-program/interview-experiences"
        />
        <Redirect
          from="/company/view/117/profile"
          to="/company/macquarie-graduate-program/applications"
        />
        <Redirect
          from="/company/view/117/facts"
          to="/company/macquarie-graduate-program/applications"
        />
        <Redirect
          from="/company/view/110"
          to="/company/morgan-stanley-spring-week/applications"
        />
        <Redirect
          from="/company/view/110/applications"
          to="/company/morgan-stanley-spring-week/applications"
        />
        <Redirect
          from="/company/view/110/interview-experiences"
          to="/company/morgan-stanley-spring-week/interview-experiences"
        />
        <Redirect
          from="/company/view/110/profile"
          to="/company/morgan-stanley-spring-week/applications"
        />
        <Redirect
          from="/company/view/110/facts"
          to="/company/morgan-stanley-spring-week/applications"
        />
        <Redirect
          from="/company/view/185"
          to="/company/nomura-internship/applications"
        />
        <Redirect
          from="/company/view/185/applications"
          to="/company/nomura-internship/applications"
        />
        <Redirect
          from="/company/view/185/interview-experiences"
          to="/company/nomura-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/185/profile"
          to="/company/nomura-internship/applications"
        />
        <Redirect
          from="/company/view/185/facts"
          to="/company/nomura-internship/applications"
        />
        <Redirect
          from="/company/view/186"
          to="/company/numis-securities-internship/applications"
        />
        <Redirect
          from="/company/view/186/applications"
          to="/company/numis-securities-internship/applications"
        />
        <Redirect
          from="/company/view/186/interview-experiences"
          to="/company/numis-securities-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/186/profile"
          to="/company/numis-securities-internship/applications"
        />
        <Redirect
          from="/company/view/186/facts"
          to="/company/numis-securities-internship/applications"
        />
        <Redirect
          from="/company/view/101"
          to="/company/perella-weinberg-internship/applications"
        />
        <Redirect
          from="/company/view/101/applications"
          to="/company/perella-weinberg-internship/applications"
        />
        <Redirect
          from="/company/view/101/interview-experiences"
          to="/company/perella-weinberg-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/101/profile"
          to="/company/perella-weinberg-internship/applications"
        />
        <Redirect
          from="/company/view/101/facts"
          to="/company/perella-weinberg-internship/applications"
        />
        <Redirect
          from="/company/view/116"
          to="/company/rbs-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/116/applications"
          to="/company/rbs-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/116/interview-experiences"
          to="/company/rbs-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/116/profile"
          to="/company/rbs-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/116/facts"
          to="/company/rbs-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/102"
          to="/company/rothschild-internship/applications"
        />
        <Redirect
          from="/company/view/102/applications"
          to="/company/rothschild-internship/applications"
        />
        <Redirect
          from="/company/view/102/interview-experiences"
          to="/company/rothschild-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/102/profile"
          to="/company/rothschild-internship/applications"
        />
        <Redirect
          from="/company/view/102/facts"
          to="/company/rothschild-internship/applications"
        />
        <Redirect
          from="/company/view/106"
          to="/company/royal-bank-of-canada-internship/applications"
        />
        <Redirect
          from="/company/view/106/applications"
          to="/company/royal-bank-of-canada-internship/applications"
        />
        <Redirect
          from="/company/view/106/interview-experiences"
          to="/company/royal-bank-of-canada-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/106/profile"
          to="/company/royal-bank-of-canada-internship/applications"
        />
        <Redirect
          from="/company/view/106/facts"
          to="/company/royal-bank-of-canada-internship/applications"
        />
        <Redirect
          from="/company/view/187"
          to="/company/santander-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/187/applications"
          to="/company/santander-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/187/interview-experiences"
          to="/company/santander-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/187/profile"
          to="/company/santander-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/187/facts"
          to="/company/santander-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/166"
          to="/company/schroders-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/166/applications"
          to="/company/schroders-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/166/interview-experiences"
          to="/company/schroders-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/166/profile"
          to="/company/schroders-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/166/facts"
          to="/company/schroders-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/149"
          to="/company/societe-generale-graduate-program/applications"
        />
        <Redirect
          from="/company/view/149/applications"
          to="/company/societe-generale-graduate-program/applications"
        />
        <Redirect
          from="/company/view/149/interview-experiences"
          to="/company/societe-generale-graduate-program/interview-experiences"
        />
        <Redirect
          from="/company/view/149/profile"
          to="/company/societe-generale-graduate-program/applications"
        />
        <Redirect
          from="/company/view/149/facts"
          to="/company/societe-generale-graduate-program/applications"
        />
        <Redirect
          from="/company/view/164"
          to="/company/standard-chartered-internship/applications"
        />
        <Redirect
          from="/company/view/164/applications"
          to="/company/standard-chartered-internship/applications"
        />
        <Redirect
          from="/company/view/164/interview-experiences"
          to="/company/standard-chartered-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/164/profile"
          to="/company/standard-chartered-internship/applications"
        />
        <Redirect
          from="/company/view/164/facts"
          to="/company/standard-chartered-internship/applications"
        />
        <Redirect
          from="/company/view/108"
          to="/company/ubs-spring-week/applications"
        />
        <Redirect
          from="/company/view/108/applications"
          to="/company/ubs-spring-week/applications"
        />
        <Redirect
          from="/company/view/108/interview-experiences"
          to="/company/ubs-spring-week/interview-experiences"
        />
        <Redirect
          from="/company/view/108/profile"
          to="/company/ubs-spring-week/applications"
        />
        <Redirect
          from="/company/view/108/facts"
          to="/company/ubs-spring-week/applications"
        />
        <Redirect
          from="/company/view/159"
          to="/company/wells-fargo-internship/applications"
        />
        <Redirect
          from="/company/view/159/applications"
          to="/company/wells-fargo-internship/applications"
        />
        <Redirect
          from="/company/view/159/interview-experiences"
          to="/company/wells-fargo-internship/interview-experiences"
        />
        <Redirect
          from="/company/view/159/profile"
          to="/company/wells-fargo-internship/applications"
        />
        <Redirect
          from="/company/view/159/facts"
          to="/company/wells-fargo-internship/applications"
        />
        <Redirect
          from="/company/view/145"
          to="/company/zurich-insurance-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/145/applications"
          to="/company/zurich-insurance-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/145/interview-experiences"
          to="/company/zurich-insurance-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/145/profile"
          to="/company/zurich-insurance-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/145/facts"
          to="/company/zurich-insurance-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/148"
          to="/company/american-express-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/148/applications"
          to="/company/american-express-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/148/interview-experiences"
          to="/company/american-express-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/148/profile"
          to="/company/american-express-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/148/facts"
          to="/company/american-express-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/146"
          to="/company/liberty-global-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/146/applications"
          to="/company/liberty-global-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/146/interview-experiences"
          to="/company/liberty-global-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/146/profile"
          to="/company/liberty-global-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/146/facts"
          to="/company/liberty-global-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/147/applications"
          to="/company/procter-and-gamble-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/147/applications"
          to="/company/procter-and-gamble-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/147/interview-experiences"
          to="/company/procter-and-gamble-graduate-scheme/interview-experiences"
        />
        <Redirect
          from="/company/view/147/profile"
          to="/company/procter-and-gamble-graduate-scheme/applications"
        />
        <Redirect
          from="/company/view/147/facts"
          to="/company/procter-and-gamble-graduate-scheme/applications"
        />

        <Route path="/404" component={NotFoundPage} />
        <Redirect to="/404" />
      </Switch>
      {/*<FinalisingAccount/>*/}
      <CookiesPopup />
      <GlobalStyle />
    </>
  );
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
