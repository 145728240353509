/**
 *
 * Social Login
 *
 */
import React, {useEffect, useLayoutEffect, useState} from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useGoogleLogin } from '@react-oauth/google';
import styled from 'styled-components';
import googleLogo from '../../images/google-logo.svg';
import facebookLogo from '../../images/facebook-logo.svg';
import { Fonts } from "../../styles/Theme";

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0 auto;
    align-content: center;
    gap: 16px
  }


`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 338px;

  @media (max-width: 1300px) {
    &:first-child {
      padding-right: 8px;
    }

    &:last-child {
      padding-left: 8px;
    }
  }

  @media (max-width: 600px) {
    &:first-child, &:last-child {
      padding: 0;
    }

    max-width: 100%;
  }
`;

const Button = styled.button`
  appearance: none;
  width: 100%;
  max-width: 375px;
  min-height: 60px;
  padding: 5px;
  font-family: 'Helvetica', 'Arial', 'sans-serif';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .123s all linear;

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  min-height: 54px;
  transition: .123s all linear;
`;

const FacebookButton = styled(Button)`
  color: #ffffff;
  border: 1px solid #1877F2;
  background: #1877F2;

  &:hover {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
  }
`;

const GoogleButton = styled(Button)`
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid #ffffff;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);

  &:hover {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.084);
  }
`;

const Span = styled.span`
  padding-left: 15px;
  font-family: ${Fonts.interFont};
`;

const Img = styled.img`
  display: block;

`;

function SocialLogin({loginListener}) {

  const responseFacebook = (response) => {
    loginListener({
      data: response,
      item: 'facebook'
    });
  }

  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      loginListener({
        data: {
          'code': codeResponse.code
        },
        item: 'google'
      });
    },
    flow: 'auth-code',
  });

  return (
    <ButtonsWrapper>
      <ButtonWrapper>
        <FacebookLogin
          appId={process.env.FACEBOOK_APP_ID}
          autoLoad={false}
          callback={responseFacebook}
          fields="name,email,picture"
          render={renderProps => (
            <FacebookButton type="button" onClick={renderProps.onClick}>
              <Img src={facebookLogo} alt='Facebook' width="23px" height="23px"/>
              <Span>Continue with Facebook</Span>
            </FacebookButton>
          )}
        />

      </ButtonWrapper>
      <ButtonWrapper>
        <GoogleButton onClick={() => login()}>
            <Img src={googleLogo} alt='Google' width="24px" height="24px"/>
            <Span>Continue with Google</Span>
          </GoogleButton>
      </ButtonWrapper>
    </ButtonsWrapper>
  );
}

SocialLogin.propTypes = {};

export default SocialLogin;
