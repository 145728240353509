import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { requestJSON } from '../../utils/request';
import {
  DEFAULT_ACTION,
  // plopConstImport
  COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ACTION,
  COMPANY_GET_APPLICATION_LIST_ACTION,
  GET_COMPANY,
  GET_COMPANY_INDUSTRY_LIST,
  GET_COMPANY_LIST,
  GET_COMPANY_TYPE_LIST,
  GET_COMPANY_LOCATION_LIST,
  COMPANY_GET_LIBRARY_LIST_ACTION,
  COMPANY_GET_POST_LIST_ACTION,
  COMPANY_GET_POST_BY_TAG_LIST_ACTION,
} from './constants';
import {
  defaultAction,
  // plopActionImport
  companyGetInterviewExperienceListSuccessAction,
  companyGetInterviewExperienceListErrorAction,
  companyGetApplicationListSuccessAction,
  companyGetApplicationListErrorAction,
  getCompanyErrorAction,
  getCompanyIndustryListErrorAction,
  getCompanyIndustryListSuccessAction,
  getCompanyLocationListErrorAction,
  getCompanyLocationListSuccessAction,
  getCompanyListErrorAction,
  getCompanyListSuccessAction,
  getCompanySuccessAction,
  getCompanyTypeListErrorAction,
  getCompanyTypeListSuccessAction,
  companyGetLibraryListSuccessAction,
  companyGetLibraryListErrorAction,
  companyGetPostListSuccessAction,
  companyGetPostListErrorAction,
  companyGetPostByTagListSuccessAction,
  companyGetPostByTagListErrorAction,
} from './actions';
import { makeSelectUserToken } from '../App/selectors';

// plopSagaExports
export function* companyGetInterviewExperienceList(action) {
  const token = yield select(makeSelectUserToken());
  const requestURL = `company/${action.payload.companyId}/interview_experience/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(companyGetInterviewExperienceListSuccessAction(result));
  } catch (err) {
    console.log('There was an error: ', err);
    yield put(companyGetInterviewExperienceListErrorAction(err));
  }
}

export function* companyGetApplicationList(action) {
  const token = yield select(makeSelectUserToken());
  const requestURL = `company/${action.payload.companyId}/cover_letter/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(companyGetApplicationListSuccessAction(result));
  } catch (err) {
    console.log('There was an error: ', err);
    yield put(companyGetApplicationListErrorAction(err));
  }
}

export function* companyGetLibraryList(action) {
  const token = yield select(makeSelectUserToken());
  const requestURL = `company/${action.payload.companyId}/libraries/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(companyGetLibraryListSuccessAction(result));
  } catch (err) {
    console.log('There was an error: ', err);
    yield put(companyGetLibraryListErrorAction(err));
  }
}

export function* companyGetPostList(action) {
  const token = yield select(makeSelectUserToken());
  const page = action.payload.page || 1
  let requestURL = `blog/post/?tag=${action.payload.tag}&page=${page}`;

  if (action.payload.categories) {
    requestURL += `&categories=${action.payload.categories}`
  }

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(companyGetPostListSuccessAction(result));
  } catch (err) {
    console.log('There was an error: ', err);
    yield put(companyGetPostListErrorAction(err));
  }
}

export function* getPostByTagList(action) {
  const token = yield select(makeSelectUserToken());
  const page = action.payload.page || 1
  let requestURL = `company/${action.payload.companyId}/posts/?page=${page}`;

  if (action.payload.categories) {
    requestURL += `&categories=${action.payload.categories}`
  }

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(companyGetPostByTagListSuccessAction(result));
  } catch (err) {
    console.log('There was an error: ', err);
    yield put(companyGetPostByTagListErrorAction(err));
  }
}

export function* getCompanyList(action) {
  // Select username from store
  const industry = action?.payload?.industry || null
  const token = yield select(makeSelectUserToken());
  const requestURL = `company/${industry ? `?industry=${industry}` : ''}`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(getCompanyListSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);

    yield put(getCompanyListErrorAction(err));
  }
}

export function* getCompanyTypeList() {
  // Select username from store
  const requestURL = `company/type/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, null, 'GET');
    yield put(getCompanyTypeListSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);

    yield put(getCompanyTypeListErrorAction(err));
  }
}

export function* getCompanyIndustryList(action) {
  // Select username from store

  const questions = action?.payload?.questions

  const requestURL = `company/industry/${questions ? '?questions=true' : ''}`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, null, 'GET');
    yield put(getCompanyIndustryListSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);

    yield put(getCompanyIndustryListErrorAction(err));
  }
}

export function* getCompanyLocationList() {
  // Select username from store
  const requestURL = `company/locations/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, null, 'GET');
    yield put(getCompanyLocationListSuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);

    yield put(getCompanyLocationListErrorAction(err));
  }
}

export function* getCompany(action) {
  // Select username from store

  const token = yield select(makeSelectUserToken());
  const requestURL = `company/${action.payload.id}/`;

  try {
    // Call our request helper (see 'utils/request')
    const result = yield call(requestJSON, requestURL, null, token, 'GET');
    yield put(getCompanySuccessAction(result));
  } catch (err) {
    console.log('there is an error: ', err);
    if (err.detail === 'Not found.') {
      yield put(push('/404'));
    }

    yield put(getCompanyErrorAction(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* companySaga() {
  // plopDefaultSagaExports
  yield takeLatest(
    COMPANY_GET_INTERVIEW_EXPERIENCE_LIST_ACTION,
    companyGetInterviewExperienceList,
  );

  yield takeLatest(
    COMPANY_GET_APPLICATION_LIST_ACTION,
    companyGetApplicationList,
  );

  yield takeLatest(GET_COMPANY_LIST, getCompanyList);
  yield takeLatest(GET_COMPANY_TYPE_LIST, getCompanyTypeList);
  yield takeLatest(GET_COMPANY_INDUSTRY_LIST, getCompanyIndustryList);
  yield takeLatest(GET_COMPANY_LOCATION_LIST, getCompanyLocationList);
  yield takeLatest(GET_COMPANY, getCompany);
  yield takeLatest(COMPANY_GET_LIBRARY_LIST_ACTION, companyGetLibraryList);
  yield takeLatest(COMPANY_GET_POST_LIST_ACTION, companyGetPostList);
  yield takeLatest(COMPANY_GET_POST_BY_TAG_LIST_ACTION, getPostByTagList);
}
