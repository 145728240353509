/*
 *
 * Geo actions
 *
 */

import {
  GET_CURRENT_COUNTRY_ACTION,
  RESET_STATE,
  GET_CURRENT_COUNTRY_SUCCESS_ACTION
  // plopConstImport
} from './constants';

export function getCurrentCountryAction() {
  return {
    type: GET_CURRENT_COUNTRY_ACTION,
  };
}

export function getCurrentCountrySuccessAction(payload) {
  return {
    type: GET_CURRENT_COUNTRY_SUCCESS_ACTION,
    payload,
  };
}

export function resetStateAction() {
  return {
    type: RESET_STATE,
  };
}
