import React from "react";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import { Colors } from "../../../styles/Theme";


const ContainerLink = styled(NavLink)`
  margin-bottom: 14px;
  display: block;
`;

const Container = styled.div`
  margin-bottom: 14px;
`;

const Title = styled.h4`
  color: ${Colors.darkBlue};
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
`;

const Badge = styled.div`
  height: 18px;
  width: auto;
  display: table;
  text-align: center;
  padding: 0 5px;
  border-radius: 6px;
  color: #FFA531;
  border: 0.1rem solid #FFA531;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.2px;
  text-transform: capitalize;

  ${(props) =>
    !!props.type && props.type === "free" &&
    css`
      border-color: #589CE8;
      color: #589CE8;
    `}

  ${(props) =>
    !!props.type && props.type === "earn" &&
    css`
      border-color: #46BA6D;
      color: #46BA6D;
    `}

  ${(props) =>
    !!props.type && props.type === "coming soon" &&
    css`
      border-color: #a5a5a5;
      color: #a5a5a5;
    `}
`;

const Description = styled.p`
  color: #6B7280;
  font-feature-settings: 'liga' off;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin: 0;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;


function MobileDropDownItem({ content, ...props }) {
  return (
    <>{!!content.to
      ? <ContainerLink to={content.to}>
        <FlexContainer>
          <Title>{content.title}</Title>
          <Badge type={content.badge}>{content.badge}</Badge>
        </FlexContainer>

        <Description>{content.description}</Description>
      </ContainerLink>
      : <Container>
        <FlexContainer>
          <Title>{content.title}</Title>
          <Badge type={content.badge}>{content.badge}</Badge>
        </FlexContainer>
        <Description>{content.description}</Description>
      </Container>}</>
  );
}

MobileDropDownItem.propTypes = {
  content: require
};

export default MobileDropDownItem;
