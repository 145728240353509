/**
 *
 * AuthView
 *
 */
import React from 'react';
import styled from "styled-components";
import { Colors, Fonts } from "../../styles/Theme";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;

  @media (max-width: 992px) {
    flex-direction: column;
    margin-bottom: 120px;
  }
`;

export const AuthTitle = styled.div`
  color: ${Colors.textPrimary};
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.6; /* 160% */
  margin-bottom: 5px;

  @media (max-width: 1780px) {
    font-size: 40px;
    line-height: 1.25;
  }

  @media (max-width: 1550px) {
    font-size: 35px;
  }

  h1, h2, h3 {
    margin: 0;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    padding: 0;
  }

`;

export const AuthSubTitle = styled.p`
  color: ${Colors.textPrimary};
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 2.15; /* 215.385% */
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: ${(props) => props.marginBottom || '0'};
`;

export const AuthPadding = styled.div`
  padding: ${(props) => props.padding || '12px 0'};
`;

export const AuthSubtitlePadding = styled.div`
  padding-bottom: 27px;

  @media (max-width: 1300px) {
    padding-bottom: 15px;
  }
`;



function AuthView({ children, ...rest }) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

AuthView.propTypes = {};

export default AuthView;
