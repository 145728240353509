/**
 *
 * Loading
 *
 */

import React from 'react';
import './Loading.scoped.scss';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function Loading({showText = true}) {
  return (
   <>
     <div className="loader">Loading...</div>
     {showText && <div className="loader-text">Hold on, we're refreshing our database</div>}
   </>
  );
}

Loading.propTypes = {};

export default Loading;
