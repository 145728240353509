/**
 *
 * Finalising Account
 *
 */

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {connect} from "react-redux";
import {compose} from "redux";
import {createStructuredSelector} from "reselect";
import {
  makeSelectFinalError,
  makeSelectFinalForm,
  makeSelectFinalLoading,
  makeSelectUser
} from "../../containers/User/selectors";
import {makeSelectCompanyIndustryList} from "../../containers/Company/selectors";
import {LoginBlockTitle} from "../../containers/SignIn";
import {FormGroup} from "../Form";
import InputField from "../InputField";
import {Link, useLocation} from "react-router-dom";
import ClearFix from "../ClearFix";
import {Formik} from "formik";
import {LeftAlign, RegisterButton, TosPrivacy} from "../../containers/SignUp";
import reducer from "../../containers/User/reducer";
import saga from "../../containers/User/saga";
import {useInjectReducer} from "../../utils/injectReducer";
import {useInjectSaga} from "../../utils/injectSaga";
import {finalFormSubmitAction, finalFormUpdateAction} from "../../containers/User/actions";
import LoadingIndicator from "../LoadingIndicator";

const ModalShape = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99999999999 !important;
  padding: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .4);
  backdrop-filter: blur(4px);
`;

const Modal = styled.div`
  width: 100%;
  max-width: 767px;
  border-radius: 20px;
  border: 1px solid #d9dbe9;
  background: #FCF9F9;
  box-shadow: 0px 14px 42px rgba(8, 15, 52, 0.25);
  padding: 25px;
  color: #15295a;
  font-family: Arial;
`;


export function FinalisingAccount({
                                    history,
                                    dispatch,
                                    form,
                                    loading,
                                    errors,
                                    user,
                                    companyIndustryList,
                                    finalFormSubmit,
                                    finalFormUpdate
                                  }) {

  useInjectReducer({ key: 'user', reducer });
  useInjectSaga({ key: 'user', saga });

  const [isModal, setIsModal] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [errorsList, setErrorsList] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const locations = ['/privacy-policy', '/terms-and-conditions']

  const handlerFormSubmit = (values) => {
    finalFormUpdate(values);
    finalFormSubmit();
  }

  useEffect(() => {
    if (!!user.id && !!user.interested_industries) {
      setIsModal(user.interested_industries.length === 0)
    }
  }, [user])

  useEffect(() => {
    setErrorsList(errors);
  }, [errors]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading])

  const initialValues = {
    interested_industries: null
  };


  if (isModal && locations.indexOf(location.pathname) === -1) {

    let className = 'fade-in-fwd';
    if (fadeOut) {
      className = 'fade-out-fwd';
    }

    return (
      <ModalShape className={className}>
        <Modal className={className}>
          <LoginBlockTitle>Finalising your account</LoginBlockTitle>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handlerFormSubmit(values)}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setTouched,
                isSubmitting,
                touched,
                values,
              }) => (
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <InputField
                    name="interested_industries"
                    type="select"
                    items={companyIndustryList}
                    form={values}
                    onChange={handleChange}
                    errors={errorsList}
                    isMulti={true}
                    value={values.interested_industries}
                    helperText="Select the industry(ies) you are interested in and view 8 pre-selected verified successful application/cover letters and interview testimonials for FREE! Wish you good luck!"
                    label="Interested Industries:"
                    labelAttribute="title"
                    valueAttribute="id"
                  />
                </FormGroup>
                <TosPrivacy>
                  <LeftAlign>
                    <InputField
                      name="tos_consent"
                      type="checkbox"
                      form={values}
                      errors={errorsList}
                      onChange={handleChange}
                      label={
                        <>
                          I have read and agree with this website’s{' '}
                          <b>
                            <Link to="/terms-and-conditions">
                              Terms &amp; Conditions
                            </Link>{' '}
                          </b>
                          and{' '}
                          <b>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                          </b>
                        </>
                      }
                    />
                  </LeftAlign>
                  <ClearFix/>
                  <LeftAlign>
                    <InputField
                      name="marketing_consent"
                      type="checkbox"
                      form={values}
                      errors={errorsList}
                      onChange={handleChange}
                      label="I would like to receive the 20% Initial Discount Code and Future Promotions"
                    />
                  </LeftAlign>
                  <ClearFix/>
                </TosPrivacy>
                {isLoading && <LoadingIndicator/>}
                {!isLoading && <RegisterButton type="submit" color="orange">
                  Register
                </RegisterButton> }

              </form>
            )}
          </Formik>
        </Modal>
      </ModalShape>
    )
  }

  return '';

}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  companyIndustryList: makeSelectCompanyIndustryList(),
  form: makeSelectFinalForm(),
  loading: makeSelectFinalLoading(),
  errors: makeSelectFinalError()
});

function mapDispatchToProps(dispatch) {
  return {
    finalFormUpdate: (payload) => dispatch(finalFormUpdateAction(payload)),
    finalFormSubmit: () => dispatch(finalFormSubmitAction()),
    dispatch
  }
}

FinalisingAccount.propTypes = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FinalisingAccount);
