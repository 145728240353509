/**
 *
 * WhiteModal
 *
 */

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import Modal from 'react-modal';
import crossPopupSvg from '../../images/cross-popup.svg';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import Title from './Title';

const CloseWrapper = styled.img`
  background-color: #fff;
  padding: 2px;
  -webkit-box-shadow: 1px 1px 6px -1px #000;
  box-shadow: 1px 1px 6px -1px #000;
  border-radius: 40px;
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 20px;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 20px 20px 20px;
  text-align: center;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

const ModalWrapper = styled(Modal)`
  // width: 668px;
`;
const blockScroll = () => {
  document.body.style.position = 'fixed';
  document.body.style.overflow = 'hidden';
};
const releaseScroll = () => {
  document.body.style.position = 'initial';
  document.body.style.overflow = 'initial';
};
Modal.setAppElement(document.getElementById('app'));

const WhiteModal = forwardRef(
  (
    {
      title = '',
      success,
      children,
      onAfterClose = () => {},
      style = {
        overlay: {},
        content: {},
      },
      ...rest
    },
    ref,
  ) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    useImperativeHandle(ref, () => ({
      openModal: (evt) => {
        setIsOpen(true);
      },

      closeModal: () => {
        setIsOpen(false);
      },
      isOpen: () => modalIsOpen,
    }));

    useEffect(() => {
      return releaseScroll;
    }, []);

    useEffect(() => {
      if (modalIsOpen) {
        blockScroll();
      } else {
        releaseScroll();
      }
    }, [modalIsOpen]);

    return (
      <Modal
        style={{
          overlay: {
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            ...style.overlay,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-20%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '80%',
            ...style.content,
          },
        }}
        isOpen={modalIsOpen}
        onRequestClose={ref && ref.current && ref.current.closeModal}
        onAfterClose={() => {
          onAfterClose();
          setIsOpen(false);
        }}
        contentLabel={title}
        {...rest}
      >
        <CloseWrapper onClick={() => setIsOpen(false)} src={crossPopupSvg} />
        <ModalContentWrapper>
          {title && <Title success={success}>{title}</Title>}
          {children}
        </ModalContentWrapper>
      </Modal>
    );
  },
);

WhiteModal.propTypes = {};

export default WhiteModal;
