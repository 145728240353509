import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the company state domain
 */

const selectCompanyDomain = (state) => state.company || initialState;

/**
 * Other specific selectors
 */

// plopImport

const makeSelectCompanyGetInterviewExperienceList = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getInterviewExperienceList,
  );

const makeSelectCompanyGetInterviewExperienceListLoading = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getInterviewExperienceListLoading,
  );

const makeSelectCompanyGetInterviewExperienceListError = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getInterviewExperienceListError,
  );

const makeSelectCompanyGetApplicationList = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getApplicationList,
  );

const makeSelectCompanyGetApplicationListLoading = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getApplicationListLoading,
  );

const makeSelectCompanyGetApplicationListError = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getApplicationListError,
  );

const makeSelectCompanyGetLibraryList = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getLibraryList,
  );

const makeSelectCompanyGetLibraryListLoading = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getLibraryListLoading,
  );

const makeSelectCompanyGetLibraryListError = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getLibraryListError,
  );

const makeSelectCompanyGetPostList = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getPostList,
  );

const makeSelectCompanyGetPostListLoading = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getPostListLoading,
  );

const makeSelectCompanyGetPostListError = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getPostListError,
  );

const makeSelectGetPostList = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getPostByTagList,
  );

const makeSelectGetPostListLoading = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getPostByTagListLoading,
  );

const makeSelectGetPostListError = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.getPostByTagListError,
  );

/**
 * Default selector used by Company
 */

const makeSelectCompany = () =>
  createSelector(selectCompanyDomain, (substate) => substate);
const makeSelectCompanyList = () =>
  createSelector(selectCompanyDomain, (substate) => substate.list);

const makeSelectCompanyListLoading = () =>
  createSelector(selectCompanyDomain, (substate) => substate.loading);

const makeSelectCompanyListError = () =>
  createSelector(selectCompanyDomain, (substate) => substate.error);
const makeSelectCompanyTypeList = () =>
  createSelector(selectCompanyDomain, (substate) => substate.companyTypeList);

const makeSelectCompanyTypeListLoading = () =>
  createSelector(selectCompanyDomain, (substate) => substate.loading);

const makeSelectCompanyTypeListError = () =>
  createSelector(selectCompanyDomain, (substate) => substate.error);
const makeSelectCompanyIndustryList = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.companyIndustryList,
  );

const makeSelectCompanyIndustryListLoading = () =>
  createSelector(selectCompanyDomain, (substate) => substate.loading);

const makeSelectCompanyIndustryListError = () =>
  createSelector(selectCompanyDomain, (substate) => substate.error);

const makeSelectCurrentCompany = () =>
  createSelector(selectCompanyDomain, (substate) => substate.current);

const makeSelectCurrentCompanyLoading = () =>
  createSelector(selectCompanyDomain, (substate) => substate.loading);

const makeSelectCurrentCompanyError = () =>
  createSelector(selectCompanyDomain, (substate) => substate.error);


const makeSelectCompanyLocationList = () =>
  createSelector(
    selectCompanyDomain,
    (substate) => substate.companyLocationList,
  );

const makeSelectCompanyLocationListLoading = () =>
  createSelector(selectCompanyDomain, (substate) => substate.loading);

const makeSelectCompanyLocationListError = () =>
  createSelector(selectCompanyDomain, (substate) => substate.error);


export default makeSelectCompany;

export {
  makeSelectCompanyGetInterviewExperienceList,
  makeSelectCompanyGetInterviewExperienceListLoading,
  makeSelectCompanyGetInterviewExperienceListError,
  makeSelectCompanyGetApplicationList,
  makeSelectCompanyGetApplicationListLoading,
  makeSelectCompanyGetApplicationListError,
  makeSelectCompanyGetLibraryList,
  makeSelectCompanyGetLibraryListLoading,
  makeSelectCompanyGetLibraryListError,
  makeSelectCompanyList,
  makeSelectCompanyListLoading,
  makeSelectCompanyListError,
  makeSelectCompanyTypeList,
  makeSelectCompanyTypeListLoading,
  makeSelectCompanyTypeListError,
  makeSelectCompanyIndustryList,
  makeSelectCompanyIndustryListLoading,
  makeSelectCompanyIndustryListError,
  makeSelectCompanyLocationList,
  makeSelectCompanyLocationListLoading,
  makeSelectCompanyLocationListError,
  makeSelectCurrentCompany,
  makeSelectCurrentCompanyLoading,
  makeSelectCurrentCompanyError,
  makeSelectCompanyGetPostList,
  makeSelectCompanyGetPostListError,
  makeSelectCompanyGetPostListLoading,
  makeSelectGetPostList,
  makeSelectGetPostListLoading,
  makeSelectGetPostListError,
};
