/**
 *
 * Spacer
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function Spacer({ height, mobileHeight }) {
  const Div = styled.div`
    height: ${mobileHeight || height};

    @media screen and (min-width: 500px) {
      height: ${height};
    }
  `;

  return <Div>&nbsp;</Div>;
}

Spacer.propTypes = {
  height: PropTypes.string,
  mobileHeight: PropTypes.string,
};

export default Spacer;
