/**
 *
 * Cookies pop-up
 *
 */

import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import ClosePopupBtn from "./ClosePopupBtn";
import cookieLogo from '../../images/cookie-logo.svg';
import CookieButton from "./CookieButton";
import { getCookie } from "../../utils/utils";

export const PopupWrapper = styled.div`
  width: 100%;
  max-width: 511px;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 99999999999 !important;
  padding: 17px;
`;

export const PopupInner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d9dbe9;
  background: #FCF9F9;
  box-shadow: 0px 14px 42px rgba(8, 15, 52, 0.25);
  padding: 25px 30px 30px;
  color: #15295a;
  font-family: Arial;
`;

export const CookieImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  margin: 0 auto;
`;

export const CookieHeader = styled.h4`
  font-size: 28px;
  text-align: center;
  font-weight: 900;
  margin: 15px 0;
`;

export const CookieP = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin: 7px 0 25px;
  text-align: center;
`;

export const CookieButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;

  @media (max-width: 400px) {
    flex-wrap: wrap;
  }
`;

// export function getCookie(name) {
//   let matches = document.cookie.match(new RegExp(
//     "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
//   ));
//   return matches ? decodeURIComponent(matches[1]) : undefined;
// }

function CookiesPopup() {

  const [isPopup, setIsPopup] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  function buttonClick(val) {
    setFadeOut(true)
    setTimeout(() => {
      setIsPopup(false);
      let date = new Date(Date.now() + 31536000e3);
      date = date.toUTCString();
      document.cookie = "_congrappsCookieConsent=" + val + "; path=/; expires=" + date;
    }, 350)
  }

  function closePopup(e) {
    e.preventDefault();
    setFadeOut(true)
    setTimeout(() => {
      setIsPopup(false);
    }, 350)
  }

  useEffect(() => {
    let cookie = getCookie('_congrappsCookieConsent');
    if (cookie !== undefined) {
      setIsPopup(false);
    } else {
      setIsPopup(true);
    }
  }, []);

  if (isPopup) {

    let className = 'fade-in-right';
    if (fadeOut) {
      className = 'fade-out-right';
    }

    return (
      <PopupWrapper className={className}>
        <PopupInner>
          <ClosePopupBtn action={closePopup}></ClosePopupBtn>
          <CookieImageWrapper>
            <img src={cookieLogo} alt="Cookies"/>
          </CookieImageWrapper>
          <CookieHeader>Cookie consent</CookieHeader>
          <CookieP>We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze
            our
            traffic. By clicking "Accept All", you consent to our use of cookies.</CookieP>
          <CookieButtonsWrapper>
            <CookieButton action={() => {
              buttonClick('reject')
            }}>Reject All</CookieButton>
            <CookieButton primary={true} action={() => {
              buttonClick('accept')
            }}>Accept All</CookieButton>
          </CookieButtonsWrapper>
        </PopupInner>
      </PopupWrapper>
    )
  }

  return '';

}

CookiesPopup.propTypes = {};

export default CookiesPopup;
