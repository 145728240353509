/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = (state) => state.global || initialState;

const selectRouter = (state) => state.router;

const makeSelectCurrentUser = () =>
  createSelector(selectGlobal, (globalState) => globalState);

const makeSelectLoading = () =>
  createSelector(selectGlobal, (globalState) => globalState.loading);

const makeSelectUserToken = () =>
  createSelector(selectGlobal, (globalState) => globalState.token);

const makeSelectBreadcrumbs = () =>
  createSelector(selectGlobal, (globalState) => globalState.breadcrumbs);

const makeSelectError = () =>
  createSelector(selectGlobal, (globalState) => globalState.error);

const makeSelectIsDiscountFooter = () => createSelector(selectGlobal, (globalState) => globalState.isDiscountFooter);

const makeSelectRepos = () =>
  createSelector(
    selectGlobal,
    (globalState) => globalState.userData.repositories,
  );

const makeSelectLocation = () =>
  createSelector(selectRouter, (routerState) => routerState.location);

export {
  selectGlobal,
  makeSelectCurrentUser,
  makeSelectUserToken,
  makeSelectLoading,
  makeSelectError,
  makeSelectBreadcrumbs,
  makeSelectLocation,
  makeSelectIsDiscountFooter
};
